import {
  Tooltip as ToolipShadcn,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { getExpressionPastData, getTagsPastData } from "api/dashboard";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import zoomPlugin from "chartjs-plugin-zoom";
import Loader from "components/shared/Loader";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { BiAlarm, BiExpand } from "react-icons/bi";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { MdOutlineDoDisturbOn } from "react-icons/md";
import { PiFileCsv, PiFilePdf, PiFilePng } from "react-icons/pi";
import { RxIdCard } from "react-icons/rx";
import { TbZoomOutArea } from "react-icons/tb";
import classNames from "utilities/ClassNames";
import Bar from "../Bar";
import { getExpressionData } from "api/expression";
import BigGraph from "./BigGraph";
import useApp from "hooks/useApp";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  zoomPlugin
);

export default function Area({
  data,
  bar,
  setTicketDialog,
  isExpression,
  alarm,
  disable,
}: any) {
  const { isScaled } = useApp();
  const chartRef = useRef<any>(null);
  const [bigGraph, setBigGraph] = useState(false);
  const [isDaily, setIsDaily] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expressionWithData, setExpressionWithData] = useState<any>(null);
  const [valueSet, setValueSet] = useState<number[]>();
  const [dataList, setDataList] = useState<any>();
  const [minValue, setMinValue] = useState<any>();
  const [maxValue, setMaxValue] = useState<any>();
  const [mainid, setmainid] = useState<any>(null);
  const [maintype, setmainType] = useState<any>(null);
  const avgType = "D";

  function randomIntFromInterval() {
    const min = 3,
      max = 20;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const randomsDays = randomIntFromInterval();
  let mainexpressionId = 0;

  useEffect(() => {
    (async () => {
      const response1 = await getExpressionData([data.id]);
      if (response1.data && response1.status === 200) {
        const exp = response1.data[0].expression;
        if (exp) {
          if (exp.substring(0, 3) === "AVG") {
            const expstring = exp.slice(
              exp.indexOf("{") + 1,
              exp.lastIndexOf("}")
            );
            if (expstring.includes("EX") || expstring.includes("TG")) {
              mainexpressionId = Number(expstring.slice(3));
              setmainid(mainexpressionId);
              if (expstring.includes("EX")) {
                setmainType("expression");
              }
              if (expstring.includes("TG")) {
                setmainType("tag");
              }
            } else {
              setmainid(null);
            }
          }
        }
      }
      let response: any = null;
      if (mainid && maintype) {
        if (maintype === "tag") {
          response = await getTagsPastData(
            mainid,
            bigGraph ? 365 : 7,
            isDaily ? "D" : "H"
          );
        }
        if (maintype === "expression") {
          response = await getExpressionPastData(
            mainid,
            bigGraph ? 365 : 7,
            isDaily ? "D" : "H"
          );
        }
      } else {
        response = await getExpressionPastData(
          data.id,
          bigGraph ? 365 : 7,
          isDaily ? "D" : "H"
        );
      }

      if (response?.data && response?.status === 200) {
        const dataList = response?.data.map((expression: any) => ({
          x: new Date(expression.createdOn).getTime(),
          y: expression.expressionValue,
        }));
        const valuesSet = response?.data.map(
          (expression: any) => expression.expressionValue
        );
        const dataResponse: any = await getExpressionData([data.id]);
        if (dataResponse.status === 200) {
          console.log(dataResponse.data[0]);
          setExpressionWithData(dataResponse.data[0]);
        }
        setDataList(dataList);
        setValueSet(valuesSet);
      }
      setLoading(false);
    })();
  }, [mainid, maintype]);

  useEffect(() => {
    const minValue = valueSet?.reduce(
      (a: any, b: any) => Math.min(a, b),
      Infinity
    );
    const maxValue = valueSet?.reduce(
      (a: any, b: any) => Math.max(a, b),
      -Infinity
    );
    setMinValue(minValue);
    setMaxValue(maxValue);
  }, [valueSet]);

  const options: any = {
    animation: {
      duration: 0,
    },
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: isScaled ? 18 : 15,
          },
        },
      },
      y: {
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: isScaled ? 18 : 15,
          },
        },
        min: minValue - 5,
        max: maxValue + 5,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        axis: "x",
        intersect: false,
        filter: function (tooltipItem: any) {
          return tooltipItem.datasetIndex === 0;
        },
      },
      legend: {
        display: false,
      },
      interaction: {
        intersect: true,
      },
      zoom: {
        animation: {
          duration: 1000,
          easing: "easeOutCubic",
        },
        enabled: true,
        mode: "xy",
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgba(62, 252, 173, 0.2)",
            borderColor: "rgb(19, 146, 93)",
            borderWidth: 1,
          },
          wheel: {
            enabled: true,
            modifierKey: "ctrl",
          },
        },
        limits: {
          x: {
            min: data.minRange,
          },
        },
      },
    },
  };

  let minSetData = null;
  let maxSetData = null;

  if (minValue < data.minRange) {
    minSetData = dataList?.map((set: any) => ({
      x: set.x,
      y: data.minRange,
    }));
  }

  if (maxValue > data.maxRange) {
    maxSetData = dataList?.map((set: any) => ({
      x: set.x,
      y: data.maxRange,
    }));
  }

  let minSet = null;
  let maxSet = null;

  if (minSetData) {
    minSet = {
      fill: "start",
      label: "minSet",
      data: minSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  if (maxSetData) {
    maxSet = {
      fill: "end",
      label: "maxSet",
      data: maxSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  const defaultDataSet = {
    fill: true,
    label: "Tag Value",
    data: dataList,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
    lineTension: 0.3,
    pointRadius: 0,
    pointHoverRadius: 0,
    order: 1,
  };

  const datasetsArray = [];
  datasetsArray.push(defaultDataSet);

  if (minSet) {
    datasetsArray.push(minSet);
  }

  if (maxSet) {
    datasetsArray.push(maxSet);
  }

  const chartData = {
    datasets: datasetsArray,
  };

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const zoomIn = () => {
    chartRef.current.zoom(1.1);
  };

  const zoomOut = () => {
    chartRef.current.zoom(0.9);
  };

  const downloadPng = () => {
    const link = document.createElement("a");
    link.download = data.name;
    link.href = chartRef.current.toBase64Image();
    link.click();
  };

  const downloadPdf = () => {
    const image = chartRef?.current?.canvas.toDataURL("image/jpg", 1.0);
    const pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    pdf.addImage(image, "JPEG", 15, 15, 260, 150);
    pdf.save(data.name);
  };

  const downloadCsv = () => {
    const csvData = [
      ["Date", "Expression Value"],
      ...dataList.map((item: any) => [
        dayjs(item.x).format("YYYY-MM-DDTHH:mm:ss"),
        item.y,
      ]),
    ]
      .map((e: any) => e.join(","))
      .join("\n");

    const link = document.createElement("a");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    link.href = URL.createObjectURL(blob);
    link.download = data.name;
    link.click();
  };

  const bgColor = {
    id: `areaChart_${data.id}`,
    beforeDraw: (chart: any, option: any) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.fillStyle = "#111111";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  if (loading)
    return (
      <div className="w-full h-full grid place-content-center min-h-[200px]">
        <Loader />
      </div>
    );

  return (
    <>
      {!bigGraph && data && (
        <div className="flex flex-col gap-3 w-full mx-auto">
          <div
            className={classNames(
              isScaled ? "min-h-[560px]" : "min-h-[470px]",
              "flex gap-3 w-full border-2 border-green rounded-md p-3 mt-3"
            )}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between">
                <p className="text-lg font-bold">{data.name}</p>
                {/* tag controls */}
                <div className="flex items-center justify-between">
                  {isExpression && (
                    <div className="flex gap-2">
                      {/* Expand */}
                      <TooltipProvider delayDuration={0}>
                        <ToolipShadcn>
                          <TooltipTrigger>
                            <div
                              onClick={() => setBigGraph(true)}
                              className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                            >
                              <BiExpand size={isScaled ? 25 : 18} />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent style={{ background: "#34d399" }}>
                            <p className="text-stone-100">Expand</p>
                          </TooltipContent>
                        </ToolipShadcn>
                      </TooltipProvider>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-3 mt-5 w-full">
                <div className="flex flex-col w-full">
                  <div>
                    <Line
                      ref={chartRef}
                      plugins={[bgColor]}
                      options={options}
                      data={chartData}
                    />
                  </div>
                  {bar && expressionWithData && (
                    <div className="w-[70%] mx-auto mt-5">
                      <Bar
                        data={expressionWithData}
                        meta={expressionWithData}
                        barChartName={data.name}
                        mainValue={expressionWithData.value}
                      />
                    </div>
                  )}
                </div>
                <div className=" flex flex-col gap-3 items-center mt-3">
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={zoomIn}
                  >
                    <FiZoomIn size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={zoomOut}
                  >
                    <FiZoomOut size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={resetZoom}
                  >
                    <TbZoomOutArea size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300 mt-3"
                    onClick={downloadCsv}
                  >
                    <PiFileCsv size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={downloadPdf}
                  >
                    <PiFilePdf size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={downloadPng}
                  >
                    <PiFilePng size={20} />
                  </div>
                  <div
                    className={classNames(
                      isDaily ? "font-bold text-green" : "text-white",
                      "cursor-pointer duration-300 mt-3 leading-3"
                    )}
                    onClick={() => setIsDaily(true)}
                  >
                    D
                  </div>
                  <div
                    className={classNames(
                      !isDaily ? "font-bold text-green" : "text-white",
                      "cursor-pointer duration-300 leading-3"
                    )}
                    onClick={() => setIsDaily(false)}
                  >
                    H
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {bigGraph && data && <BigGraph data={data} setBigGraph={setBigGraph} />}
    </>
  );
}
