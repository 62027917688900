import classNames from "utilities/ClassNames";
import Area from "./pages/Area";
import Gauge from "./pages/Gauge";
import GaugeBar from "./pages/GaugeBar";
import Kpi from "./pages/Kpi";
import Large from "./pages/Large";
import OneGauge from "./pages/OneGauge";
import useApp from "hooks/useApp";

export default function TabBody({ screen, tabs, currentTab, setScreen }: any) {
  const { isScaled } = useApp();
  return (
    <div className="relative h-[92%] 2xl:p-3 p-1">
      {tabs[currentTab]?.configured && tabs[currentTab].type === "large" && (
        <Large tab={tabs[currentTab]} />
      )}
      {tabs[currentTab]?.configured && tabs[currentTab].type === "kpi" && (
        <Kpi tab={tabs[currentTab]} />
      )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab].type === "area" && (
          <Area tab={tabs[currentTab]} screen={screen} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab].type === "gauge" && (
          <Gauge tab={tabs[currentTab]} screen={screen} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab]?.type === "gaugeBar" && (
          <GaugeBar tab={tabs[currentTab]} screen={screen} />
        )}
      {screen !== "configuration" &&
        screen !== "newTab" &&
        tabs[currentTab]?.configured &&
        tabs[currentTab]?.type === "oneGaugeBar" && (
          <OneGauge tab={tabs[currentTab]} screen={screen} />
        )}
      {!tabs[currentTab]?.configurations && (
        <div className="flex justify-center items-center gap-3 h-full">
          <div className={classNames(isScaled ? "text-2xl" : "")}>
            Tab is empty,{" "}
          </div>
          <div
            onClick={() => setScreen("configuration")}
            className={classNames(
              isScaled ? "text-2xl" : "",
              "cursor-pointer text-green"
            )}
          >
            Start Configuring
          </div>
        </div>
      )}
    </div>
  );
}
