import Bar from "components/charts/Bar";
import Area from "components/charts/Area";
import { useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";

export default function AreaPage({ tab }: any) {
  const expression = tab?.configurations?.value;

  const [areaData, setAreaData] = useState<any>(null);

  useEffect(() => {
    (async () => {
      if (tab.configurations.type === "tags") {
        const response = await getTagData([expression.id]);
        if (response.status === 200) {
          setAreaData(response.data[0]);
        }
      } else {
        const response = await getExpressionData([expression.id]);
        if (response.status === 200) {
          setAreaData(response.data[0]);
        }
      }
    })();
  }, []);

  return (
    <div className="flex flex-col h-full gap-1">
      <div className="bg-dark px-1 py-2 rounded-md">
        <Area
          item={expression}
          type={tab.configurations.type === "tags" ? "tag" : "expression"}
        />
      </div>
      <div className="w-[80%] mx-auto h-full pt-1">
        {areaData && (
          <Bar
            meta={expression}
            mainValue={areaData.value}
            barChartName={expression?.name}
          />
        )}
      </div>
    </div>
  );
}
