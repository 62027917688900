import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function NewTag({
  selectedTag,
  setSelectedTag,
  createNewTag,
}: any) {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const [newTag, setNewTag] = useState<any>({
    equipmentId: null,
    name: "",
    tagNameIdentifier: "",
    desc: "",
    uom: "",
    minValue: 0,
    maxValue: 0,
    minRange: 0,
    maxRange: 0,
  });

  const resetValues = () => {
    setNewTag({
      ...newTag,
      minValue: "",
      maxValue: "",
      minRange: "",
      maxRange: "",
    });
  };

  const handleChange = (e: { target: { id: any; value: any } }) => {
    if (
      e.target.id === "minValue" ||
      e.target.id === "maxValue" ||
      e.target.id === "minRange" ||
      e.target.id === "maxRange"
    ) {
      const value = parseFloat(e.target.value);
      setNewTag({ ...newTag, [e.target.id]: value });
    } else {
      setNewTag({ ...newTag, [e.target.id]: e.target.value });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const result = await createNewTag(newTag);
    if (result.message === "success") {
      return setSelectedTag({ action: "", value: null });
    } else {
      setErrors(result.value);
    }
    setLoading(false);
  };

  return (
    <>
      {selectedTag.action === "new" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          {!loading && (
            <div
              className={classNames(
                isScaled ? "mt-48 sm:max-w-[800px]" : "sm:max-w-[650px]",
                "w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              )}
            >
              <div className="bg-dark rounded-md p-3">
                <div className="flex flex-col gap-5">
                  {/* Header */}
                  <div className="flex justify-between items-center sticky">
                    <h1
                      className={classNames(
                        isScaled ? "text-2xl" : "text-xl ",
                        "font-bold"
                      )}
                    >
                      New Tag
                    </h1>
                    <span
                      className="cursor-pointer"
                      onClick={() =>
                        setSelectedTag({ action: "", value: null })
                      }
                    >
                      <FiX size={20} />
                    </span>
                  </div>
                  {errors?.length > 0 && (
                    <div>
                      {errors.map((error: string) => (
                        <p key={error} className="text-rose-400">
                          {error}
                        </p>
                      ))}
                    </div>
                  )}
                  <form onSubmit={handleSubmit} className="grid gap-4 py-4">
                    {/* PI Name */}
                    <div className="flex flex-col gap-2">
                      <label htmlFor="name">PI Name</label>
                      <input
                        id="name"
                        type="text"
                        placeholder="PI Name"
                        className="col-span-3 input"
                        required
                        value={newTag.name}
                        onChange={handleChange}
                      />
                    </div>
                    {/* Tag Name */}
                    <div className="flex flex-col gap-2">
                      <label htmlFor="tagNameIdentifier">Tag Name</label>
                      <input
                        id="tagNameIdentifier"
                        type="text"
                        placeholder="Tag Name"
                        className="col-span-3 input"
                        required
                        value={newTag.tagNameIdentifier}
                        onChange={handleChange}
                      />
                    </div>
                    {/* Tag Description */}
                    <div className="flex flex-col gap-2">
                      <label htmlFor="description">Tag Description</label>
                      <input
                        id="desc"
                        type="text"
                        placeholder="Description"
                        className="col-span-3 input"
                        required
                        value={newTag.desc}
                        onChange={handleChange}
                      />
                    </div>
                    {/* Engineering Unit */}
                    <div className="flex flex-col gap-2">
                      <label htmlFor="uom">Engineering Unit</label>
                      <input
                        id="uom"
                        type="text"
                        placeholder="Engineering Unit"
                        className="col-span-3 input"
                        required
                        value={newTag.uom}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="flex gap-5">
                      <div className="flex-1">
                        {/* Min Max Values */}
                        <div className="flex gap-3 items-center">
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="minValue">Min Value</label>
                            <input
                              id="minValue"
                              type="number"
                              placeholder="Min Value"
                              className="col-span-3 input"
                              required
                              value={newTag.minValue}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="maxValue">Max Value</label>
                            <input
                              id="maxValue"
                              type="number"
                              placeholder="Max Value"
                              className="col-span-3 input"
                              required
                              value={newTag.maxValue}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/* Min Max Range */}
                        <div className="flex gap-3 items-center">
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="minRange">Min Range</label>
                            <input
                              id="minRange"
                              type="number"
                              placeholder="Min Range"
                              className="col-span-3 input"
                              required
                              value={newTag.minRange}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="flex flex-col gap-2 w-full">
                            <label htmlFor="maxRange">Max Range</label>
                            <input
                              id="maxRange"
                              type="number"
                              placeholder="Max Range"
                              className="col-span-3 input"
                              required
                              value={newTag.maxRange}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center mt-10">
                        <div className="btn" onClick={resetValues}>
                          Reset
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 flex justify-end">
                      <button className="btn" type="submit">
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {loading && (
            <div className="w-full h-full grid place-content-center relative z-30">
              <Loader />
            </div>
          )}
        </div>
      )}
    </>
  );
}
