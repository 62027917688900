import Bar from "components/charts/SmallBar";
import SmallArea from "components/charts/GaugeBarArea";
import { MdQueryStats } from "react-icons/md";
import classNames from "utilities/ClassNames";
import GaugeBox from "./GaugeBox";

import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { useEffect, useState } from "react";

export default function GaugeBar({ tab }: any) {
  const [loading, setLoading] = useState(true);
  const data = tab?.configurations;
  const [allData, setAllData] = useState<any>(null);

  const expressionsIds: any = [];
  const tagsIds: any = [];

  if (
    data &&
    data.k1 &&
    data.k1.value &&
    data.k1.value.item &&
    data.k1.value.item.id &&
    data.k1.value.item.id !== "" &&
    data.k1.value.item.tagNameIdentifier
  ) {
    tagsIds.push(data.k1.value.item.id);
  } else if (data?.k1?.value?.item?.id && data?.k1?.value?.item?.id !== "") {
    expressionsIds.push(data.k1.value.item.id);
  }

  if (
    data &&
    data.k2 &&
    data.k2.value &&
    data.k2.value.item &&
    data.k2.value.item.id &&
    data.k2.value.item.id !== "" &&
    data.k2.value.item.tagNameIdentifier
  ) {
    tagsIds.push(data.k2.value.item.id);
  } else if (data?.k2?.value?.item?.id && data?.k2?.value?.item?.id !== "") {
    expressionsIds.push(data.k2.value.item.id);
  }

  if (
    data &&
    data.k3 &&
    data.k3.value &&
    data.k3.value.item &&
    data.k3.value.item.id &&
    data.k3.value.item.id !== "" &&
    data.k3.value.item.tagNameIdentifier
  ) {
    tagsIds.push(data.k3.value.item.id);
  } else if (data?.k3?.value?.item?.id && data?.k3?.value?.item?.id !== "") {
    expressionsIds.push(data.k3.value.item.id);
  }

  if (
    data &&
    data.k4 &&
    data.k4.value &&
    data.k4.value.item &&
    data.k4.value.item.id &&
    data.k4.value.item.id !== "" &&
    data.k4.value.item.tagNameIdentifier
  ) {
    tagsIds.push(data.k4.value.item.id);
  } else if (data?.k4?.value?.item?.id && data?.k4?.value?.item?.id !== "") {
    expressionsIds.push(data.k4.value.item.id);
  }

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (tagsIds.length > 0) {
        const response = await getTagData(tagsIds);
        if (response.status === 200) {
          setAllData(response.data);
        }
      } else if (expressionsIds.length > 0) {
        const response = await getExpressionData(expressionsIds);
        if (response.status === 200) {
          setAllData(response.data);
        }
      }
    })();
    setLoading(false);
  }, [tab]);

  const [current, setCurrent] = useState(0);
  const [chartType, setChartType] = useState("gauge");

  function changeChart() {
    if (chartType === "gauge") {
      setChartType("area");
    } else {
      setChartType("gauge");
    }
  }

  return (
    <>
      {data && (
        <div className="flex flex-col w-full h-full justify-center items-center">
          {/* Gauge */}
          <div
            className={classNames(
              chartType === "area" ? "pt-0" : "pt-3",
              "flex relative w-full h-[60%] justify-center bg-dark p-1 rounded-md mb-2"
            )}
          >
            <div
              onClick={changeChart}
              className="cursor-pointer z-20 text-white p-1 rounded-md bg-green duration-300 absolute left-0 top-0"
            >
              <MdQueryStats size={20} />
            </div>
            {chartType === "gauge" && (
              <>
                {allData && allData[current] !== null && (
                  <>
                    {current === 0 && allData[0] && (
                      <GaugeBox data={allData[0]} name={data?.k1?.name} />
                    )}
                    {current === 1 && allData[1] && (
                      <GaugeBox data={allData[1]} name={data?.k2?.name} />
                    )}
                    {current === 2 && allData[2] && (
                      <GaugeBox data={allData[2]} name={data?.k3?.name} />
                    )}
                    {current === 3 && allData[3] && (
                      <GaugeBox data={allData[3]} name={data?.k4?.name} />
                    )}
                  </>
                )}
                {allData && allData[current] === null && (
                  <div className="w-full h-full grid place-content-center">
                    <h1>N/A</h1>
                  </div>
                )}
              </>
            )}
            {/* Area */}
            {allData && chartType === "area" && (
              <SmallArea
                item={allData[current]}
                type={allData[current].tagNameIdentifier ? "tag" : "expression"}
              />
            )}
          </div>

          {allData && (
            <div className="h-[40%] grid grid-cols-2 grid-rows-2 gap-1 w-full">
              {allData[0] && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(0);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={data?.k1?.name}
                    rawData={allData[0]}
                    type={allData[0].tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
              {allData[1] && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(1);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={data?.k2?.name}
                    rawData={allData[1]}
                    type={allData[1].tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
              {allData[2] && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(2);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={data?.k3?.name}
                    rawData={allData[2]}
                    type={allData[2].tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
              {allData[3] && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(3);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={data?.k4?.name}
                    rawData={allData[3]}
                    type={allData[3].tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
