import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Tabs from "./Tabs";
import { updateWidgets as apiUpdateWidgets } from "api/dashboard";
import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";

const initialTab = {
  name: "",
  type: "",
  configurations: null,
  configured: false,
};

export default function Widget({ widget, widgets }: any) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [screen, setScreen] = useState<string>("");
  const [newTab, setNewTab] = useState(initialTab);
  const { dashboardWidgets, dashboardWidgetsLoading, updateDashboardWidgets } =
    useApp();

  const updateWidgets = async (id: any, newWidget: any) => {
    const othersWidgets = widgets.filter((w: any) => w.id !== id);
    const newWidgets = [...othersWidgets, newWidget];
    updateDashboardWidgets({ ...dashboardWidgets, widget3: newWidgets });
    setScreen("");
  };

  const addNewTab = (widgetId: any) => {
    const widget = widgets.filter((w: any) => w.id === widgetId)[0];
    const newTabDetails = { id: uuidv4(), ...newTab };
    const newTabs = [...widget.tabs, newTabDetails];
    const newWidget = { ...widget, tabs: newTabs };
    updateWidgets(widget.id, newWidget);
    setCurrentTab(widget.tabs.length);
  };

  const updateTab = (widgetId: string) => {
    const widget = widgets.filter((w: any) => w.id === widgetId)[0];
    const newTabs = [...widget.tabs];
    newTabs[currentTab] = {
      ...newTab,
      configurations: widget.tabs[currentTab].configurations,
      configured: widget.tabs[currentTab].configurations,
    };
    const newWidget = { ...widget, tabs: newTabs };
    updateWidgets(widget.id, newWidget);
  };

  const removeTab = (widgetId: string, tabId: string) => {
    setCurrentTab(0);
    setNewTab(initialTab);
    const widget = widgets.filter((w: any) => w.id === widgetId)[0];
    const newTabs = widget.tabs.filter((tab: any) => tab.id !== tabId);
    const newWidget = { ...widget, tabs: newTabs };
    updateWidgets(widget.id, newWidget);
  };

  const removeWidget = async (widgetId: string) => {
    const newWidgets = widgets.filter((w: any) => w.id !== widgetId);
    updateDashboardWidgets({ ...dashboardWidgets, widget3: newWidgets });
  };

  // if (dashboardWidgetsLoading)
  //   return (
  //     <div className="w-full h-[500px] 2xl:h-[415px] rounded-md gradient p-0.5">
  //       <div className="bg-black rounded-md w-full h-full grid place-content-center">
  //         <Loader />
  //       </div>
  //     </div>
  //   );
  return (
    <div className="w-full h-[500px] 2xl:h-[415px] rounded-md gradient p-0.5">
      <div className="bg-black rounded-md w-full h-full">
        <Tabs
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          screen={screen}
          setScreen={setScreen}
          newTab={newTab}
          setNewTab={setNewTab}
          tabs={widget.tabs}
          addNewTab={addNewTab}
          updateTab={updateTab}
          removeTab={removeTab}
          widget={widget}
          updateWidgets={updateWidgets}
          removeWidget={removeWidget}
        />
      </div>
    </div>
  );
}
