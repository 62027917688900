import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  createUnit,
  updateUnit as apiUpdateUnit,
  deleteUnit as apiDeleteUnit,
} from "api/units";
import DataTable from "components/DataTables";
import Loader from "components/shared/Loader";
import { useState, useEffect } from "react";
import { FiEdit, FiTrash2, FiX } from "react-icons/fi";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function Units({
  setShowEquipments,
  setSelectedUnit,
  data,
  getUnits,
  selectedTrain,
  trains,
}: any) {
  const { isScaled } = useApp();
  const [screen, setScreen] = useState("");
  const [updateUnit, setUpdateUnit] = useState<any>(null);
  const [deleteUnit, setDeleteUnit] = useState<any>(null);
  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "name",
      header: () => <div className="font-bold text-green">Train Name</div>,
      cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setShowEquipments(true);
              setSelectedUnit(row.original);
            }}
            className="cursor-pointer hover:text-green"
          >
            {row.original.name}
          </div>
        );
      },
    },
    {
      accessorKey: "desc",
      header: () => <div className="font-bold text-green">Description</div>,
    },
    {
      accessorKey: "seqOrder",
      header: () => <div className="font-bold text-green">Seq.No.</div>,
    },
    {
      header: () => (
        <div className="text-center font-bold text-green">Actions</div>
      ),
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-center gap-5">
            <div
              onClick={() => {
                setUpdateUnit(row.original);
                setScreen("edit");
              }}
              className="cursor-pointer hover:text-green"
            >
              <FiEdit size={isScaled ? 25 : 20} />
            </div>
            <div
              onClick={() => {
                setDeleteUnit(row.original.id);
                setScreen("delete");
              }}
              className="cursor-pointer hover:text-green"
            >
              <FiTrash2 size={isScaled ? 25 : 20} />
            </div>
          </div>
        );
      },
    },
  ];
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnFilters,
    },
  });

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [seqOrder, setSeqOrder] = useState<any>(0);

  const [trainId, setTrainId] = useState(selectedTrain.name);
  const [menuTrain, setMenuTrain] = useState<any>(null);

  useEffect(() => {
    if (trainId) {
      const result = trains.filter((train: any) => train.name === trainId);
      if (result.length) {
        setMenuTrain(result[0].id);
      }
    }
  }, [trainId]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const response = await createUnit({
      name,
      desc,
      seqOrder: parseInt(seqOrder),
      plantId: selectedTrain.plantId,
      trainId: selectedTrain.id,
      unitId: null,
    });
    if (response.status === 200) {
      getUnits();
      setScreen("");
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    const response = await apiUpdateUnit({
      name: updateUnit.name,
      desc: updateUnit.desc,
      seqOrder: parseInt(updateUnit.seqOrder),
      plantId: selectedTrain.plantId,
      trainId: selectedTrain.id,
      unitId: null,
      id: updateUnit.id,
    });
    if (response.status === 200) {
      getUnits();
      setScreen("");
    }
  };

  const handleDelete = async (event: any) => {
    event.preventDefault();
    const response = await apiDeleteUnit(deleteUnit);
    if (response.status === 200) {
      getUnits();
      setScreen("");
    }
  };

  return (
    <div
      className={classNames(
        isScaled ? "h-[500px]" : "h-full",
        "gradient p-0.5 rounded-md overflow-hidden"
      )}
    >
      <div className="bg-black pl-3 pt-3 pb-3 rounded-md h-full overflow-hidden overflow-y-scroll">
        {!data && (
          <div className="w-full h-full grid place-content-center">
            <Loader />
          </div>
        )}
        {data && (
          <>
            <h1 className="font-bold text-lg mb-2">Units</h1>
            <DataTable
              table={table}
              showToolbar
              searchColumn="name"
              searchPlaceholder="Search"
              columns={columns}
              data={data}
              noBorder
              isScaled={isScaled}
              rightMenuAction={
                <button className="btn" onClick={() => setScreen("add")}>
                  Add
                </button>
              }
            />
          </>
        )}
        {data && screen === "add" && (
          <div className="fixed inset-0 w-full h-full z-10">
            <div className="fixed inset-0 bg-black/80 w-full h-full z-20" />
            <div className="w-[700px] z-30 absolute bg-dark p-3 rounded-md transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              {/* header */}
              <div className="flex w-full justify-between">
                <h1 className="text-xl font-bold">New Unit</h1>
                <div onClick={() => setScreen("")} className="cursor-pointer">
                  <FiX size={20} />
                </div>
              </div>
              {/* content */}
              <div className="mt-3">
                <form onSubmit={handleSubmit}>
                  {/* name */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Name</label>
                    <input
                      value={name}
                      onChange={(event: any) => setName(event.target.value)}
                      required
                      type="text"
                      className="input"
                      placeholder="Name"
                    />
                  </div>
                  {/* description */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Description</label>
                    <input
                      value={desc}
                      onChange={(event: any) => setDesc(event.target.value)}
                      required
                      type="text"
                      className="input"
                      placeholder="Description"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Sequence Order</label>
                    <input
                      value={seqOrder}
                      onChange={(event: any) => setSeqOrder(event.target.value)}
                      required
                      type="number"
                      className="input"
                      placeholder="Sequence Order"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex justify-end">
                    <button type="submit" className="btn">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {data && screen === "edit" && updateUnit && (
          <div className="fixed inset-0 w-full h-full z-10">
            <div className="fixed inset-0 bg-black/80 w-full h-full z-20" />
            <div className="w-[700px] z-30 absolute bg-dark p-3 rounded-md transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              {/* header */}
              <div className="flex w-full justify-between">
                <h1 className="text-xl font-bold">New Train</h1>
                <div onClick={() => setScreen("")} className="cursor-pointer">
                  <FiX size={20} />
                </div>
              </div>
              {/* content */}
              <div className="mt-3">
                <form onSubmit={handleUpdate}>
                  {/* name */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Name</label>
                    <input
                      value={updateUnit.name}
                      onChange={(event: any) =>
                        setUpdateUnit({
                          ...updateUnit,
                          name: event.target.value,
                        })
                      }
                      required
                      type="text"
                      className="input"
                      placeholder="Name"
                    />
                  </div>
                  {/* description */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Description</label>
                    <input
                      value={updateUnit.desc}
                      onChange={(event: any) =>
                        setUpdateUnit({
                          ...updateUnit,
                          desc: event.target.value,
                        })
                      }
                      required
                      type="text"
                      className="input"
                      placeholder="Description"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Sequence Order</label>
                    <input
                      value={updateUnit.seqOrder}
                      onChange={(event: any) =>
                        setUpdateUnit({
                          ...updateUnit,
                          seqOrder: event.target.value,
                        })
                      }
                      required
                      type="number"
                      className="input"
                      placeholder="Sequence Order"
                    />
                  </div>
                  {/* trains */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Trains</label>
                    <Select
                      onValueChange={(value: any) => setTrainId(value.id)}
                      defaultValue={trainId}
                    >
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select Train" />
                      </SelectTrigger>
                      <SelectContent className="w-full bg-dark">
                        {trains.map((train: any) => (
                          <SelectItem key={train.id} value={train.name}>
                            {train.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex justify-end">
                    <button type="submit" className="btn">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {data && screen === "delete" && deleteUnit && (
          <div className="fixed inset-0 w-full h-full bg-black/90 grid place-content-center">
            <div className="flex justify-center items-center flex-col gap-5 p-3 pb-5 text-center bg-dark rounded-md">
              <p>Are you sure you want to delete this unit</p>
              <div className="flex gap-5">
                <button className="btn-sec" onClick={() => setScreen("")}>
                  cancel
                </button>
                <button className="btn-danger" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
