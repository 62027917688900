import Select from "components/widgets/Select";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";
export default function GaugeConfiguration({
  gaugeBar,
  setGaugeBar,
  handleConfiguration,
  setScreen,
  configError,
}: any) {
  const { isScaled } = useApp();
  return (
    <form className="flex flex-col gap-3 w-11/12 mx-auto">
      {/* K1 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gaugeBar?.k1.name}
            onChange={(e: any) =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k1: {
                  ...gaugeBar?.k1,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gaugeBar.k1.id}
            selectedValue={gaugeBar.k1.value.item}
            clear={() =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k1: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGaugeBar((prev: any) => ({
                ...prev,
                k1: {
                  ...gaugeBar.k1,
                  id: item.id,
                  value: item,
                },
              }));
            }}
          />
        </div>
      </div>

      {/* K2 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gaugeBar?.k2.name}
            onChange={(e: any) =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k2: {
                  ...gaugeBar?.k2,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gaugeBar.k2.id}
            selectedValue={gaugeBar.k2.value.item}
            clear={() =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k2: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGaugeBar((prev: any) => ({
                ...prev,
                k2: {
                  ...gaugeBar.k2,
                  id: item.id,
                  value: item,
                },
              }));
            }}
          />
        </div>
      </div>

      {/* K3 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gaugeBar?.k3.name}
            onChange={(e: any) =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k3: {
                  ...gaugeBar?.k3,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gaugeBar.k3.id}
            selectedValue={gaugeBar.k3.value.item}
            clear={() =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k3: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGaugeBar((prev: any) => ({
                ...prev,
                k3: {
                  ...gaugeBar.k3,
                  id: item.id,
                  value: item,
                },
              }));
            }}
          />
        </div>
      </div>

      {/* K4 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gaugeBar?.k4.name}
            onChange={(e: any) =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k4: {
                  ...gaugeBar?.k4,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gaugeBar.k4.id}
            selectedValue={gaugeBar.k4.value.item}
            clear={() =>
              setGaugeBar((prev: any) => ({
                ...prev,
                k4: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGaugeBar((prev: any) => ({
                ...prev,
                k4: {
                  ...gaugeBar.k4,
                  id: item.id,
                  value: item,
                },
              }));
            }}
          />
        </div>
      </div>

      <div className="flex gap-1 justify-end mt-3">
        <div className="text-red">{configError}</div>
        <div className="flex items-center gap-3">
          <div
            onClick={() => {
              setScreen("");
            }}
            className="btn-sec"
          >
            Cancel
          </div>
          <div onClick={handleConfiguration} className="btn">
            Commit
          </div>
        </div>
      </div>
    </form>
  );
}
