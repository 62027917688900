import React from "react";
import Area from "components/charts/newDashboard/Area";
import Bar from "components/charts/newDashboard/Bar";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function Chart({ data }: any) {
  const { isScaled } = useApp();
  return (
    <div className="flex flex-col w-full mx-auto justify-center items-center">
      <div className="2xl:w-[90%] w-full bg-dark px-1 pt-1 rounded-md">
        <Area
          item={data}
          type={data.tagNameIdentifier ? "tag" : "expression"}
        />
      </div>
      <div className={classNames(isScaled ? "w-[70%]" : "w-[80%]", "mx-auto")}>
        <Bar meta={data} mainValue={data.value} barChartName={data.name} />
      </div>
    </div>
  );
}
