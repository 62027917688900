import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function UpdateTab({
  setTabError,
  newTab,
  updateTab,
  setNewTab,
  widget,
  tabError,
  setScreen,
  tabs,
  currentTab,
}: any) {
  const { isScaled } = useApp();
  const handleUpdateTab = (event: any) => {
    event.preventDefault();
    if (newTab.name === "") {
      setTabError("All fields are required");
    } else {
      updateTab(widget.id, tabs[currentTab].id);
      setScreen("");
      setTabError("");
    }
  };
  return (
    <div className="h-full w-full flex items-center p-3">
      <form
        className="w-full flex flex-col justify-between"
        onSubmit={handleUpdateTab}
      >
        <div className="flex flex-col gap-1">
          <label
            htmlFor="name"
            className={classNames(isScaled ? "text-2xl" : "")}
          >
            Edit Name
          </label>
          <input
            className={classNames(isScaled ? "text-lg" : "", "input w-1/2")}
            name="name"
            id="name"
            placeholder="Name"
            type="text"
            required
            value={newTab.name}
            onChange={(e: any) =>
              setNewTab((prev: any) => ({ ...prev, name: e.target.value }))
            }
          />
        </div>
        <div className="flex gap-3 mt-5">
          <div className="text-red">{tabError}</div>
          <div className="flex-1" />
          <div className="flex items-center gap-3">
            <div className="btn-sec" onClick={() => setScreen("")}>
              Cancel
            </div>
            <button type="submit" className="btn">
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
