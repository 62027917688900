import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import Widget from "./Widget";
import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";
import { v4 as uuidv4 } from "uuid";

export default function WidgetGrid() {
  const [lastWidget, setLastWidget] = useState<any>(null);
  const { dashboardWidgets, dashboardWidgetsLoading, updateDashboardWidgets } =
    useApp();

  useEffect(() => {
    if (dashboardWidgets.widget3) {
      setLastWidget(
        dashboardWidgets.widget3[dashboardWidgets.widget3.length - 1]
      );
    }
  }, [dashboardWidgets.widget3]);

  const handleNewWidget = (pos: number) => {
    const data = [
      ...dashboardWidgets.widget3,
      { id: uuidv4(), tabs: [], position: pos },
    ];
    updateDashboardWidgets({ ...dashboardWidgets, widget3: data });
  };

  // if (dashboardWidgetsLoading)
  //   return (
  //     <div className="w-full h-full grid place-content-center min-h-[200px]">
  //       <Loader />
  //     </div>
  //   );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-rows-2 gap-2 pt-2 pl-3 pb-10 2xl:pb-3 w-full h-full">
      {dashboardWidgets.widget3 &&
        lastWidget &&
        dashboardWidgets.widget3.length > 0 &&
        dashboardWidgets.widget3
          .sort((a: any, b: any) => a.position - b.position)
          .map((widget: any) => (
            <Widget
              key={widget.id}
              widgets={dashboardWidgets.widget3}
              widget={widget}
              dashboardWidgetsLoading={dashboardWidgetsLoading}
            />
          ))}
      {!dashboardWidgets.widget3 ||
        (dashboardWidgets.widget3 && dashboardWidgets.widget3.length < 6 && (
          <div
            className={classNames(
              dashboardWidgets.widget3.length > 0
                ? "bg-transparent"
                : "bg-dark",
              "w-widgets h-[400px] grid place-content-center rounded-md p-3"
            )}
          >
            <div
              className="flex flex-col gap-3 justify-center items-center cursor-pointer hover:text-green duration-300"
              onClick={() =>
                handleNewWidget(
                  dashboardWidgets.widget3.length
                    ? parseInt(lastWidget?.position + 1)
                    : 1
                )
              }
            >
              <FiPlus size={50} />
              Add Widget
            </div>
          </div>
        ))}
    </div>
  );
}
