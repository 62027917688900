const colorCodes: any = {
  white: "#555555",
  red: "#f43f5e",
  orange: "#f59e0b",
  redShadow: "#f95460",
  green: "#10b981",
  greenShadow: "#047857",
};

const calculateData = (operatingRange: any, noOfParts: any) => {
  const { minValue, maxValue, value, minRange, maxRange } = operatingRange;
  const operatingRangeArr = [minValue, minRange, maxRange, maxValue];
  const tempAccumulator: any = {
    data: [],
    bg: [],
  };
  const accumulatorDataSet: number[] = [];
  const tempDS = operatingRangeArr.reduce(
    (accumulator, currentValue, currentIndex) => {
      let temp = 0;
      if (currentIndex != operatingRangeArr.length - 1) {
        const nextValue = operatingRangeArr[++currentIndex];
        if (currentValue != null && nextValue != null) {
          temp = Math.abs(nextValue - currentValue);
          accumulator.push(temp);
        }
      }

      return accumulator;
    },
    accumulatorDataSet
  );

  const totalValue = tempDS.reduce(
    (accumulator: any, data: any) => accumulator + data,
    0
  );

  /* operating range chart - starts */
  // let totalParts = 100;
  const totalParts = noOfParts;
  const eachPart = totalValue / totalParts;

  const finalDataSet = tempDS.reduce(
    (accumulator: any, currentValue: any, currentIndex: any) => {
      const tempPart = Math.floor((currentValue / totalValue) * totalParts);
      for (let i = 1; i <= tempPart; i = i + 2) {
        if (currentIndex === 0 || currentIndex === 2) {
          // red
          accumulator.bg.push("#DA4E59");
        } else {
          // blue
          accumulator.bg.push("#437EBA");
        }

        accumulator.bg.push("transparent");

        accumulator.data.push(eachPart);
        accumulator.data.push(eachPart);
      }
      return accumulator;
    },
    tempAccumulator
  );

  /* operating range chart - ends */

  const actualValueDataSet = {
    data: [0],
    bg: [""],
    shadowBg: [""],
  };
  let zone = "green";

  /* actual value indicator - starts */
  let defaultDataSet = 1;
  if (value != null) {
    if (
      minRange != null &&
      maxRange != null &&
      minValue != null &&
      maxValue != null
    ) {
      let occupyFull = 0;

      // define zone
      if (value >= minRange && value <= maxRange) {
        defaultDataSet = 0;
      } else if (value >= minValue && value <= maxValue) {
        defaultDataSet = 0;
        zone = "red";
      } else if (value > maxValue) {
        occupyFull = 1;
        zone = "red";
      }

      // define zone values
      if (!defaultDataSet) {
        let zoneValue = value;
        if (minValue != null && value != null) {
          if (minValue < 0) {
            if (value === 0) {
              zoneValue = Math.abs(minValue);
            } else if (value > 0) {
              zoneValue = Math.abs(value - minValue);
            } else {
              zoneValue = Math.abs(value - minValue);
            }
          } else if (minValue > 0) {
            if (value === 0) {
              zoneValue = minValue - value;
            } else if (value > 0) {
              zoneValue = value - minValue;
            }
          }
        }
        actualValueDataSet.bg = [colorCodes[`${zone}`], colorCodes.white];
        actualValueDataSet.shadowBg = [
          colorCodes[`${zone}Shadow`],
          "transparent",
        ];
        actualValueDataSet.data = [zoneValue, totalValue - zoneValue];
      }

      if (occupyFull) {
        defaultDataSet = 0;
        actualValueDataSet.bg = [colorCodes[`${zone}`]];
        actualValueDataSet.shadowBg = [colorCodes[`${zone}Shadow`]];
        actualValueDataSet.data = [totalValue];
      }
    }
  }
  /* actual value indicator - ends */

  let disable = false;
  // disable the chart for null values
  if (operatingRangeArr.includes(null) || value == null) {
    disable = true;
  }
  return {
    totalValue,
    rangeSet: finalDataSet,
    valueSet: actualValueDataSet,
    disable: disable,
    defaultDataSet,
    inRange: zone === "green" ? true : false,
  };
};

export default calculateData;
