import { useState, useEffect, useCallback, useContext } from "react";
import { SmallLoader } from "components/shared/Loader";
import { Formik, Form } from "formik";
import WebGLContext, { INote } from "./WebGlContext";
import { useUpdate, useGet } from "./apiHook";
import {
  createBubble,
  createNote,
  deleteBubble,
  deleteNote,
  downloadFile,
  getBubble,
  updateBubble,
  updateNote,
} from "api/unity";
import { saveAs } from "file-saver";
import { b64toBlob, errorHandling } from "./apiHook";
import { FiChevronDown, FiX } from "react-icons/fi";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import TextOverflow from "utilities/TextOverflow";
import { Checkbox } from "@/components/ui/checkbox";
import { getEquipmentsByUnitId } from "api/equipments";

const Bubble = ({
  bubbleId,
  bubbleDetails,
  closeHandler,
  updateBubbles,
  units,
  equipments,
  pauseUnity,
  resumeUnity,
  selectedUnit,
}: any) => {
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submittingForm, setSubmittingForm] = useState(false);

  const [values, setValues] = useState<any>();
  const [name, setName] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  const [openSelectUnit, setOpenSelectUnit] = useState(false);
  const [openSelectEquipment, setOpenSelectEquipment] = useState(false);

  const [selectUnitLabel, setSelectUnitLabel] = useState("");
  const [selectEquipmentLabel, setSelectEquipmentLabel] = useState("");

  const [unit, setUnit] = useState<any>(null);
  const [equipment, setEquipment] = useState<any>(null);

  const [modelID, setModelID] = useState("");

  const [showModelId, setShowModelId] = useState<any>(false);
  const [bubbleData, setBubbleData] = useState<any>(null);

  const [allEquipments, setAllEquipments] = useState<any>(equipments);

  const editMode = Boolean(bubbleId);

  useEffect(() => {
    pauseUnity();
    return () => resumeUnity();
  }, []);

  useEffect(() => {
    (async () => {
      if (unit) {
        const result = await getEquipmentsByUnitId(unit);
        setAllEquipments(result.data);
      }
    })();
  }, [unit]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (editMode) {
        const bubble = await getBubble(bubbleId);
        setBubbleData(bubble.data);
        setValues({
          ...values,
          id: bubble.data.id,
          x: bubble.data.x,
          y: bubble.data.y,
          z: bubble.data.z,
          prefabParentId: bubble.data.prefabParentId,
          prefabId: bubble.data.prefabId,
          equipmentId: bubble.data.equipmentId,
          unitId: bubble.data.unitId,
        });
        setName(bubble.data.name);
        setDescription(bubble.data.desc);
        setModelID(bubble.data.unityModelId);
        if (bubble.data.unitId) {
          setSelectUnitLabel(
            units.filter((unit: any) => unit.id === bubble.data.unitId)[0].name
          );
          setUnit(
            units.filter((unit: any) => unit.id === bubble.data.unitId)[0].id
          );
        }
        if (selectedUnit) {
          setSelectUnitLabel(
            units.filter((unit: any) => unit.id === selectedUnit)[0].name
          );
          setUnit(units.filter((unit: any) => unit.id === selectedUnit)[0].id);
        }
        if (bubble.data.equipmentId) {
          setSelectEquipmentLabel(
            equipments.filter(
              (equipment: any) => equipment.id === bubble.data.equipmentId
            )[0].name
          );
          setEquipment(
            equipments.filter(
              (equipment: any) => equipment.id === bubble.data.equipmentId
            )[0].id
          );
        }
      }
    })();
    setLoading(false);
  }, [bubbleId]);

  const bubbleHandler = async (e: any) => {
    setSubmittingForm(true);
    e.preventDefault();
    if (editMode) {
      await updateBubble({
        name,
        desc: description,
        unitId: unit,
        equipmentId: equipment,
        unityModelId: modelID,
        x: values.x,
        y: values.y,
        z: values.z,
        id: values.id,
        prefabParentId: values.prefabParentId,
        prefabId: values.prefabId,
      });
      updateBubbles();
      setSubmittingForm(false);
    } else {
      await createBubble({
        name,
        desc: description,
        unitId: unit,
        equipmentId: equipment,
        unityModelId: modelID,
        x: bubbleDetails.x,
        y: bubbleDetails.y,
        z: bubbleDetails.z,
      });
      updateBubbles();
      setSubmittingForm(false);
    }
    closeHandler();
  };

  const deleteHandler = async () => {
    setSubmittingForm(true);
    if (bubbleId) {
      const text =
        "Closing the window will delete the Note. Are you sure you want to close ?";
      if (window.confirm(text) === true) {
        await deleteBubble(bubbleId);
        setValues(null);
        updateBubbles();
      }
    }
    closeHandler();
  };

  return (
    <div className="fixed inset-0 z-10 w-full h-screen">
      <div className="absolute z-20 inset-0 w-full h-full bg-black/70" />

      <div className="w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px]">
        <div className="bg-dark p-3 w-full h-full rounded-md">
          {loading && (
            <div className="w-full h-[300px] grid place-content-center">
              <SmallLoader />
            </div>
          )}
          {!loading && (
            <>
              {/* Header */}
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-green">
                  Bubble Configuration
                </h1>
                <div
                  className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                  onClick={closeHandler}
                >
                  <FiX size={20} />
                </div>
              </div>
              {/* Content */}
              <div className="mt-5">
                {message.map((eachMessage, index) => (
                  <div className="pt-4" key={`ticket_${index}`}>
                    <p className={error ? "text-danger" : "text-success"}>
                      {eachMessage}
                    </p>
                  </div>
                ))}
                <form className="mt-3" onSubmit={bubbleHandler}>
                  <div className="mt-3">
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      name="name"
                      placeholder="Name"
                      type="text"
                      required={true}
                      className="input mt-1"
                      value={name}
                      onChange={(e: any) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="desc">Description</label>
                    <textarea
                      rows={5}
                      id="desc"
                      name="desc"
                      placeholder="Description"
                      className="input mt-1"
                      value={description}
                      onChange={(e: any) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="flex items-center gap-3 mt-3">
                    {/* units */}
                    <div className="w-1/2">
                      <div className="mb-2">Unit</div>
                      <Popover
                        open={openSelectUnit}
                        onOpenChange={setOpenSelectUnit}
                      >
                        <PopoverTrigger asChild>
                          <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                            {selectUnitLabel && selectUnitLabel !== ""
                              ? TextOverflow(selectUnitLabel, 27)
                              : "Select Unit"}
                            <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent className="w-[240px] p-0 border-slate bg-black command">
                          <Command className="bg-dark border-slate">
                            <CommandInput
                              placeholder="Search"
                              className="bg-dark border-b-slate"
                            />
                            <CommandEmpty>No units found.</CommandEmpty>
                            {units && (
                              <CommandGroup className="bg-dark border-slate h-[230px] overflow-hidden overflow-y-scroll">
                                <div>
                                  {units &&
                                    units.map((item: any) => (
                                      <CommandItem
                                        className="pl-3"
                                        key={item.id}
                                        onSelect={(currentValue) => {
                                          setUnit(item.id);
                                          setSelectUnitLabel(item.name);
                                          setOpenSelectUnit(false);
                                        }}
                                      >
                                        {item.name}
                                      </CommandItem>
                                    ))}
                                </div>
                              </CommandGroup>
                            )}
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>

                    {/* equipments */}
                    <div className="w-1/2">
                      <div className="mb-2">Equipment</div>
                      <Popover
                        open={openSelectEquipment}
                        onOpenChange={setOpenSelectEquipment}
                      >
                        <PopoverTrigger asChild>
                          <div className="w-full text-sm whitespace-nowrap h-9 px-4 py-2 justify-between cursor-pointer inline-flex items-center rounded-md border-2 border-slate bg-black">
                            {selectEquipmentLabel && selectEquipmentLabel !== ""
                              ? TextOverflow(selectEquipmentLabel, 27)
                              : "Select Equipment"}
                            <FiChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent className="w-[240px] p-0 border-slate bg-black command">
                          <Command className="bg-dark border-slate">
                            <CommandInput
                              placeholder="Search"
                              className="bg-dark border-b-slate"
                            />
                            <CommandEmpty>No equipments found.</CommandEmpty>
                            {allEquipments && (
                              <CommandGroup className="bg-dark border-slate h-[230px] overflow-hidden overflow-y-scroll">
                                <div>
                                  {allEquipments &&
                                    allEquipments.map((item: any) => (
                                      <CommandItem
                                        className="pl-3"
                                        key={item.id}
                                        onSelect={(currentValue) => {
                                          setEquipment(item.id);
                                          setSelectEquipmentLabel(item.name);
                                          setOpenSelectEquipment(false);
                                        }}
                                      >
                                        {item.name}
                                      </CommandItem>
                                    ))}
                                </div>
                              </CommandGroup>
                            )}
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2 mt-6">
                    <Checkbox
                      id="model"
                      checked={showModelId}
                      onCheckedChange={setShowModelId}
                    />
                    <label
                      htmlFor="model"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Assign 3D Model ID for Highlighting.
                    </label>
                  </div>

                  {showModelId && (
                    <div className="mt-6">
                      <label htmlFor="modelId">Model ID</label>
                      <input
                        id="modelId"
                        name="modelId"
                        placeholder="Model ID"
                        type="text"
                        required={true}
                        className="input mt-1"
                        value={modelID}
                        onChange={(e: any) => setModelID(e.target.value)}
                      />
                    </div>
                  )}

                  <div className="flex flex-row-reverse mt-5 gap-5">
                    <input
                      name="create"
                      id="create"
                      key="create"
                      value={editMode ? "Update" : "Create"}
                      type="submit"
                      className="btn btn-fill btn-primary"
                      disabled={submittingForm}
                    />
                    {editMode && (
                      <input
                        name="delete"
                        id="delete"
                        key="delete"
                        value="Delete"
                        type="button"
                        onClick={deleteHandler}
                        className="btn"
                      />
                    )}
                    {submittingForm && <SmallLoader />}
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Content end */}
        </div>
      </div>
    </div>
  );
};
export default Bubble;

// import React, { useState, useEffect, useContext, useCallback } from "react";
// import WebGLContext, { IBubble } from "./WebGlContext";
// import { useUpdate, useGet } from "./apiHook";
// import Loader from "components/shared/Loader";
// import { Formik, Form, Field } from "formik";
// import Select from "components/Input/Select";
// import TextInput from "components/Input/TextInput";
// import { FiX } from "react-icons/fi";
// import { Checkbox } from "@/components/ui/checkbox";

// export const changeToOptions = (values: any) => {
//   const options = values.map((data: any) => {
//     return { value: data.id, label: data.name };
//   });
//   return options;
// };

// const Bubble = ({
//   bubbleDetails,
//   bubbleId,
//   closeHandler,
//   modalOpen,
//   updateBubbles,
//   unitFullOptions,
//   equipmentFullOptions,
// }: any) => {
//   const [message, setMessage] = useState<string[]>([]);
//   const [error, setError] = useState(false);
//   const [loader, setLoader] = useState(true);
//   const [post, setPost] = useState(false);
//   const [values, setValues] = useState<any>(null);
//   const [deleteId, setDelete] = useState(0);
//   const [equipments, setEquipments] = useState<any>([]);
//   const [equipmentOptions, setEquipmentOptions] = useState<any>([]);
//   const [unitOption, setUnitOption] = useState<any>();
//   const [equipmentOption, setEquipmentOption] = useState<any>();
//   const [unitId, setUnitId] = useState<null | number>(null);
//   const [equipmentId, setEquipmentId] = useState<null | number>(null);
//   const [ischecked, setischecked] = useState(false);
//   const editMode = Boolean(bubbleId);
//   const handleCheckbox = () => {
//     setischecked((state) => !state);
//   };
//   const { handleModalLoader } = useContext(WebGLContext);
//   const {
//     data: configuredSuccessfully,
//     loader: load,
//     message: messageRes,
//     error: errorRes,
//   } = useUpdate("bubble", post, bubbleId, editMode, deleteId, values);
//   const {
//     data,
//     loader: loadConfig,
//     message: messageConfig,
//     error: errorConfig,
//   } = useGet<IBubble>("bubble", bubbleId, editMode);
//   useEffect(() => {
//     const controller = new AbortController();
//     let isApiSubscribed = true;
//     if (isApiSubscribed) {
//       handleModalLoader();
//     }
//     return () => {
//       isApiSubscribed = false;
//       controller.abort();
//     };
//   }, []);
//   useEffect(() => {
//     if (configuredSuccessfully) {
//       closeHandler();
//     }
//   }, [configuredSuccessfully, closeHandler]);
//   useEffect(() => {
//     if (!loader) {
//       updateBubbles();
//     }
//   }, [loader]);

//   const unitHandler = useCallback(
//     (value: any) => {
//       setUnitId(value);
//       const selectedUnit = unitFullOptions.filter(
//         (unit: any) => unit.value === value
//       );
//       if (selectedUnit.length) setUnitOption(selectedUnit[0]);
//       else setUnitOption(null);
//       const equipmentColl = equipments
//         .filter((equip: any) => equip.unitId === value)
//         .map((equip: any) => equip.id);
//       setEquipmentOptions((state: any) => {
//         return equipmentFullOptions.filter((equip: any) =>
//           equipmentColl.includes(equip.value)
//         );
//       });
//       setEquipmentOption(null);
//       setEquipmentId(null);
//     },
//     [unitFullOptions, equipmentFullOptions, equipments]
//   );
//   const equipmentHandler = useCallback(
//     (value: any) => {
//       setEquipmentId(value);
//       const selectedEquipment = equipmentFullOptions.filter(
//         (equipment: any) => equipment.value === value
//       );
//       if (selectedEquipment.length) setEquipmentOption(selectedEquipment[0]);
//       else setEquipmentOption(null);
//     },
//     [equipmentFullOptions]
//   );
//   useEffect(() => {
//     if (values) {
//       const unitId = values?.unitId;
//       const equipmentId = values?.equipmentId;
//       if (equipmentId && !unitId) {
//         values.equipmentId = equipmentId;
//         //get the equipment details using the equipmentId
//         const currequipment = equipments.filter(
//           (eq: any) => eq.id === equipmentId
//         );
//         //get unit id of current equipemt
//         if (currequipment.length) {
//           const currunitid = currequipment[0].unitId;
//           if (currunitid) {
//             const selectedUnit = unitFullOptions.filter(
//               (unit: any) => unit.value === currunitid
//             );
//             if (selectedUnit.length) {
//               setUnitId(currunitid);
//               values.unitId = currunitid;
//               setUnitOption(selectedUnit[0]);
//             } else setUnitOption(null);

//             const equipmentColl = equipments
//               .filter((equip: any) => equip.unitId === currunitid)
//               .map((equip: any) => equip.id);
//             setEquipmentOptions((state: any) => {
//               return equipmentFullOptions.filter((equip: any) =>
//                 equipmentColl.includes(equip.value)
//               );
//             });

//             const selectedEquipment = equipmentFullOptions.filter(
//               (equipment: any) => equipment.value === equipmentId
//             );
//             if (selectedEquipment.length)
//               setEquipmentOption(selectedEquipment[0]);
//             else setEquipmentOption(null);
//           }
//         }
//       }
//       if (unitId && equipmentId) {
//         setUnitId(unitId);
//         setEquipmentId(equipmentId);

//         const selectedUnit = unitFullOptions.filter(
//           (unit: any) => unit.value === unitId
//         );
//         if (selectedUnit.length) setUnitOption(selectedUnit[0]);
//         else setUnitOption(null);

//         const equipmentColl = equipments
//           .filter((equip: any) => equip.unitId === unitId)
//           .map((equip: any) => equip.id);
//         setEquipmentOptions((state: any) => {
//           return equipmentFullOptions.filter((equip: any) =>
//             equipmentColl.includes(equip.value)
//           );
//         });

//         const selectedEquipment = equipmentFullOptions.filter(
//           (equipment: any) => equipment.value === equipmentId
//         );
//         if (selectedEquipment.length) setEquipmentOption(selectedEquipment[0]);
//         else setEquipmentOption(null);
//       } else if (unitId) {
//         setUnitId(unitId);
//         const selectedUnit = unitFullOptions.filter(
//           (unit: any) => unit.value === unitId
//         );
//         if (selectedUnit.length) setUnitOption(selectedUnit[0]);
//         else setUnitOption(null);
//       } else {
//         setUnitOption(null);
//         setEquipmentOption(null);
//       }
//     }
//   }, [values, unitFullOptions, equipmentFullOptions, equipments]);

//   useEffect(() => {
//     if (data) {
//       setValues(data);
//     }
//     setPost(false);
//   }, [data]);
//   useEffect(() => {
//     setLoader(loadConfig);
//   }, [loadConfig]);
//   useEffect(() => {
//     setMessage(messageConfig);
//   }, [messageConfig]);
//   useEffect(() => {
//     setError(errorConfig);
//   }, [errorConfig]);
//   useEffect(() => {
//     setLoader(load);
//   }, [load]);
//   useEffect(() => {
//     setMessage(messageRes);
//   }, [messageRes]);
//   useEffect(() => {
//     setError(errorRes);
//   }, [errorRes]);
//   let formValues;
//   if (editMode) {
//     if (values) {
//       if (values.equipmentId) {
//         formValues = {
//           id: bubbleId,
//           ...values,
//         };
//       } else {
//         formValues = {
//           ...values,
//           id: bubbleId,
//           unitId: null,
//           equipmentId: null,
//         };
//       }
//     } else {
//       formValues = {
//         id: bubbleId,
//         unitId: null,
//         equipmentId: null,
//         name: "",
//         desc: "",
//       };
//     }
//   } else {
//     if (bubbleDetails) {
//       formValues = {
//         name: "",
//         desc: "",
//         ...bubbleDetails,
//       };
//     } else {
//       formValues = { name: "", desc: "", unitId: null, equipmentId: null };
//     }
//   }
//   console.log("bd", values);
//   useEffect(() => {
//     if (values && values.unityModelId) {
//       setischecked(true);
//     }
//   }, [values]);
//   useEffect(() => {
//     setValues(null);
//   }, [post]);
//   const submitHandler = (values: any) => {
//     const text = editMode
//       ? "Closing the window will update  the bubble. Are you sure you want to close ?"
//       : "Closing the window will create the bubble. Are you sure you want to close ?";
//     if (window.confirm(text) === true) {
//       const formValues = { ...values, equipmentId, unitId };
//       if (ischecked) {
//         setValues({ ...formValues });
//       } else {
//         const formValues = {
//           ...values,
//           equipmentId,
//           unitId,
//           unityModelId: null,
//         };
//         setValues({ ...formValues });
//       }
//       setPost(true);
//     }
//   };
//   const deleteHandler = () => {
//     if (bubbleId) {
//       const text =
//         "Closing the window will delete the bubble. Are you sure you want to close ?";
//       if (window.confirm(text) === true) {
//         setValues(null);
//         setDelete(bubbleId);
//         setPost(true);
//       }
//     }
//   };

//   return (
//     <div className="fixed inset-0 z-10 w-full h-screen">
//       <div className="absolute z-20 inset-0 w-full h-full bg-black/70" />

//       <div className="w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px]">
//         <div className="bg-dark p-3 w-full h-full rounded-md">
//           {/* Header */}
//           <div className="flex justify-between">
//             <h1 className="text-2xl font-bold text-green">
//               Bubble Configuration
//             </h1>
//             <div
//               className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
//               onClick={closeHandler}
//             >
//               <FiX size={20} />
//             </div>
//           </div>

//           <div className="tags-chart-wrapper">
//             <div className="bubble-popup-container tag-chart-top-container d-flex flex-wrap charts-grid tags-ticket-creation-popup">
//               <div className="h-100 w-100">
//                 <h2 className="panel-heading" style={{ width: "auto" }}>
//                   Bubble Configuration
//                 </h2>
//                 <div className="panel-stats ticket-panel text-left">
//                   <div>
//                     {message.map((eachMessage, index) => (
//                       <div className="pt-4" key={`ticket_${index}`}>
//                         <p className={error ? "text-danger" : "text-success"}>
//                           {eachMessage}
//                         </p>
//                       </div>
//                     ))}
//                     <Formik
//                       initialValues={formValues}
//                       onSubmit={async (values, actions) => {
//                         await submitHandler(values);
//                       }}
//                       enableReinitialize={true}
//                     >
//                       {(formik) => (
//                         <div
//                           className="card"
//                           style={{ marginBottom: "0", paddingRight: "10px" }}
//                         >
//                           <Form>
//                             <div className="form-group">
//                               <TextInput
//                                 key="name"
//                                 id="name"
//                                 name="name"
//                                 label="Name"
//                                 placeholder="Name"
//                                 type="text"
//                                 required={true}
//                               />
//                             </div>
//                             <div className="form-group">
//                               <TextInput
//                                 key="desc"
//                                 id="desc"
//                                 name="desc"
//                                 label="Description"
//                                 placeholder="Description"
//                                 type="text"
//                               />
//                             </div>
//                             <div className="form-group px-md-1">
//                               <label htmlFor="priority">Unit</label>
//                               <div
//                                 style={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                 }}
//                               >
//                                 <Field
//                                   name={"unitId"}
//                                   key={"unitId"}
//                                   component={Select}
//                                   options={unitFullOptions}
//                                   value={unitOption}
//                                   className="prioritySelect"
//                                   placeholder="Unit"
//                                   onChange={unitHandler}
//                                 />
//                               </div>
//                             </div>
//                             <div className="form-group px-md-1">
//                               <label htmlFor="priority">Equipment</label>
//                               <div
//                                 style={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                 }}
//                               >
//                                 <Field
//                                   name={"equipmentId"}
//                                   key={"equipmentId"}
//                                   component={Select}
//                                   options={equipmentOptions}
//                                   value={equipmentOption}
//                                   className="prioritySelect"
//                                   placeholder="Equipment"
//                                   onChange={equipmentHandler}
//                                 />
//                               </div>
//                             </div>
//                             <div className="flex items-center space-x-2 mt-6">
//                               <Checkbox
//                                 id="model"
//                                 checked={ischecked}
//                                 onCheckedChange={handleCheckbox}
//                               />
//                               <label
//                                 htmlFor="model"
//                                 className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
//                               >
//                                 Assign 3D Model ID for Highlighting.
//                               </label>
//                             </div>
//                             {ischecked && (
//                               <div className="form-group">
//                                 <TextInput
//                                   key="unityModelId"
//                                   id="unityModelId"
//                                   name="unityModelId"
//                                   label="Model ID"
//                                   placeholder="Model ID"
//                                   type="text"
//                                 />
//                               </div>
//                             )}
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "row-reverse",
//                               }}
//                             >
//                               <TextInput
//                                 label=""
//                                 name="create"
//                                 id="create"
//                                 key="create"
//                                 value={editMode ? "Update" : "Create"}
//                                 type="submit"
//                                 className="btn btn-fill btn-primary"
//                                 disabled={
//                                   formik.isSubmitting || !formik.isValid
//                                 }
//                               />
//                               {editMode && (
//                                 <TextInput
//                                   label=""
//                                   name="delete"
//                                   id="delete"
//                                   key="delete"
//                                   value="Delete"
//                                   type="button"
//                                   onClick={deleteHandler}
//                                   className="btn btn-fill btn-primary"
//                                 />
//                               )}
//                               {formik.isSubmitting && <Loader />}
//                             </div>
//                           </Form>
//                         </div>
//                       )}
//                     </Formik>
//                     {loader && <Loader />}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default React.memo(Bubble);
