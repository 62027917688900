export const colorCodes: any = {
  white: "#555555",
  red: "#f95460",
  orange: "#ffb554",
  redShadow: "#f95460",
  green: "#4799E9",
  greenShadow: "#4799E9",
};

export const drawSplits = (
  chart: any,
  args: any,
  options: any,
  values: any,
  isScaled: any
) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.strokeStyle = "#fff";

  // drawing lines - starts
  ctx.moveTo(cx - 68, Iy - 56);
  ctx.lineTo(cx - 52, Iy - 43);
  ctx.stroke();

  ctx.moveTo(cx - 38, Iy - 80);
  ctx.lineTo(cx - 30, Iy - 62);
  ctx.stroke();

  ctx.moveTo(cx, Iy - 88);
  ctx.lineTo(cx, Iy - 68);
  ctx.stroke();

  ctx.moveTo(cx + 68, Iy - 56);
  ctx.lineTo(cx + 52, Iy - 43);
  ctx.stroke();

  ctx.moveTo(cx + 38, Iy - 80);
  ctx.lineTo(cx + 30, Iy - 62);
  ctx.stroke();
  // drawing lines - ends

  ctx.restore();

  ctx.font = isScaled ? "normal 12px Cera-Pro" : "normal 9px Cera-Pro";
  ctx.fillStyle = "#fff";

  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  let sectionValue = Math.round(values.total / 6);

  if (sectionValue < 10) {
    sectionValue = Number(
      (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
    );
  }

  // if (temp +)
  // in between values
  ctx.fillText(sectionValue + temp, cx - 45, Iy - 35);
  ctx.fillText(sectionValue * 2 + temp, cx - 20, Iy - 52);
  ctx.fillText(sectionValue * 4 + temp, cx + 35, Iy - 52);
  ctx.fillText(sectionValue * 5 + temp, cx + 57, Iy - 35);

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 45, cy - 10);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 40, cy - 10);

  ctx.restore();
};

export const drawHiddenSplits = (
  chart: any,
  args: any,
  options: any,
  values: any
) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.strokeStyle = "rgba(0, 0, 0, 0)";

  // drawing lines - starts
  ctx.moveTo(cx - 68, Iy - 56);
  ctx.lineTo(cx - 52, Iy - 43);
  ctx.stroke();

  ctx.moveTo(cx - 38, Iy - 80);
  ctx.lineTo(cx - 30, Iy - 62);
  ctx.stroke();

  ctx.moveTo(cx, Iy - 88);
  ctx.lineTo(cx, Iy - 68);
  ctx.stroke();

  ctx.moveTo(cx + 68, Iy - 56);
  ctx.lineTo(cx + 52, Iy - 43);
  ctx.stroke();

  ctx.moveTo(cx + 38, Iy - 80);
  ctx.lineTo(cx + 30, Iy - 62);
  ctx.stroke();
  // drawing lines - ends

  ctx.restore();

  ctx.font = "normal 9px Cera-Pro";
  ctx.fillStyle = "rgba(0, 0, 0, 0)";

  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  let sectionValue = Math.round(values.total / 6);

  if (sectionValue < 10) {
    sectionValue = Number(
      (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
    );
  }

  // if (temp +)
  // in between values
  ctx.fillText(sectionValue + temp, cx - 45, Iy - 35);
  ctx.fillText(sectionValue * 2 + temp, cx - 20, Iy - 52);
  ctx.fillText(sectionValue * 4 + temp, cx + 35, Iy - 52);
  ctx.fillText(sectionValue * 5 + temp, cx + 57, Iy - 35);

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 45, cy - 10);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 40, cy - 10);

  ctx.restore();
};

export const calculateData = (operatingRange: any, noOfParts: any) => {
  const { minValue, maxValue, value, minRange, maxRange } = operatingRange;

  const operatingRangeArr = [minValue, minRange, maxRange, maxValue];

  const tempAccumulator: any = {
    data: [],
    bg: [],
  };

  const accumulatorDataSet: number[] = [];
  const tempDS = operatingRangeArr.reduce(
    (accumulator, currentValue, currentIndex) => {
      let temp = 0;
      if (currentIndex != operatingRangeArr.length - 1) {
        const nextValue = operatingRangeArr[++currentIndex];
        if (currentValue != null && nextValue != null) {
          temp = Math.abs(nextValue - currentValue);
          accumulator.push(temp);
        }
      }

      return accumulator;
    },
    accumulatorDataSet
  );

  const totalValue = tempDS.reduce(
    (accumulator: any, data: any) => accumulator + data,
    0
  );

  /* operating range chart - starts */
  // const totalParts = 100;
  const totalParts = noOfParts;
  const eachPart = totalValue / totalParts;

  const finalDataSet = tempDS.reduce(
    (accumulator: any, currentValue: any, currentIndex: any) => {
      const tempPart = Math.floor((currentValue / totalValue) * totalParts);
      for (let i = 1; i <= tempPart; i = i + 2) {
        if (currentIndex === 0 || currentIndex === 2) {
          accumulator.bg.push(colorCodes.red);
        } else {
          accumulator.bg.push(colorCodes.green);
        }

        accumulator.bg.push("transparent");

        accumulator.data.push(eachPart);
        accumulator.data.push(eachPart);
      }
      return accumulator;
    },
    tempAccumulator
  );

  /* operating range chart - ends */

  const actualValueDataSet = {
    data: [0],
    bg: [""],
    shadowBg: [""],
  };
  let zone = "green";

  /* actual value indicator - starts */
  let defaultDataSet = 1;
  if (value != null) {
    if (
      minRange != null &&
      maxRange != null &&
      minValue != null &&
      maxValue != null
    ) {
      let occupyFull = 0;

      // define zone
      if (value >= minRange && value <= maxRange) {
        defaultDataSet = 0;
      } else if (value >= minValue && value <= maxValue) {
        defaultDataSet = 0;
        zone = "red";
      } else if (value > maxValue) {
        occupyFull = 1;
        zone = "red";
      }

      // define zone values
      if (!defaultDataSet) {
        let zoneValue = value;
        if (minValue != null && value != null) {
          if (minValue < 0) {
            if (value === 0) {
              zoneValue = Math.abs(minValue);
            } else if (value > 0) {
              zoneValue = Math.abs(value - minValue);
            } else {
              zoneValue = Math.abs(value - minValue);
            }
          } else if (minValue > 0) {
            if (value === 0) {
              zoneValue = minValue - value;
            } else if (value > 0) {
              zoneValue = value - minValue;
            }
          }
        }
        actualValueDataSet.bg = [colorCodes[`${zone}`], colorCodes.white];
        actualValueDataSet.shadowBg = [
          colorCodes[`${zone}Shadow`],
          "transparent",
        ];
        actualValueDataSet.data = [zoneValue, totalValue - zoneValue];
      }

      if (occupyFull) {
        defaultDataSet = 0;
        actualValueDataSet.bg = [colorCodes[`${zone}`]];
        actualValueDataSet.shadowBg = [colorCodes[`${zone}Shadow`]];
        actualValueDataSet.data = [totalValue];
      }
    }
  }
  /* actual value indicator - ends */

  let disable = false;
  // disable the chart for null values
  if (operatingRangeArr.includes(null) || value == null) {
    disable = true;
  }

  return {
    totalValue,
    rangeSet: finalDataSet,
    valueSet: actualValueDataSet,
    disable: disable,
    defaultDataSet,
    inRange: zone === "green" ? true : false,
  };
};

export const drawLargeSplits = (
  chart: any,
  args: any,
  options: any,
  values: any
) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.strokeStyle = "#fff";

  // // drawing lines - starts
  // ctx.moveTo(cx - 98, Iy - 86);
  // ctx.lineTo(cx - 82, Iy - 73);
  // ctx.stroke();

  // ctx.moveTo(cx - 68, Iy - 110);
  // ctx.lineTo(cx - 60, Iy - 92);
  // ctx.stroke();

  // ctx.moveTo(cx, Iy - 128);
  // ctx.lineTo(cx, Iy - 108);
  // ctx.stroke();

  // ctx.moveTo(cx + 98, Iy - 86);
  // ctx.lineTo(cx + 82, Iy - 73);
  // ctx.stroke();

  // ctx.moveTo(cx + 78, Iy - 110);
  // ctx.lineTo(cx + 70, Iy - 92);
  // ctx.stroke();
  // // drawing lines - ends

  ctx.restore();

  ctx.font = "normal 12px Cera-Pro";
  ctx.fillStyle = "#fff";

  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  // let sectionValue = Math.round(values.total / 6);

  // if (sectionValue < 10) {
  //   sectionValue = Number(
  //     (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
  //   );
  // }

  const sectionValue = createEqualSections(values.minValue, values.maxValue, 5);

  // if (temp +)
  // in between values
  ctx.fillText(sectionValue[0], cx - 60, Iy - 60);
  ctx.fillText(sectionValue[1], cx - 20, Iy - 90);
  ctx.fillText(sectionValue[2], cx + 40, Iy - 90);
  ctx.fillText(sectionValue[3], cx + 80, Iy - 60);

  // drawing lines - starts
  ctx.moveTo(cx - 1 / 6, Iy - 1 / 6);
  ctx.lineTo(cx - 1 / 6, Iy - 1 / 6);
  ctx.stroke();

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 45, cy - 10);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 40, cy - 10);

  ctx.restore();
};

export const drawLargestSplits = (
  chart: any,
  args: any,
  options: any,
  values: any
) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.strokeStyle = "#fff";

  // // drawing lines - starts
  // ctx.moveTo(cx - 98, Iy - 86);
  // ctx.lineTo(cx - 82, Iy - 73);
  // ctx.stroke();

  // ctx.moveTo(cx - 68, Iy - 110);
  // ctx.lineTo(cx - 60, Iy - 92);
  // ctx.stroke();

  // ctx.moveTo(cx, Iy - 128);
  // ctx.lineTo(cx, Iy - 108);
  // ctx.stroke();

  // ctx.moveTo(cx + 98, Iy - 86);
  // ctx.lineTo(cx + 82, Iy - 73);
  // ctx.stroke();

  // ctx.moveTo(cx + 78, Iy - 110);
  // ctx.lineTo(cx + 70, Iy - 92);
  // ctx.stroke();
  // // drawing lines - ends

  ctx.restore();

  ctx.font = "normal 12px Cera-Pro";
  ctx.fillStyle = "#fff";

  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  // let sectionValue = Math.round(values.total / 6);

  // if (sectionValue < 10) {
  //   sectionValue = Number(
  //     (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
  //   );
  // }

  const sectionValue = createEqualSections(values.minValue, values.maxValue, 5);

  // if (temp +)
  // in between values
  ctx.fillText(sectionValue[0], cx - 85, Iy - 80);
  ctx.fillText(sectionValue[1], cx - 40, Iy - 110);
  ctx.fillText(sectionValue[2], cx + 50, Iy - 110);
  ctx.fillText(sectionValue[3], cx + 100, Iy - 80);

  // drawing lines - starts
  ctx.moveTo(cx - 1 / 6, Iy - 1 / 6);
  ctx.lineTo(cx - 1 / 6, Iy - 1 / 6);
  ctx.stroke();

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 110, cy - 40);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 110, cy - 40);

  ctx.restore();
};

export const drawLargestSplitsHidden = (
  chart: any,
  args: any,
  options: any,
  values: any
) => {
  const {
    ctx,
    data,
    chartArea: { width, height, left, right },
  } = chart;
  ctx.save();

  const cx = width / 2;
  const cy = chart._metasets[0].data[0].y;

  const Iy = chart._metasets[0].data[0].y;

  // stroke line definition
  ctx.beginPath();
  ctx.lineWidth = 0.5;
  ctx.strokeStyle = "rgba(0, 0, 0, 0)";

  // // drawing lines - starts
  // ctx.moveTo(cx - 98, Iy - 86);
  // ctx.lineTo(cx - 82, Iy - 73);
  // ctx.stroke();

  // ctx.moveTo(cx - 68, Iy - 110);
  // ctx.lineTo(cx - 60, Iy - 92);
  // ctx.stroke();

  // ctx.moveTo(cx, Iy - 128);
  // ctx.lineTo(cx, Iy - 108);
  // ctx.stroke();

  // ctx.moveTo(cx + 98, Iy - 86);
  // ctx.lineTo(cx + 82, Iy - 73);
  // ctx.stroke();

  // ctx.moveTo(cx + 78, Iy - 110);
  // ctx.lineTo(cx + 70, Iy - 92);
  // ctx.stroke();
  // // drawing lines - ends

  ctx.restore();

  ctx.font = "normal 12px Cera-Pro";
  ctx.fillStyle = "rgba(0, 0, 0, 0)";

  let temp = 0;
  if (values.minValue != 0) {
    temp = values.minValue;
  }

  // let sectionValue = Math.round(values.total / 6);

  // if (sectionValue < 10) {
  //   sectionValue = Number(
  //     (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
  //   );
  // }

  const sectionValue = createEqualSections(values.minValue, values.maxValue, 5);

  // if (temp +)
  // in between values
  ctx.fillText(sectionValue[0], cx - 85, Iy - 80);
  ctx.fillText(sectionValue[1], cx - 40, Iy - 110);
  ctx.fillText(sectionValue[2], cx + 50, Iy - 110);
  ctx.fillText(sectionValue[3], cx + 100, Iy - 80);

  // drawing lines - starts
  ctx.moveTo(cx - 1 / 6, Iy - 1 / 6);
  ctx.lineTo(cx - 1 / 6, Iy - 1 / 6);
  ctx.stroke();

  // minValue
  ctx.textAlign = "left";
  ctx.fillText(values.minValue, left + 45, cy - 10);

  // maxValue
  ctx.textAlign = "right";
  ctx.fillText(values.maxValue, right - 40, cy - 10);

  ctx.restore();
};

// export const drawLargeSplits = (
//   chart: any,
//   args: any,
//   options: any,
//   values: any
// ) => {
//   const {
//     ctx,
//     data,
//     chartArea: { width, height, left, right },
//   } = chart;
//   ctx.save();

//   const cx = width / 2;
//   const cy = chart._metasets[0].data[0].y;

//   const Iy = chart._metasets[0].data[0].y;

//   // stroke line definition
//   ctx.beginPath();
//   ctx.lineWidth = 1;
//   ctx.strokeStyle = "#fff";

//   // ctx.fillText(sectionValue[0], cx - 60, Iy - 60);
//   // ctx.fillText(sectionValue[1], cx - 20, Iy - 90);
//   // ctx.fillText(sectionValue[2], cx + 40, Iy - 90);
//   // ctx.fillText(sectionValue[3], cx + 80, Iy - 60);

//   // drawing lines - starts
//   // ctx.moveTo(cx - 98, Iy - 86);
//   // ctx.lineTo(cx - 82, Iy - 73);
//   // ctx.stroke();

//   // ctx.moveTo(cx - 68, Iy - 110);
//   // ctx.lineTo(cx - 60, Iy - 92);
//   // ctx.stroke();

//   // ctx.moveTo(cx, Iy - 128);
//   // ctx.lineTo(cx, Iy - 108);
//   // ctx.stroke();

//   // // ctx.moveTo(cx + 98, Iy - 86);
//   // // ctx.lineTo(cx + 82, Iy - 73);
//   // // ctx.stroke();

//   // ctx.moveTo(cx + 80, Iy - 80);
//   // ctx.lineTo(cx + 130, Iy - 120);
//   // ctx.stroke();
//   // drawing lines - ends

//   ctx.restore();

//   ctx.font = "normal 14px Cera-Pro";
//   ctx.fillStyle = "#fff";

//   let temp = 0;
//   if (values.minValue != 0) {
//     temp = values.minValue;
//   }

//   // let sectionValue = Math.round(values.total / 6);

//   // if (sectionValue < 10) {
//   //   sectionValue = Number(
//   //     (Math.floor((values.total / 6) * 10) / 10).toFixed(1)
//   //   );
//   // }

//   const sectionValue = createEqualSections(
//     values.minValue,
//     values.maxValue,
//     values.maxValue % 2 === 0 ? 5 : 6
//   );

//   if (values.maxValue % 2 === 0) {
//     ctx.fillText(sectionValue[0], cx - 60, Iy - 60);
//     ctx.fillText(sectionValue[1], cx - 20, Iy - 90);
//     ctx.fillText(sectionValue[2], cx + 40, Iy - 90);
//     ctx.fillText(sectionValue[3], cx + 80, Iy - 60);
//   } else {
//     ctx.fillText(sectionValue[0], cx - 60, Iy - 60);
//     ctx.fillText(sectionValue[1], cx - 20, Iy - 90);
//     ctx.fillText(sectionValue[3], cx + 40, Iy - 90);
//     ctx.fillText(sectionValue[4], cx + 80, Iy - 60);
//   }
//   // if (temp +)
//   // in between values

//   // minValue
//   ctx.textAlign = "left";
//   ctx.fillText(values.minValue, left + 45, cy - 10);

//   // maxValue
//   ctx.textAlign = "right";
//   ctx.fillText(values.maxValue, right - 40, cy - 10);

//   ctx.restore();
// };

export function createEqualSections(
  start: number,
  end: number,
  numSections: number
) {
  if (start >= end || numSections <= 0) {
    throw new Error(
      "Invalid input: Start must be less than end, and numSections must be greater than 0."
    );
  }

  const sectionSize = (end - start) / numSections;
  const result = [];

  for (let i = 0; i < numSections - 1; i++) {
    const sectionStart = start + i * sectionSize;
    const sectionEnd = sectionStart + sectionSize;
    result.push(Math.ceil(sectionEnd));
  }

  // Adjust the last section's end to match the exact 'end' value to avoid floating-point precision issues.
  // result[result.length - 1].end = end;

  return result;
}
