import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import useApp from "hooks/useApp";
import { BsCheck, BsPlusCircle } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function Filters({
  equipmentsOptions,
  equipmentsFilters,
  setEquipmentsFilters,
  unitsOptions,
  unitsFilters,
  setUnitsFilters,
}: any) {
  const { isScaled } = useApp();
  return (
    <div className="flex items-center gap-5">
      {/* units filter */}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className={classNames(
              unitsFilters?.length > 0 ? "pr-0" : "pr-2",
              isScaled ? "h-10" : "h-8",
              "border-dashed pl-2 bg-dark border-slate"
            )}
          >
            <BsPlusCircle className="mr-2 h-4 w-4" />
            <span className={classNames(isScaled ? "text-xl" : "text-base")}>
              Units
            </span>

            {unitsFilters?.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <span
                  className="rounded-sm px-1 font-normal cursor-pointer hover:text-rose-400 duration-300"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setUnitsFilters([]);
                  }}
                >
                  <FiX size={20} />
                </span>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[200px] p-0 bg-dark border-slate"
          align="start"
        >
          <Command className="bg-dark border-slate">
            <CommandInput placeholder="Units" />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {unitsOptions.map((option: any) => {
                  const isSelected = unitsFilters?.includes(option?.value);
                  return (
                    <CommandItem
                      className={classNames(
                        isScaled ? "text-lg" : "text-base",
                        "cursor-pointer"
                      )}
                      key={option?.value}
                      onSelect={() => {
                        if (!unitsFilters?.includes(option?.value)) {
                          setUnitsFilters([...unitsFilters, option?.value]);
                        } else {
                          setUnitsFilters(
                            unitsFilters.filter(
                              (item: any) => item !== option?.value
                            )
                          );
                        }
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected
                            ? "bg-primary text-primary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <BsCheck className={cn("h-4 w-4")} />
                      </div>
                      {option?.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                      )}
                      <span>{option?.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {/* equipments filter */}
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            size="sm"
            className={classNames(
              equipmentsFilters?.length > 0 ? "pr-0" : "pr-2",
              isScaled ? "h-10" : "h-8",
              "border-dashed pl-2 bg-dark border-slate"
            )}
          >
            <BsPlusCircle className="mr-2 h-4 w-4" />
            <span className={classNames(isScaled ? "text-xl" : "text-base")}>
              Equipments
            </span>
            {equipmentsFilters?.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <span
                  className="rounded-sm px-1 font-normal cursor-pointer hover:text-rose-400 duration-300"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    setEquipmentsFilters([]);
                  }}
                >
                  <FiX size={20} />
                </span>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[200px] p-0 bg-dark border-slate"
          align="start"
        >
          <Command className="bg-dark border-slate">
            <CommandInput placeholder="Units" />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {equipmentsOptions.map((option: any) => {
                  const isSelected = equipmentsFilters?.includes(option?.value);
                  return (
                    <CommandItem
                      className={classNames(
                        isScaled ? "text-lg" : "text-base",
                        "cursor-pointer"
                      )}
                      key={option?.value}
                      onSelect={() => {
                        if (!equipmentsFilters?.includes(option?.value)) {
                          setEquipmentsFilters([
                            ...equipmentsFilters,
                            option?.value,
                          ]);
                        } else {
                          setEquipmentsFilters(
                            equipmentsFilters.filter(
                              (item: any) => item !== option?.value
                            )
                          );
                        }
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected
                            ? "bg-primary text-primary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <BsCheck className={cn("h-4 w-4")} />
                      </div>
                      {option?.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                      )}
                      <span>{option?.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
