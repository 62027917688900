import { downloadTag, uploadTag, clearTag } from "api/tags";
import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";
import useSignalR from "hooks/useSignalR";
import { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import { v4 as uuidv4 } from "uuid";

const Status: any = {
  1: "Start",
  2: "Progress",
  3: "Completed",
  4: "Canceled",
  5: "Failed",
};

const defaultSignalRValue = {
  currentMessage: "",
  currentPercentage: 0,
  currentCount: 0,
  totalCount: 0,
  currentStage: 0,
  totalStage: 0,
  type: "",
  status: "",
};

export default function EditTag({ selectedTag, setSelectedTag, editTag }: any) {
  const { isScaled } = useApp();
  const [screen, setScreen] = useState("edit");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [newTag, setNewTag] = useState<any>({
    id: "",
    equipmentId: null,
    name: "",
    tagNameIdentifier: "",
    desc: "",
    uom: "",
    minValue: "",
    maxValue: "",
    minRange: "",
    maxRange: "",
  });

  const { isLoggedIn } = useApp();
  const { FileUploadHandler } = useSignalR();
  const [fileLoading, setFileLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState<any>([]);
  const [abortController, setAbortController] = useState<any>(null);
  const [fileDetails, setFileDetails] = useState({
    fileName: "",
    errors: "",
    value: "",
  });
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [uuid, setId] = useState<string>();
  const [progressPopup, setProgressPopup] = useState(false);
  const [signalRDetails, setSignalRDetails] = useState(defaultSignalRValue);

  useEffect(() => {
    if (selectedTag.action === "edit") {
      setNewTag({
        id: selectedTag.value.id,
        equipmentId: null,
        name: selectedTag.value?.name,
        tagNameIdentifier: selectedTag.value?.tagNameIdentifier,
        desc: selectedTag.value?.desc || "",
        uom: selectedTag.value?.uom,
        minValue: selectedTag.value?.minValue,
        maxValue: selectedTag.value?.maxValue,
        minRange: selectedTag.value?.minRange,
        maxRange: selectedTag.value?.maxRange,
      });
    }
  }, [selectedTag]);

  const handleChange = (e: { target: { id: any; value: any } }) => {
    if (
      e.target.id === "minValue" ||
      e.target.id === "maxValue" ||
      e.target.id === "minRange" ||
      e.target.id === "maxRange"
    ) {
      const value = parseFloat(e.target.value);
      setNewTag({ ...newTag, [e.target.id]: value });
    } else {
      setNewTag({ ...newTag, [e.target.id]: e.target.value });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const result = await editTag(newTag);
    if (result.message === "success") {
      return setSelectedTag({ action: "", value: null });
    } else {
      setErrors(result.value);
    }
    setLoading(false);
  };

  const handleFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded && !fileDetails.fileName) {
      setFileDetails({ fileName: "", errors: "required", value: "" });
    } else if (fileUploaded) {
      if (!["text/csv", ".csv"].includes(fileUploaded.type)) {
        setFileDetails({ fileName: "", errors: "fileType", value: "" });
        setMessage(["Please upload only csv file."]);
        setError(true);
      } else {
        setFileDetails({
          fileName: fileUploaded.name,
          errors: "",
          value: event.target.files[0],
        });
        setMessage([]);
        setError(false);
      }
    }
  };

  const handleFileSubmit = async (event: any) => {
    event.preventDefault();
    setFileLoading(true);
    const formData = new FormData();
    const _id = uuidv4();
    formData.append("File", fileDetails.value);
    formData.append("Id", _id);
    const controller = new AbortController();
    setAbortController(controller);
    setProgressPopup(true);
    setId(_id);
    const response: any = await uploadTag(formData, controller.signal);
    if (response.status === 200) {
      setFileLoading(false);
      setMessage(["Uploaded Successfully."]);
      setAbortController(null);
      return setProgressPopup(false);
    }
    if (response.status === 400) {
      if (response.data.Validations) {
        setFileErrors(response.data.Validations);
      } else {
        setFileErrors([response.data.title]);
      }
    }
    setFileLoading(false);
  };

  const updateSignalRDetails = useCallback(
    (data: any) => {
      if (data.id === uuid) {
        setSignalRDetails(data);
      }
    },
    [uuid]
  );

  useEffect(() => {
    if (isLoggedIn === "loggedIn") {
      FileUploadHandler(updateSignalRDetails);
    }
  }, [isLoggedIn, updateSignalRDetails, FileUploadHandler]);

  const closeHandler = useCallback(() => {
    const text =
      "Closing the window will abort the file upload. Are you sure you want to close ?";
    if (window.confirm(text) === true) {
      setProgressPopup(false);
      abortController?.abort();
      setFileLoading(false);
      setId("");
      setSignalRDetails(defaultSignalRValue);
    }
  }, [abortController]);

  const resetValues = () => {
    setNewTag({
      ...newTag,
      minValue: "",
      maxValue: "",
      minRange: "",
      maxRange: "",
    });
  };

  const downloadFile = async () => {
    const response = await downloadTag(selectedTag.value.id);
    if (response.status === 200) {
      const result = response.data;
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${selectedTag.value.tagNameIdentifier}.csv`; // whatever your file name .
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  const clearFile = async () => {
    await clearTag(selectedTag.value.id);
  };

  return (
    <>
      {selectedTag.action === "edit" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          {!loading && (
            <>
              <div className="absolute inset-0 w-full h-full bg-black/50 z-20" />
              <div
                className={classNames(
                  isScaled ? "mt-48" : "",
                  "w-full gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[800px]"
                )}
              >
                <div className="bg-dark rounded-md p-3">
                  <div className="flex flex-col gap-5 relative">
                    {/* Header */}
                    <div className="flex justify-between items-center  p-3">
                      <h1 className="text-xl font-bold">Edit Tag</h1>
                      <span
                        className="cursor-pointer bg-green p-1 rounded-md"
                        onClick={() =>
                          setSelectedTag({ action: "", value: null })
                        }
                      >
                        <FiX size={20} />
                      </span>
                    </div>

                    <div className="h-[650px] overflow-hidden overflow-y-scroll p-3">
                      {errors?.length > 0 && (
                        <div>
                          {errors.map((error: string) => (
                            <p key={error} className="text-rose-400">
                              {error}
                            </p>
                          ))}
                        </div>
                      )}
                      <form className="grid gap-4 py-4">
                        {/* PI Name */}
                        <div className="flex flex-col gap-2">
                          <label htmlFor="name">Name</label>
                          <input
                            id="name"
                            type="text"
                            placeholder="PI Name"
                            className="col-span-3 input"
                            required
                            value={newTag.name}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Tag Name */}
                        <div className="flex flex-col gap-2">
                          <label htmlFor="tagNameIdentifier">Tag Name</label>
                          <input
                            id="tagNameIdentifier"
                            type="text"
                            placeholder="Tag Name"
                            className="col-span-3 input"
                            required
                            value={newTag.tagNameIdentifier}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Tag Description */}
                        <div className="flex flex-col gap-2">
                          <label htmlFor="description">Tag Description</label>
                          <input
                            id="desc"
                            type="text"
                            placeholder="Description"
                            className="col-span-3 input"
                            required
                            value={newTag.desc}
                            onChange={handleChange}
                          />
                        </div>
                        {/* Engineering Unit */}
                        <div className="flex flex-col gap-2">
                          <label htmlFor="uom">Engineering Unit</label>
                          <input
                            id="uom"
                            type="text"
                            placeholder="Engineering Unit"
                            className="col-span-3 input"
                            required
                            value={newTag.uom}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="flex gap-5">
                          <div className="flex-1">
                            {/* Min Max Values */}
                            <div className="flex gap-3 items-center">
                              <div className="flex flex-col gap-2 w-full">
                                <label htmlFor="minValue">Min Value</label>
                                <input
                                  id="minValue"
                                  type="number"
                                  placeholder="Min Value"
                                  className="col-span-3 input"
                                  required
                                  value={newTag.minValue}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex flex-col gap-2 w-full">
                                <label htmlFor="maxValue">Max Value</label>
                                <input
                                  id="maxValue"
                                  type="number"
                                  placeholder="Max Value"
                                  className="col-span-3 input"
                                  required
                                  value={newTag.maxValue}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            {/* Min Max Range */}
                            <div className="flex gap-3 items-center">
                              <div className="flex flex-col gap-2 w-full">
                                <label htmlFor="minRange">Min Range</label>
                                <input
                                  id="minRange"
                                  type="number"
                                  placeholder="Min Range"
                                  className="col-span-3 input"
                                  required
                                  value={newTag.minRange}
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="flex flex-col gap-2 w-full">
                                <label htmlFor="maxRange">Max Range</label>
                                <input
                                  id="maxRange"
                                  type="number"
                                  placeholder="Max Range"
                                  className="col-span-3 input"
                                  required
                                  value={newTag.maxRange}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center mt-10">
                            <div className="btn" onClick={resetValues}>
                              Reset
                            </div>
                          </div>
                        </div>
                      </form>
                      <>
                        {!fileLoading && (
                          <div className="flex flex-col gap-5 ">
                            {fileErrors?.length > 0 && (
                              <div>
                                {fileErrors.map((error: string) => (
                                  <p key={error} className="text-rose-400">
                                    {error}
                                  </p>
                                ))}
                              </div>
                            )}
                            <form
                              onSubmit={handleFileSubmit}
                              className="grid gap-4 py-4"
                            >
                              {/* PI Name */}
                              <div className="gradient p-0.5 rounded-md relative">
                                <div className="absolute py-1 px-2 bg-dark -top-4 left-2">
                                  File Upload
                                </div>
                                <div className="flex flex-col gap-2 bg-dark p-3 pt-5 rounded-md">
                                  <input
                                    name="tag_csv"
                                    onChange={handleFileSubmit}
                                    accept={"text/csv,.csv"}
                                    type="file"
                                    placeholder="PI Name"
                                    className="col-span-3 input"
                                    required
                                  />
                                  <div className="mt-2">
                                    <span
                                      className="btn"
                                      onClick={handleFileSubmit}
                                    >
                                      Upload
                                    </span>
                                  </div>
                                  <div className="mt-3 file-upload">
                                    <div className="my-1">
                                      <label>Supports: </label>
                                      <div className="inline-block ml-1">
                                        <span className="text-green">.csv</span>{" "}
                                        files only
                                      </div>
                                    </div>
                                    <div className="my-1">
                                      <label className="my-1">TagName:</label>
                                      <span className="ml-1">PITagName</span>
                                      <label className="ml-2">
                                        ExpressionName:
                                      </label>
                                      <span className="ml-2">
                                        ExprName
                                        <span className="text-green">@</span>
                                        EquipName or ExprName
                                        <span className="text-green">@</span>
                                        UnitName or ExprName
                                        <span className="text-green">@</span>
                                        TrainName or ExprName
                                        <span className="text-green">@</span>
                                        PlantName
                                      </span>
                                    </div>
                                    <div></div>
                                    <div>
                                      <label>Sample File Format: </label>
                                      Date (mm/dd/yyyy hh:mm) , TagName1 or
                                      ExpressionName1 , TagName2 or
                                      ExpressionName2 ,....
                                    </div>
                                  </div>
                                  <h1 className="mt-3 font-bold">
                                    Sample File
                                  </h1>
                                  <pre className="bg-white text-dark p-3 rounded-md text-sm">
                                    Date, 141-FI-320.PV, 500-FI-611. PV,
                                    500-FI-611A. PV <br />
                                    1/1/2023 0:00,
                                    157.9499355,834.0295041,788.3192724
                                    <br />
                                    1/3/2023
                                    0:00,158.1374796,773.6364545,733.5062088
                                    <br />
                                    1/4/2023
                                    0:00,157.9176099,850.7646894,801.0766662
                                  </pre>
                                </div>
                              </div>

                              <div className="mt-3 flex justify-between">
                                <div className="flex items-center gap-3">
                                  <div className="btn" onClick={downloadFile}>
                                    Download Data
                                  </div>
                                  <div className="btn" onClick={clearFile}>
                                    Clean Data
                                  </div>
                                </div>
                                <div className="btn" onClick={handleSubmit}>
                                  Update
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                        {progressPopup && (
                          <>
                            <div className="absolute z-40 inset-0 w-full h-full bg-black/70" />
                            <div className="w-full h-auto bg-black p-3 rounded-md z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[600px]">
                              <div className="flex justify-between items-center">
                                <h1>File Uploading...</h1>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => closeHandler()}
                                >
                                  <FiX size={20} />
                                </span>
                              </div>
                              <p>
                                {Status[signalRDetails.status]} -
                                {signalRDetails.currentPercentage} %
                              </p>
                              <div className="py-[15px] px-[30px]">
                                <div className="Progressbar">
                                  <div
                                    className="Progressbar__value"
                                    style={{
                                      width: `${signalRDetails.currentPercentage}%`,
                                    }}
                                  ></div>
                                  <progress
                                    value={signalRDetails.currentPercentage}
                                    max="100"
                                  >
                                    100%
                                  </progress>
                                </div>
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <p>
                                  {" "}
                                  <span>
                                    {signalRDetails.currentMessage}{" "}
                                  </span>{" "}
                                  <span>{signalRDetails.currentCount} </span> /{" "}
                                  <span id="TotalCount">
                                    {signalRDetails.totalCount}
                                  </span>{" "}
                                </p>
                                <label id="CurrentStage">
                                  {signalRDetails.currentStage}
                                </label>
                                /
                                <label id="TotalStage">
                                  {signalRDetails.totalStage}
                                </label>
                              </div>
                              <div className="flex justify-end">
                                <button
                                  onClick={() => abortController?.abort()}
                                  className="btn-sec"
                                  type="button"
                                >
                                  Cancel Upload
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {loading && (
            <div className="w-full h-full grid place-content-center relative z-30">
              <Loader />
            </div>
          )}
        </div>
      )}
    </>
  );
}
