import { Input } from "@/components/ui/input";
import classNames from "utilities/ClassNames";

export default function Toolbar({
  table,
  searchColumn,
  searchPlaceholder,
  filters,
  rightMenuAction,
  isScaled,
}: any) {
  return (
    <div className="flex items-center justify-between pb-4">
      {/* filters */}
      <div className="flex items-center gap-5 w-full">
        <div>
          <Input
            placeholder={searchPlaceholder}
            value={
              (table.getColumn(searchColumn)?.getFilterValue() as string) ?? ""
            }
            onChange={(event) =>
              table.getColumn(searchColumn)?.setFilterValue(event.target.value)
            }
            className={classNames(isScaled ? "text-lg" : "", "max-w-xs input")}
          />
        </div>
        {filters && <>{filters}</>}
      </div>
      {rightMenuAction && <>{rightMenuAction}</>}
    </div>
  );
}
