import { getExpressionPastData } from "api/dashboard";
import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import Loader from "components/shared/Loader";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { MdOutlineZoomInMap } from "react-icons/md";
import { PiFileCsv, PiFilePdf, PiFilePng } from "react-icons/pi";
import { TbZoomOutArea } from "react-icons/tb";
import classNames from "utilities/ClassNames";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

export default function BigGraph({ setBigGraph, data, unityHandler }: any) {
  const chartRef = useRef<any>(null);
  const [isDaily, setIsDaily] = useState(true);
  const [loading, setLoading] = useState(true);
  const [expressionWithData, setExpressionWithData] = useState<any>(null);
  const [valueSet, setValueSet] = useState<number[]>();
  const [dataList, setDataList] = useState<any>();
  const [minValue, setMinValue] = useState<any>();
  const [maxValue, setMaxValue] = useState<any>();

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response: any = await getExpressionPastData(
        data.id,
        365,
        isDaily ? "D" : "H"
      );
      if (response?.data && response?.status === 200) {
        const dataList = response?.data.map((expression: any) => ({
          x: new Date(expression.createdOn).getTime(),
          y: expression.expressionValue,
        }));
        const valuesSet = response?.data.map(
          (expression: any) => expression.expressionValue
        );
        setDataList(dataList);
        setValueSet(valuesSet);
      }
      const dataResponse: any = await getExpressionData([data.id]);
      if (dataResponse.status === 200) {
        setExpressionWithData(dataResponse.data[0]);
      }
      setLoading(false);
    })();
  }, [isDaily]);

  useEffect(() => {
    const minValue = valueSet?.reduce(
      (a: any, b: any) => Math.min(a, b),
      Infinity
    );
    const maxValue = valueSet?.reduce(
      (a: any, b: any) => Math.max(a, b),
      -Infinity
    );
    setMinValue(minValue);
    setMaxValue(maxValue);
  }, [valueSet]);

  const options: any = {
    animation: {
      duration: 0,
    },
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: isDaily ? "month" : "day",
        },
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: 15,
          },
        },
      },
      y: {
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: 15,
          },
        },
        min: minValue - 5,
        max: maxValue + 5,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        axis: "x",
        intersect: false,
        filter: function (tooltipItem: any) {
          return tooltipItem.datasetIndex === 0;
        },
      },
      legend: {
        display: false,
      },
      interaction: {
        intersect: true,
      },
      zoom: {
        animation: {
          duration: 1000,
          easing: "easeOutCubic",
        },
        enabled: true,
        mode: "xy",
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgba(62, 252, 173, 0.2)",
            borderColor: "rgb(19, 146, 93)",
            borderWidth: 1,
          },
          wheel: {
            enabled: true,
            modifierKey: "ctrl",
          },
        },
        limits: {
          x: {
            min: data.minRange,
          },
        },
      },
    },
  };

  let minSetData = null;
  let maxSetData = null;

  if (minValue < data.minRange) {
    minSetData = dataList?.map((set: any) => ({
      x: set.x,
      y: data.minRange,
    }));
  }

  if (maxValue > data.maxRange) {
    maxSetData = dataList?.map((set: any) => ({
      x: set.x,
      y: data.maxRange,
    }));
  }

  let minSet = null;
  let maxSet = null;

  if (minSetData) {
    minSet = {
      fill: "start",
      label: "minSet",
      data: minSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  if (maxSetData) {
    maxSet = {
      fill: "end",
      label: "maxSet",
      data: maxSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  const defaultDataSet = {
    fill: true,
    label: "Tag Value",
    data: dataList,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
    lineTension: 0.3,
    pointRadius: 0,
    pointHoverRadius: 0,
    order: 1,
  };

  const datasetsArray = [];
  datasetsArray.push(defaultDataSet);

  if (minSet) {
    datasetsArray.push(minSet);
  }

  if (maxSet) {
    datasetsArray.push(maxSet);
  }

  const chartData = {
    datasets: datasetsArray,
  };

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const zoomIn = () => {
    chartRef.current.zoom(1.1);
  };

  const zoomOut = () => {
    chartRef.current.zoom(0.9);
  };

  const downloadPng = () => {
    const link = document.createElement("a");
    link.download = data.name;
    link.href = chartRef.current.toBase64Image();
    link.click();
  };

  const downloadPdf = () => {
    const image = chartRef?.current?.canvas.toDataURL("image/jpg", 1.0);
    const pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    pdf.addImage(image, "JPEG", 15, 15, 260, 150);
    pdf.save(data.name);
  };

  const downloadCsv = () => {
    const csvData = [
      ["Date", "Expression Value"],
      ...dataList.map((item: any) => [
        dayjs(item.x).format("YYYY-MM-DDTHH:mm:ss"),
        item.y,
      ]),
    ]
      .map((e: any) => e.join(","))
      .join("\n");

    const link = document.createElement("a");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    link.href = URL.createObjectURL(blob);
    link.download = data.name;
    link.click();
  };

  const bgColor = {
    id: `areaChart_${data.id}`,
    beforeDraw: (chart: any, option: any) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.fillStyle = "#111111";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  const bigChartHandler = () => {
    if (unityHandler) {
      unityHandler();
    } else {
      setBigGraph(false);
    }
  };

  if (loading && !unityHandler)
    return (
      <div className="w-full h-full grid place-content-center min-h-[200px]">
        <Loader />
      </div>
    );

  return (
    <div className="absolute inset-0 bg-black/70">
      <div className="gradient p-0.5 w-full min-w-[1400px] h-full min-h-[830px] -mt-[350px] -ml-[400px] rounded-md">
        <div className="bg-dark w-full h-full rounded-md p-3">
          <div className="flex justify-between ">
            <div></div>
            <div
              onClick={bigChartHandler}
              className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
            >
              <MdOutlineZoomInMap size={18} />
            </div>
          </div>
          <div className="flex gap-3 w-full mt-3 border-2 border-green p-3 rounded-md">
            <div className="w-full">
              <Line
                ref={chartRef}
                plugins={[bgColor]}
                options={options}
                data={chartData}
              />
            </div>
            <div className=" flex flex-col gap-3 items-center mt-7">
              <div
                className="cursor-pointer hover:text-green duration-300"
                onClick={zoomIn}
              >
                <FiZoomIn size={20} />
              </div>
              <div
                className="cursor-pointer hover:text-green duration-300"
                onClick={zoomOut}
              >
                <FiZoomOut size={20} />
              </div>
              <div
                className="cursor-pointer hover:text-green duration-300"
                onClick={resetZoom}
              >
                <TbZoomOutArea size={20} />
              </div>
              <div
                className="cursor-pointer hover:text-green duration-300 mt-3"
                onClick={downloadCsv}
              >
                <PiFileCsv size={20} />
              </div>
              <div
                className="cursor-pointer hover:text-green duration-300"
                onClick={downloadPdf}
              >
                <PiFilePdf size={20} />
              </div>
              <div
                className="cursor-pointer hover:text-green duration-300"
                onClick={downloadPng}
              >
                <PiFilePng size={20} />
              </div>
              <div
                className={classNames(
                  isDaily ? "font-bold text-green" : "text-white",
                  "cursor-pointer duration-300 mt-3 leading-3"
                )}
                onClick={() => setIsDaily(true)}
              >
                D
              </div>
              <div
                className={classNames(
                  !isDaily ? "font-bold text-green" : "text-white",
                  "cursor-pointer duration-300 leading-3"
                )}
                onClick={() => setIsDaily(false)}
              >
                H
              </div>
            </div>
          </div>
          <div className="pt-1">
            <div className="flex items-center">
              <p>Tag Name:</p>
              <div className="flex-1" />
              <p>{data.name}</p>
            </div>

            <div className="flex items-center">
              <p>Description:</p>
              <div className="flex-1" />
              <p>{data.desc}</p>
            </div>

            <div className="flex items-center">
              <p>Engineering Unit:</p>
              <div className="flex-1" />
              <p>{data.uom}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
