/* eslint-disable @typescript-eslint/no-unused-expressions */
import api from "utilities/Axios";

export const getTrains = () => {
  return api.get(`/TagMeta/GetTrainsByPlantId?id=${1}`);
};

export const createTrain = (train: any) => {
  return api.post("/TagMeta/CreateTrain", train);
};

export const updateTrain = (train: any) => {
  return api.post("/TagMeta/UpdateTrain", train);
};

export const deleteTrain = (id: any) => {
  return api.get(`/TagMeta/DeleteTrain?id=${id}`);
};
