import useData from "hooks/useData";
import { useContext, useEffect, useState } from "react";
import { FiSettings, FiX } from "react-icons/fi";
import WebGLContext from "./WebGlContext";
import useApp from "hooks/useApp";
import TagsBigGraph from "components/charts/unity/bigChart/tags";
import ExpressionBigGraph from "components/charts/unity/bigChart/expressions";
import UnityGauge from "components/charts/UnityGauge";
import classNames from "utilities/ClassNames";
import { RxIdCard } from "react-icons/rx";
import { MdOutlineDoDisturbOn, MdQueryStats } from "react-icons/md";
import { BiAlarm, BiExpand } from "react-icons/bi";
import { usePins } from "hooks/usePins";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { createTicket } from "api/tickets";
import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import Chart from "./Chart";

const defaultDataDetails = {
  name: null,
  nameIdentifier: null,
  uom: null,
  id: null,
  days: 0,
  type: "",
  tagNameIdentifier: "",
};

const Charts = ({ setModalOpen }: any) => {
  const { isScaled } = useApp();
  const {
    chartData,
    handleModalLoader,
    handleCloseModal,
    modalOpen,
    bubbleDetails,
    popupBubbleId,
    configureBubbleHandler,
    refreshUnityData,
  } = useContext(WebGLContext);
  const [dataCollection, setData] = useState<any>([]);
  const [containerClassName, setClassName] = useState<string>("");
  const [initialClassName, setInitialClassName] = useState<string>("");
  const { pastDate: selectedPastDate } = useData();

  const [showStatus, setStatus] = useState<boolean>(false);

  const [showCollection, enableCollection] = useState<boolean>(true);
  const [isGraphPopup, setGraphPopup] = useState<boolean>(false);
  const [dataDetails, setDataDetails] = useState<any>(defaultDataDetails);

  const [screen, setScreen] = useState("normal");
  const [selected, setSelected] = useState<any>(null);

  const type = bubbleDetails?.type ? bubbleDetails.type : "";

  const [Editable, setEditable] = useState(false);
  const { role } = useApp();

  const [newTicket, setNewTicket] = useState<any>({
    comments: "",
    description: "",
    priority: null,
    severity: null,
    tagId: null,
    title: "",
  });

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setNewTicket({ ...newTicket, [e.target.id]: e.target.value });
  };

  const handleNewTicket = async (event: any) => {
    event.preventDefault();
    const response: any = await createTicket({
      comments: newTicket.comments,
      description: newTicket.description,
      priority: parseInt(newTicket.priority),
      severity: parseInt(newTicket.severity),
      tagId: selected.id,
      title: newTicket.title,
    });
    if (response.status === 200) {
      setScreen("normal");
    }
    setNewTicket({
      comments: "",
      description: "",
      priority: null,
      severity: null,
      tagId: null,
      title: "",
    });
  };

  useEffect(() => {
    if (role?.toLowerCase() == "admin" || role?.toLowerCase() == "manager") {
      setEditable(true);
    }
  }, [role]);

  useEffect(() => {
    if (!showCollection && initialClassName !== "gauge-chart-6-tags") {
      setClassName("gauge-chart-6-tags");
    } else {
      setClassName(initialClassName);
    }
  }, [showCollection, initialClassName]);

  useEffect(() => {
    const controller = new AbortController();
    let isApiSubscribed = true;
    if (isApiSubscribed) {
      handleModalLoader();
      if (bubbleDetails?.type === "tag" && !selectedPastDate) setStatus(true);
    }
    return () => {
      isApiSubscribed = false;
      controller.abort();
    };
  }, []);

  //   useEffect(() => {
  //     setIsAlertTagStatus(data.isAlertTag);
  //     setIsDisabledStatus(data.isDisabled);
  //   }, [data]);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    }
  }, [chartData]);

  const ticketCreation = !showCollection && !isGraphPopup;
  const configureBubble = () => {
    if (popupBubbleId) {
      configureBubbleHandler(popupBubbleId);
      setModalOpen(false);
    }
  };

  return (
    <>
      {screen === "ticket" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div className="absolute z-20 inset-0 w-full h-full bg-black/70" />

          <div className="w-full h-auto gradient p-0.5 rounded-md z-30 mt-7 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[850px]">
            <div className="bg-dark pl-3 pt-3 pb-3 w-full rounded-md h-full max-h-[840px] overflow-hidden overflow-y-scroll">
              <div className="flex flex-col py-3">
                <h1>Add trouble ticket</h1>
                <form className="mt-3" onSubmit={handleNewTicket}>
                  <div className="py-1">
                    <label htmlFor="title">Issue Title</label>
                    <input
                      className="input"
                      onChange={handleChange}
                      type="text"
                      id="title"
                      value={newTicket.title}
                    />
                  </div>

                  <div className="py-1">
                    <label htmlFor="description">Issue description</label>
                    <textarea
                      className="input"
                      onChange={handleChange}
                      id="description"
                      value={newTicket.description}
                    />
                  </div>

                  <div className="py-1">
                    <label htmlFor="comments">Resolution</label>
                    <textarea
                      className="input"
                      onChange={handleChange}
                      id="comments"
                      value={newTicket.comments}
                    />
                  </div>

                  <div className="py-2 flex justify-between gap-5">
                    <div className="flex flex-col gap-1 w-full">
                      <label>Severity</label>
                      <Select
                        value={newTicket.severity}
                        onValueChange={(value: any) =>
                          setNewTicket({ ...newTicket, severity: value })
                        }
                      >
                        <SelectTrigger className="w-full border-slate">
                          <SelectValue placeholder="severity" />
                        </SelectTrigger>
                        <SelectContent className="bg-dark border-slate">
                          <SelectItem value="1">1</SelectItem>
                          <SelectItem value="2">2</SelectItem>
                          <SelectItem value="3">3</SelectItem>
                          <SelectItem value="4">4</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label>Priority</label>
                      <Select
                        value={newTicket.priority}
                        onValueChange={(value: any) =>
                          setNewTicket({ ...newTicket, priority: value })
                        }
                      >
                        <SelectTrigger className="w-full border-slate">
                          <SelectValue placeholder="Priority" />
                        </SelectTrigger>
                        <SelectContent className="bg-dark border-slate">
                          <SelectItem value="3">Height</SelectItem>
                          <SelectItem value="2">Medium</SelectItem>
                          <SelectItem value="1">Low</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  {/* Ticket Dialog footer */}
                  <div className="flex justify-end gap-5 mt-2">
                    <button
                      className="cursor-pointer btn-sec"
                      onClick={() => setScreen("normal")}
                    >
                      Cancel
                    </button>
                    <button className="cursor-pointer btn" type="submit">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {!chartData && <div>Loading</div>}
      {screen === "normal" && chartData && dataCollection.length && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div className="absolute z-20 inset-0 w-full h-full bg-black/70" />

          <div
            className={classNames(
              isScaled ? "mt-48" : "mt-7",
              "w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[850px]"
            )}
          >
            <div className="bg-dark pl-3 pt-3 pb-3 w-full rounded-md h-full max-h-[840px] overflow-hidden overflow-y-scroll">
              {/* Header */}
              <div className="flex justify-between">
                <div className="flex gap-5 items-center">
                  <h1 className="text-2xl font-bold text-green">
                    {bubbleDetails?.name}
                  </h1>
                  {popupBubbleId && Editable && (
                    <div
                      className="cursor-pointer text-green mt-1"
                      onClick={configureBubble}
                    >
                      <FiSettings size={20} />
                    </div>
                  )}
                </div>
                <div
                  className="bg-green py-1 px-1.5 rounded-md cursor-pointer grid place-content-center"
                  onClick={handleCloseModal}
                >
                  <FiX size={20} />
                </div>
              </div>
              <div>
                <div className="mt-5">
                  {showCollection && (
                    <div className="grid grid-cols-2 gap-3">
                      {dataCollection.map((data: any, index: any) => (
                        <Chart
                          data={data}
                          key={index}
                          screen={screen}
                          setScreen={setScreen}
                          setSelected={setSelected}
                          refreshUnityData={refreshUnityData}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {screen === "big" && (
        <div className="absolute z-20 inset-0 w-full h-full bg-black/70">
          <div className="fixed top-[23.5rem] left-[40rem] z-30">
            {selected.tagNameIdentifier ? (
              <TagsBigGraph
                data={selected}
                unityHandler={() => setScreen("normal")}
              />
            ) : (
              <ExpressionBigGraph
                data={selected}
                unityHandler={() => setScreen("normal")}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Charts;
