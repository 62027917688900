import useApp from "hooks/useApp";
import { useEffect } from "react";
import classNames from "utilities/ClassNames";

export default function NewTab({
  setTabError,
  newTab,
  addNewTab,
  setNewTab,
  widget,
  tabError,
  setScreen,
}: any) {
  const { isScaled } = useApp();
  const initialTab = {
    name: "",
    type: "",
    configurations: null,
    configured: false,
  };

  const handleNewTab = (event: any) => {
    event.preventDefault();
    if (newTab.name === "" || newTab.type === "") {
      setTabError("All fields are required");
    } else {
      addNewTab(widget.id);
      setScreen("");
      setNewTab(initialTab);
      setTabError("");
    }
  };

  useEffect(() => {
    if (widget.position === 5) {
      setNewTab((prev: any) => ({ ...prev, type: "large" }));
    }
  }, [widget]);

  const tabTypes = [
    {
      name: "4 Numbers & unit",
      value: "kpi",
      action: () => setNewTab((prev: any) => ({ ...prev, type: "kpi" })),
    },
    {
      name: "1 Graph & bar",
      value: "area",
      action: () => setNewTab((prev: any) => ({ ...prev, type: "area" })),
    },
    {
      name: "4 Gauges",
      value: "gauge",
      action: () => setNewTab((prev: any) => ({ ...prev, type: "gauge" })),
    },
    {
      name: "4 Gauge/Graph & bar",
      value: "gaugeBar",
      action: () => setNewTab((prev: any) => ({ ...prev, type: "gaugeBar" })),
    },
    {
      name: "1 Gauge/Graph",
      value: "oneGaugeBar",
      action: () =>
        setNewTab((prev: any) => ({ ...prev, type: "oneGaugeBar" })),
    },
  ];

  return (
    <div className="h-full w-full flex items-center p-3">
      <form className="w-full flex flex-col gap-3" onSubmit={handleNewTab}>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="name"
            className={classNames(isScaled ? "text-2xl" : "")}
          >
            Tab Name
          </label>
          <input
            className={classNames(isScaled ? "text-lg" : "", "input w-1/2")}
            name="name"
            id="name"
            placeholder="Name"
            type="text"
            required
            value={newTab.name}
            onChange={(e: any) =>
              setNewTab((prev: any) => ({ ...prev, name: e.target.value }))
            }
          />
          <p
            className={classNames(
              isScaled ? "text-lg" : "text-xs ",
              "text-stone-400"
            )}
          >
            Name longer than 10 characters will be reformated
          </p>
        </div>

        {widget.position !== 5 && (
          <div className="flex justify-between gap-1 w-full mt-5">
            {tabTypes.map((type: any, index: number) => (
              <div
                key={`${type.name}-${index}`}
                onClick={type.action}
                className={classNames(
                  newTab.type === type.value ? "gradient" : "text-white",
                  "bg-stone-700 flex justify-center items-center p-1 rounded-md w-full text-sm cursor-pointer hover:bg-slate font-bold duration-300 border-slate border-2"
                )}
              >
                <p
                  className={classNames(
                    isScaled ? "text-lg" : "",
                    "text-center"
                  )}
                >
                  {type.name}
                </p>
              </div>
            ))}
          </div>
        )}

        <div className="flex gap-3 mt-5">
          <div className="text-red">{tabError}</div>
          <div className="flex-1" />
          <div className="flex items-center gap-3">
            <div
              onClick={() => {
                setScreen("");
                setNewTab(initialTab);
                setTabError("");
              }}
              className="btn-sec"
            >
              Cancel
            </div>
            <button type="submit" className="btn">
              Add
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
