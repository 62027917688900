import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { motion } from "framer-motion";
import useApp from "hooks/useApp";
import { useEffect, useState } from "react";
import calculateData from "utilities/calculateData";
import classNames from "utilities/ClassNames";
import TextOverflow from "utilities/TextOverflow";

export const colorCodes: any = {
  white: "#555555",
  red: "#f95460",
  orange: "#ffb554",
  redShadow: "#f95460",
  green: "#4799E9",
  greenShadow: "#4799E9",
};

const theme = {
  fontFamily: "Cera-Pro",
  primary: "#65F4CD",
  text_grey: "white",
  "teal-color": "#00999f",
  secondary: "#4799E9",
  lightBlue: "#3f5173",
  red: "#f95460",
  orange: "#ffb554",
  danger: "#dc3545",
  grey: "#acb2c0",
  bodyBg: "#232323",
  bodyColor: "#434343",
  themeGradient:
    "linear-gradient(180deg, rgba(90, 85, 202, 0.6) 0%, rgba(90, 85, 202, 0.9) 22.58%, #5a55ca 100%)",
};

export default function Bar({ rawData, type, barChartName }: any) {
  const { isScaled } = useApp();
  const [operatingDataSet, setOperatingDataSet] = useState<any>([]);
  const [operatingBG, setOperatingBG] = useState<any>([]);
  const [total, setTotal] = useState<any>(0);
  const [inRange, setInRange] = useState<any>(0);

  const [scales, setScales] = useState<any>([]);
  const [scalesPoints, setScalesPoints] = useState<any>([]);

  const [data, setData] = useState<any>(null);
  const [barLength, setBarLength] = useState<any>(null);

  useEffect(() => {
    (async () => {
      if (rawData) {
        if (type === "tag") {
          const response: any = await getTagData([rawData.id]);
          if (response?.data && response?.status === 200) {
            setData(response.data[0]);
          }
        } else {
          const response: any = await getExpressionData([rawData.id]);
          if (response?.data && response?.status === 200) {
            setData(response.data[0]);
          }
        }
      }
    })();
  }, [rawData]);

  useEffect(() => {
    if (data) {
      const calculatedData = calculateData(data, 150);
      setTotal(calculatedData.totalValue);
      setOperatingDataSet(calculatedData.rangeSet.data);
      setOperatingBG(calculatedData.rangeSet.bg);
      setInRange(calculatedData.inRange);

      setBarLength(
        Math.trunc((100 * data.value) / data.maxValue)
          .toString()
          .trim() + "%"
      );

      let temp = 0;
      if (data.minValue !== 0 && data.minValue != null) {
        temp = data.minValue;
      }

      let sectionValue = Math.round(total / 5);

      if (sectionValue < 5) {
        sectionValue = 0;
      }
    }
  }, [data]);

  const calculatePercentage = (value: any, total: any) => {
    return (
      Math.trunc((100 * parseInt(value)) / parseInt(total))
        .toString()
        .trim() + "%"
    );
  };

  useEffect(() => {
    if (data) {
      const scalesData: any = [];
      const _scalesPoints: any = [];
      const _scales: any = [];
      const rangeValues = [data.minRange, data.maxRange];
      if (operatingBG.length) {
        for (let index = 0; index < operatingBG.length; index++) {
          if (
            operatingBG[index] !== operatingBG[index + 2] &&
            operatingBG[index] !== "transparent"
          ) {
            scalesData.push({ value: index, color: operatingBG[index] });
          }
        }
      }

      let temp = 0;
      if (data.minValue !== 0 && data.minValue != null) {
        temp = data.minValue;
      }

      if (scalesData.length) {
        for (let index = 0; index < scalesData.length; index++) {
          _scales.push({
            value:
              scalesData[index]?.value - (scalesData[index - 1]?.value || 0),
            color: scalesData[index]?.color,
            point: rangeValues[index],
          });
        }
      }

      if (scalesData.length === 2) {
        _scalesPoints.push(data.maxRange);
        _scalesPoints.push(total);
      } else if (scalesData.length === 3) {
        _scalesPoints.push(data.minRange);
        _scalesPoints.push(data.maxRange);
      }

      setScalesPoints(_scalesPoints);
      setScales(_scales);
    }
  }, [operatingBG]);

  return (
    <div className="h-16 relative w-full text-sm">
      <div className="flex justify-between">
        <h1
          className={classNames(
            isScaled ? "text-xl" : "text-base",
            "font-bold text-green whitespace-nowrap"
          )}
        >
          {TextOverflow(barChartName, 20)}
        </h1>
        <div
          className={classNames(
            isScaled ? "text-xl" : "text-base",
            "text-stone-100 flex gap-1 items-center"
          )}
        >
          {data?.value ? data?.value?.toFixed(2) : "N/A"}
          <span
            className={classNames(
              isScaled ? "text-[15px] " : "text-[10px]",
              "whitespace-nowrap"
            )}
          >
            {data?.uom}
          </span>
        </div>
      </div>

      <div className="rounded-xl p-0.5 bg-gradient-to-r from-[#3D5E7C] to-[#3D5E7C] mt-1">
        {barLength && (
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: barLength }}
            transition={{ duration: 1 }}
            className={classNames(
              inRange
                ? "from-[#64F1CE] to-[#489BE7]"
                : "from-[#FEA855] to-[#FA5C5F]",
              "bg-gradient-to-r rounded-xl h-2 relative"
            )}
          />
        )}
      </div>

      {operatingBG && operatingBG.length && (
        <div className="flex justify-evenly mt-1">
          {operatingBG.map((bit: any, index: number) => (
            <div
              key={`${index}-bit`}
              className={classNames(isScaled ? "h-1.5" : "h-1", "w-[1px]")}
              style={{ background: bit }}
            ></div>
          ))}
        </div>
      )}

      {scales && scales.length && scalesPoints && (
        <div className="gap-1 flex text-[11px]">
          <div className={classNames(isScaled ? "text-lg" : "text-[10px]")}>
            0
          </div>
          {/* <div
            className="flex"
            style={{ width: calculatePercentage(scales[0].line.value, 100) }}
          >
            {data.minRange}
          </div>
          <div
            className="flex"
            style={{ width: calculatePercentage(data.maxRange, data.maxRange) }}
          >
            {data.maxRange}
          </div> */}
          {scales.map((line: any, index: number) => (
            <div
              key={line.value}
              style={{
                width: calculatePercentage(line.value, 100),
                background: line.color,
              }}
              className="rounded-xl h-0 flex justify-center relative text-[10px]"
            >
              {index + 1 !== scales.length && (
                <div
                  className={classNames(
                    isScaled ? "text-lg" : "text-[10px]",
                    "absolute -right-5 mx-0.5"
                  )}
                >
                  {scalesPoints[index]}
                </div>
              )}
            </div>
          ))}
          <div className={classNames(isScaled ? "text-lg" : "text-[10px]")}>
            {total !== data.maxRange && total}
          </div>
        </div>
      )}
    </div>
  );
}
