/* eslint-disable @typescript-eslint/no-unused-expressions */
import api from "utilities/Axios";

export const getBubblesMeta = () => {
  return api.get("/UnityData/GetUnityBubbleMeta");
};

export const getBubblesData = () => {
  return api.get("/UnityData/GetUnityBubbleData");
  // return api.get("/Unity/GetUnityBubbleData?dateTime=2023-10-02T22%3A46%3A00.000");
};

export const updateBubblePosition = (data: any) => {
  return api.post("/Unity/UpdateBubbleCordinates", data);
};

export const getNotes = () => {
  return api.get("/UnityData/GetUnityNoteMeta");
};

export const getEquipment = (id: any) => {
  return api.get(`/UnityData/GetEquipment?id=${id}`);
};

export const getUnit = (id: any) => {
  return api.get(`/UnityData/GetUnit?id=${id}`);
};

export const getNote = (id: any) => {
  return api.get(`Unity/GetNote?id=${id}`);
};

export const getBubble = (id: any) => {
  return api.get(`Unity/GetBubble?id=${id}`);
};

export const downloadFile = (id: any) => {
  return api.get("/Unity/getFile" + `?id=${id}`);
};

// Notes

export const createNote = (data: any) => {
  return api.post("/Unity/CreateNote", data);
};

export const updateNote = (data: any) => {
  return api.post("/Unity/UpdateNote", data);
};

export const deleteNote = (id: any) => {
  return api.get("/Unity/DeleteNote" + `?id=${id}`);
};

// Bubbles

export const createBubble = (data: any) => {
  return api.post("/Unity/CreateBubble", data);
};

export const updateBubble = (data: any) => {
  return api.post("/Unity/UpdateBubble", data);
};

export const deleteBubble = (id: any) => {
  return api.get("/Unity/DeleteBubble" + `?id=${id}`);
};
