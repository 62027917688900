import { useState, useEffect } from "react";

import Menu from "components/widgets/Menu";
import {
  FiEdit,
  FiMoreVertical,
  FiPlusSquare,
  FiSettings,
  FiTrash2,
  FiX,
} from "react-icons/fi";
import classNames from "utilities/ClassNames";
import TextOverflow from "utilities/TextOverflow";
import Configuration from "./Configuration";
import TabBody from "./TabBody";
import NewTab from "./tabs/NewTab";
import UpdateTab from "./tabs/UpdateTab";
import useApp from "hooks/useApp";

export default function Tabs({
  currentTab,
  setCurrentTab,
  screen,
  setScreen,
  newTab,
  setNewTab,
  tabs,
  widget,
  widgets,
  addNewTab,
  removeTab,
  updateTab,
  updateWidgets,
  removeWidget,
}: any) {
  const { isScaled } = useApp();
  const [tabError, setTabError] = useState<string>("");

  // Menu
  const handleNewTab = () => {
    setNewTab({
      name: "",
      type: "",
      configurations: null,
      configured: false,
    });
    setTabError("");
    setScreen("newTab");
  };

  const handleEditTab = () => {
    setNewTab({
      name: tabs[currentTab].name,
      type: tabs[currentTab].type,
      configurations: tabs[currentTab].configuration,
    });
    setScreen("editTab");
  };

  const handleConfigureTab = () => {
    setScreen("configuration");
  };

  const handleDeleteTab = () => {
    setScreen("deleteTabConfirm");
  };

  const handleDeleteWidget = () => {
    setScreen("deleteWidgetConfirm");
  };

  const menu = [
    {
      text: "New Tab",
      icon: <FiPlusSquare className="mr-2 h-5 w-5" size={15} />,
      action: () => {
        setNewTab({
          name: "",
          type: "",
          configurations: null,
          configured: false,
        });
        setTabError("");
        setScreen("newTab");
      },
    },
    {
      text: "Edit Tab",
      icon: <FiEdit className="mr-2 h-5 w-5" size={15} />,
      action: () => {
        setNewTab({
          name: tabs[currentTab].name,
          type: tabs[currentTab].type,
          configurations: tabs[currentTab].configuration,
        });
        setScreen("editTab");
      },
    },
    {
      text: "Configure Tab",
      icon: <FiSettings className="mr-2 h-5 w-5" size={15} />,
      action: () => {
        setScreen("configuration");
      },
    },
    {
      text: "Delete Tab",
      icon: <FiTrash2 className="text-red mr-2 h-5 w-5" size={15} />,
      action: () => {
        setScreen("deleteTabConfirm");
      },
    },
  ];

  const menuButton = (
    <div className="cursor-pointer duration-300 hover:text-green">
      <FiMoreVertical size={isScaled ? 25 : 20} />
    </div>
  );

  if (screen === "newTab") {
    return (
      <NewTab
        setTabError={setTabError}
        newTab={newTab}
        addNewTab={addNewTab}
        setNewTab={setNewTab}
        widget={widget}
        tabError={tabError}
        setScreen={setScreen}
      />
    );
  }

  if (screen === "editTab") {
    return (
      <UpdateTab
        setTabError={setTabError}
        newTab={newTab}
        updateTab={updateTab}
        setNewTab={setNewTab}
        widget={widget}
        tabError={tabError}
        setScreen={setScreen}
        tabs={tabs}
        currentTab={currentTab}
      />
    );
  }

  if (screen === "deleteTabConfirm") {
    return (
      <div className="relative w-full h-full p-3">
        <div className="flex flex-col gap-4 items-center justify-center absolute inset-0 w-full h-full">
          <h1 className="text-4xl font-bold">Remove Tab</h1>
          <p className={classNames(isScaled ? "text-xl" : "")}>
            Are you sure you want to remove this tab
          </p>
          <div className="flex gap-3 items-center mt-5">
            <div className="btn-sec" onClick={() => setScreen("")}>
              Cancel
            </div>
            <div
              className="btn-danger"
              onClick={() => removeTab(widget.id, tabs[currentTab].id)}
            >
              Remove
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (screen === "deleteWidgetConfirm") {
    return (
      <div className="relative w-full h-full p-3">
        <div className="flex flex-col gap-4 items-center justify-center absolute inset-0 w-full h-full">
          <h1 className="text-4xl font-bold">Remove Widget</h1>
          <p className={classNames(isScaled ? "text-xl" : "")}>
            Are you sure you want to remove this widget
          </p>
          <div className="flex gap-3 items-center mt-5">
            <div className="btn-sec" onClick={() => setScreen("")}>
              Cancel
            </div>
            <div className="btn-danger" onClick={() => removeWidget(widget.id)}>
              Remove
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (screen === "configuration") {
    return (
      <Configuration
        widget={widget}
        screen={screen}
        setScreen={setScreen}
        tab={widget.tabs[currentTab]}
        tabs={widget.tabs}
        currentTab={currentTab}
        updateTab={updateTab}
        updateWidgets={updateWidgets}
      />
    );
  }

  if (widget.tabs.length < 1 && widget.tabs.length <= 4) {
    return (
      <div className="flex-1 grid place-content-center w-full h-full gap-3 relative z-10">
        <div
          className="text-center flex flex-col gap-3 items-center justify-center cursor-pointer duration-300"
          onClick={() => setScreen("newTab")}
        >
          <FiSettings size={50} />
          <div className={classNames(isScaled ? "text-2xl" : "")}>
            Start configuring Widget
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-full">
      {/* Header */}
      <div className="flex items-center relative bg-stone-950 pl-3 py-2 pr-1 rounded-tl-md rounded-tr-md text-white">
        <div className="flex gap-4 items-center">
          {tabs.map((tab: any, index: number) => (
            <div
              onClick={() => setCurrentTab(index)}
              key={`${tab.id}-${index}`}
              className={classNames(
                currentTab === index ? "text-green" : "",
                "text-sm cursor-pointer whitespace-nowrap duration-300 capitalize hover:text-green font-bold"
              )}
            >
              <span className={isScaled ? "text-xl" : ""}>
                {TextOverflow(tab.name, 20)}
              </span>
            </div>
          ))}
        </div>

        <div style={{ flexGrow: 1 }} />
        {screen !== "configuration" && (
          <>
            <div className="flex justify-end w-full relative">
              <Menu
                button={menuButton}
                menu={menu}
                tabsLength={tabs.length}
                newTab={handleNewTab}
                editTab={handleEditTab}
                configureTab={handleConfigureTab}
                deleteTab={handleDeleteTab}
              />
            </div>
          </>
        )}
      </div>
      {/* Body */}
      <TabBody
        screen={screen}
        tabs={widget.tabs}
        currentTab={currentTab}
        setScreen={setScreen}
      />
    </div>
  );
}
