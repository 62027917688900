import { getAllTags } from "api/tags";
import Loader from "components/shared/Loader";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import MapTable from "./MapTable";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function MapTag({
  mappedTags,
  setShowMapDialog,
  setRefresh,
  selectedEquipment,
  getTagsById,
}: any) {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [tab, setTab] = useState<any>("map");
  const [tags, setTags] = useState<any>(null);

  const getTags = async () => {
    const response: any = await getAllTags();
    if (response.status === 200) {
      setTags(response.data);
    } else {
      setError("Error loading tags, please try again");
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <div className="fixed bg-black/90 inset-0 grid place-content-center">
      {error && (
        <div className="w-full h-full grid place-content-center">{error}</div>
      )}
      {(!tags || loading) && (
        <div className="fixed z-30 inset-0 w-full h-full grid place-content-center bg-black/70">
          <Loader />
        </div>
      )}
      {tags && (
        <div
          className={classNames(
            isScaled ? "text-xl" : "",
            "gradient p-0.5 rounded-md"
          )}
        >
          <div
            className={classNames(
              isScaled ? "w-[1500px]" : "w-[1200px]",
              "bg-dark rounded-md p-3"
            )}
          >
            {/* header */}
            <div className="flex justify-between w-full pb-5 cursor-pointer">
              <h1>
                Map Tag to{" "}
                <span className="text-green font-bold">
                  {selectedEquipment.name}
                </span>
              </h1>
              <div onClick={() => setShowMapDialog(false)}>
                <FiX size={isScaled ? 25 : 20} />
              </div>
            </div>

            <div className="flex flex-col gap-5">
              {/* tab header */}
              {/* <div className="flex bg-black rounded-md p-1">
              <div
                className={classNames(
                  tab === "map" ? "bg-dark" : "bg-black",
                  "cursor-pointer flex-1 p-1 rounded-md text-center"
                )}
                onClick={() => setTab("map")}
              >
                Map Tag
              </div>
              <div
                className={classNames(
                  tab === "new" ? "bg-dark" : "bg-black",
                  "cursor-pointer flex-1 p-1 rounded-md text-center"
                )}
                onClick={() => setTab("new")}
              >
                New Tag
              </div>
            </div> */}
              {/* content */}
              <div
                className={classNames(
                  isScaled ? "min-h-[400px]" : "min-h-[300px]"
                )}
              >
                {tab === "map" && (
                  <MapTable
                    mappedTags={mappedTags}
                    data={tags}
                    setRefresh={setRefresh}
                    selectedEquipment={selectedEquipment}
                    setLoading={setLoading}
                    getTags={getTags}
                    setError={setError}
                    getTagsById={getTagsById}
                    setShowMapDialog={setShowMapDialog}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
