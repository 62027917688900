import Gauge from "components/charts/LargeGauge";
import LargestGauge from "components/charts/LargestGauge";

export default function GaugeBox({ data, name, largest }: any) {
  if (largest) {
    return <LargestGauge data={data} name={name} large />;
  } else {
    return <Gauge data={data} name={name} large />;
  }
}
