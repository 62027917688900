import { Label } from "@/components/ui/label";
import { validateExpression } from "api/expression";
import Loader from "components/shared/Loader";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import ExpressionCalculation from "./ExpressionCalculation";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function newExpression({
  selectedExpression,
  setSelectedExpression,
  createNewExpression,
}: any) {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>(null);
  const [message, setMessage] = useState<any>("");
  const [expressionMessage, setExpressionMessage] = useState<any>("");

  const [name, setName] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [assetType, setAssetType] = useState<any>(0);
  const [type, setType] = useState<any>(null);
  const [id, setId] = useState<any>(0);
  const [seqOrder, setSeqOrder] = useState<any>(0);
  const [minRange, setMinRange] = useState<any>(0);
  const [maxRange, setMaxRange] = useState<any>(0);
  const [expression, setExpression] = useState<any>("");
  const [expression1, setExpression1] = useState<any>("");
  const [expression2, setExpression2] = useState<any>("");
  const [expression3, setExpression3] = useState<any>("");
  const [equipmentId, setEquipmentId] = useState<any>(null);
  const [unitId, setUnitId] = useState<any>(null);
  const [trainId, setTrainId] = useState<any>(null);
  const [plantId, setPlantId] = useState<any>(1);
  const [uom, setUom] = useState<any>("");
  const [minValue, setMinValue] = useState<any>(0);
  const [maxValue, setMaxValue] = useState<any>(0);
  const [equipmentName, setEquipmentName] = useState<any>(null);
  const [unitName, setUnitName] = useState<any>(null);
  const [trainName, setTrainName] = useState<any>(null);
  const [plantName, setPlantName] = useState<any>(null);

  const [validationLoading, setValidationLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>([]);
  const [validationResult, setValidationResult] = useState(null);

  useEffect(() => {
    if (equipmentId === "") {
      setEquipmentId(null);
    }
  }, [equipmentId]);

  useEffect(() => {
    if (unitId === "") {
      setUnitId(null);
    }
  }, [unitId]);

  useEffect(() => {
    if (unitId === "") {
      setTrainId(null);
    }
  }, [trainId]);

  const handleValidation = async () => {
    setValidationErrors(null);
    setValidationLoading(true);
    setErrors([]);
    const response: any = await validateExpression({
      name,
      desc,
      assetType,
      type,
      id,
      seqOrder,
      minRange,
      maxRange,
      expression,
      expression1,
      expression2,
      expression3,
      equipmentId,
      unitId,
      trainId,
      plantId,
      uom,
      minValue,
      maxValue,
      equipmentName,
      unitName,
      trainName,
      plantName,
    });
    if (response.status === 200) {
      setMessage("Expression has been validated successfully.");
      setValidationResult(response.data);
      return setValidationLoading(false);
    }
    if (response.status === 400) {
      if (response.data.Validations) {
        setValidationErrors(response.data.Validations);
      } else {
        setValidationErrors([response.data.title]);
      }
      setValidationLoading(false);
    }
  };

  const handleSubmit = async (event: any) => {
    setErrors(null);
    event.preventDefault();
    setExpressionMessage(null);
    if (
      expression === "" &&
      expression1 === "" &&
      expression2 === "" &&
      expression3 === ""
    ) {
      return setExpressionMessage("Expression details is required");
    }
    setLoading(true);
    const result = await createNewExpression({
      name,
      desc,
      assetType: parseInt(assetType),
      type,
      id,
      seqOrder: parseInt(seqOrder),
      minRange,
      maxRange,
      expression,
      expression1,
      expression2,
      expression3,
      equipmentId,
      unitId,
      trainId,
      plantId,
      uom,
      minValue,
      maxValue,
      equipmentName,
      unitName,
      trainName,
      plantName,
    });
    if (result.message === "success") {
      return setSelectedExpression({ action: "", value: null });
    } else {
      setErrors(result.value);
    }
    setLoading(false);
  };

  return (
    <>
      {selectedExpression.action === "new" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div className="absolute z-20 inset-0 w-full h-full bg-black/80" />
          {loading && (
            <div className="w-full h-full grid place-content-center relative z-30">
              <Loader />
            </div>
          )}
          <div
            className={classNames(
              isScaled ? "h-[93%] mt-48" : "h-[68%]",
              "w-[1000px] gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            )}
          >
            <div className="flex flex-col w-full h-full overflow-hidden overflow-y-scroll gap-5 bg-dark rounded-md pl-3 pt-3 pb-3">
              {/* Header */}
              <div className="flex justify-between items-center">
                <h1 className="text-xl font-bold">New Expression</h1>
                <span
                  className="cursor-pointer bg-green p-1 rounded-md"
                  onClick={() =>
                    setSelectedExpression({ action: "", value: "" })
                  }
                >
                  <FiX size={20} />
                </span>
              </div>
              <div className="h-[600px] overflow-hidden overflow-y-scroll p-3">
                {errors && errors?.length > 0 && (
                  <div>
                    {errors.map((error: string) => (
                      <p key={error} className="text-red">
                        {error}
                      </p>
                    ))}
                  </div>
                )}
                <form onSubmit={handleSubmit} className="grid gap-4 py-4">
                  {/* Name */}
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="name">Expression Name</Label>
                    <input
                      id="name"
                      type="text"
                      placeholder="Expression Name"
                      className="col-span-3 input"
                      required
                      value={name}
                      onChange={(event: any) => setName(event.target.value)}
                    />
                  </div>
                  {/* Tag Description */}
                  <div className="flex flex-col gap-2">
                    <Label htmlFor="description">Description</Label>
                    <input
                      id="desc"
                      type="text"
                      placeholder="Description"
                      className="col-span-3 input"
                      required
                      value={desc}
                      onChange={(event: any) => setDesc(event.target.value)}
                    />
                  </div>
                  {/* Calculation Type */}
                  <ExpressionCalculation
                    loading={loading}
                    message={message}
                    errors={errors}
                    editMode={false}
                    handleValidation={handleValidation}
                    setExpression={setExpression}
                    expression={expression}
                    expression1={expression1}
                    setExpression1={setExpression1}
                    expression2={expression2}
                    setExpression2={setExpression2}
                    expression3={expression3}
                    setExpression3={setExpression3}
                    equipmentId={equipmentId}
                    setEquipmentId={setEquipmentId}
                    unitId={unitId}
                    setUnitId={setUnitId}
                    trainId={trainId}
                    setTrainId={setTrainId}
                    assetType={assetType}
                    setAssetType={setAssetType}
                    type={type}
                    setType={setType}
                    setPlantId={setPlantId}
                    expressionMessage={expressionMessage}
                    validationResult={validationResult}
                    validationLoading={validationLoading}
                    setValidationLoading={setValidationLoading}
                    setValidationResult={setValidationResult}
                    setMessage={setMessage}
                    validationErrors={validationErrors}
                  />
                  {/* Engineering Unit and Sequence Order */}
                  <div className="flex gap-3 items-center">
                    <div className="flex flex-col gap-2 w-full">
                      <Label htmlFor="uom">Engineering Unit</Label>
                      <input
                        id="uom"
                        type="text"
                        placeholder="Engineering Unit"
                        className="col-span-3 input"
                        required
                        value={uom}
                        onChange={(event: any) => setUom(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <Label htmlFor="seqOrder">Sequence Order</Label>
                      <input
                        id="seqOrder"
                        type="number"
                        placeholder="Sequence Order"
                        className="col-span-3 input"
                        required
                        value={seqOrder}
                        onChange={(event: any) =>
                          setSeqOrder(event.target.value)
                        }
                      />
                    </div>
                  </div>
                  {/* Min Max Values */}
                  <div className="flex gap-3 items-center">
                    <div className="flex flex-col gap-2 w-full">
                      <Label htmlFor="minValue">Min Value</Label>
                      <input
                        id="minValue"
                        type="number"
                        placeholder="Min Value"
                        className="col-span-3 input"
                        required
                        value={minValue}
                        onChange={(event: any) =>
                          setMinValue(parseFloat(event.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <Label htmlFor="maxValue">Max Value</Label>
                      <input
                        id="maxValue"
                        type="number"
                        placeholder="Max Value"
                        className="col-span-3 input"
                        required
                        value={maxValue}
                        onChange={(event: any) =>
                          setMaxValue(parseFloat(event.target.value))
                        }
                      />
                    </div>
                  </div>
                  {/* Min Max Range */}
                  <div className="flex gap-3 items-center">
                    <div className="flex flex-col gap-2 w-full">
                      <Label htmlFor="minRange">Min Range</Label>
                      <input
                        id="minRange"
                        type="number"
                        placeholder="Min Range"
                        className="col-span-3 input"
                        required
                        value={minRange}
                        onChange={(event: any) =>
                          setMinRange(parseFloat(event.target.value))
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                      <Label htmlFor="maxRange">Max Range</Label>
                      <input
                        id="maxRange"
                        type="number"
                        placeholder="Max Range"
                        className="col-span-3 input"
                        required
                        value={maxRange}
                        onChange={(event: any) =>
                          setMaxRange(parseFloat(event.target.value))
                        }
                      />
                    </div>
                  </div>

                  <div className="mt-3 flex justify-end">
                    <button className="btn" type="submit">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
