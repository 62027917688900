import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";
import { useState } from "react";
import classNames from "utilities/ClassNames";

export default function DeleteTag({
  selectedTag,
  setSelectedTag,
  deleteTagById,
}: any) {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(false);

  const handleDelete = async () => {
    setLoading(true);
    const result = await deleteTagById(selectedTag.value.id);
    if (result.message === "success") {
      return setSelectedTag({ action: "", value: null });
    } else {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <>
      {selectedTag.action === "delete" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div
            onClick={() => setSelectedTag({ action: "", value: null })}
            className={classNames(
              isScaled ? "h-[2000px]" : "h-full",
              "absolute z-20 inset-0 w-full bg-black/70"
            )}
          />
          {!loading && (
            <div className="w-full h-auto bg-dark p-3 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[300px]">
              <div className="flex flex-col gap-5">
                <div className="flex justify-center items-center flex-col gap-10 p-3 text-center">
                  <p className={classNames(isScaled ? "text-2xl" : "")}>
                    Are you sure you want to delete this tag
                  </p>
                  <div className="flex gap-5">
                    <button
                      className="btn-sec"
                      onClick={() =>
                        setSelectedTag({ action: "", value: null })
                      }
                    >
                      cancel
                    </button>
                    <button className="btn-danger" onClick={handleDelete}>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loading && (
            <div className="w-full h-full grid place-content-center relative z-30">
              <Loader />
            </div>
          )}
        </div>
      )}
    </>
  );
}
