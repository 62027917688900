import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { motion } from "framer-motion";
import useApp from "hooks/useApp";
import { useEffect, useState } from "react";
import { FiEdit2, FiUser } from "react-icons/fi";
import "react-phone-input-2/lib/style.css";
import Edit from "./edit";
import Passwords from "./passwords";

export default function Profile({ closeMenu, showProfileMenu }: any) {
  // const [user, setUser] = useState<any>(null);
  const { user, setUser } = useApp();
  const [screen, setScreen] = useState("default");
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);

  // const getUser = async () => {
  //   const response: any = await apiGetUser();
  //   if (response?.status === 200) {
  //     setUser(response.data);
  //   }
  // };

  // useEffect(() => {
  //   getUser();
  // }, []);

  const updateHandler = async (data: any) => {
    setLoading(true);
    // const response = await updateProfile(data);
    // if (response.status === 200) {
    //   getUser();
    // } else {
    //   setError("There was an issue updating profile please try later");
    // }
    setLoading(false);
  };

  const slide = {
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
      display: "flex",
    },
    exit: {
      opacity: 0,
      x: "100%",
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const appear = {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
        delay: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  return (
    <motion.div
      className="fixed z-50 inset-0 w-full h-full"
      initial="exit"
      animate={showProfileMenu ? "enter" : "exit"}
      variants={appear}
    >
      <motion.div
        initial="exit"
        animate={showProfileMenu ? "enter" : "exit"}
        variants={appear}
        className="fixed z-10 inset-0 w-full bg-black/90"
        onClick={closeMenu}
      />
      <motion.div
        initial="exit"
        animate={showProfileMenu ? "enter" : "exit"}
        variants={slide}
        className="absolute h-full right-0 top-0 bottom-0 w-[300px] z-20 bg-gray"
      >
        {screen === "edit" && (
          <Edit
            setScreen={setScreen}
            user={user}
            update={updateHandler}
            loading={loading}
            error={error}
          />
        )}
        {screen === "default" && user && (
          <>
            {user && (
              <div className="h-full w-full flex flex-col">
                {/* info */}
                <div className="gradient p-0.5 rounded-md m-3">
                  <div className="bg-gray rounded-md p-3 flex flex-col gap-7 pt-5">
                    {/* Avatar */}
                    <div className="flex justify-center items-center relative">
                      {user?.avatar && (
                        <Avatar className="w-40 h-40 rounded-full">
                          <AvatarImage
                            src={`data:image/jpeg;base64,${user?.avatar}`}
                            alt="Avatar"
                          />
                          <AvatarFallback></AvatarFallback>
                        </Avatar>
                      )}
                      {!user?.avatar && (
                        <div className="w-40 h-40 grid place-content-center bg-dark rounded-full">
                          <FiUser size={80} />
                        </div>
                      )}
                      <div
                        onClick={() => setScreen("edit")}
                        className="w-7 h-7 rounded-full bg-green/50 hover:bg-green duration-300 text-white absolute bottom-0 right-16 cursor-pointer flex items-center justify-center"
                      >
                        <FiEdit2 />
                      </div>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <p className="text-white/70 text-[12px]">First name</p>
                      <p className="capitalize">{user.firstName}</p>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <p className="text-white/70 text-[12px]">Last name</p>
                      <p className="capitalize">{user.lastName}</p>
                    </div>
                    <div className="flex flex-col gap-0.5">
                      <p className="text-white/70 text-[12px]">Email</p>
                      <p>{user.email}</p>
                    </div>
                    {/* {user.skypeId &&
                      user.skypeId !== undefined &&
                      user.skypeId !== "" && (
                        <div className="flex flex-col gap-0.5">
                          <p className="text-white/70 text-[12px]">Skype</p>
                          <p>{user.skypeId?user.skypeId:"N/A"}</p>
                        </div>
                      )} */}
                    {user.phoneNumber && (
                      <div className="flex flex-col gap-0.5">
                        <p className="text-white/70 text-[12px]">
                          Phone number
                        </p>
                        <p>{user.phoneNumber ? user.phoneNumber : "N/A"}</p>
                      </div>
                    )}
                    {/* <div className="flex flex-col gap-0.5">
                      <p className="text-white/70 text-[12px]">Time zone</p>
                      <p className="capitalize">{user.timeZone}</p>
                    </div> */}
                  </div>
                </div>
              </div>
              // <div className="mt-96 ml-5">{user.email}</div>
            )}
          </>
        )}
        {screen === "passwords" && <Passwords setScreen={setScreen} />}
      </motion.div>
    </motion.div>
  );
}
