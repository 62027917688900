import {
  deleteExpression as apiDeleteExpression,
  createExpression,
  getAllExpressions,
  updateExpression,
} from "api/expression";
import Loader from "components/shared/Loader";
import { useEffect, useMemo, useState } from "react";
import { filterArray } from "utilities/FilterArray";
import { parseFilters } from "utilities/ParseFilters";
import DeleteExpression from "./DeleteExpression";
import EditExpression from "./EditExpression";
import ExpressionChart from "./ExpressionChart";
import NewExpression from "./NewExpression";
import Table from "./table";
import Debounce from "utilities/Debounce";
import Filters from "./table/Filters";
import ActionMenu from "./table/ActionMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { LuFileEdit, LuTimerReset, LuTrash2 } from "react-icons/lu";
import classNames from "utilities/ClassNames";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import useApp from "hooks/useApp";

const RowAction = (props: any) => {
  const { isScaled } = useApp();
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <div
            className="cursor-pointer hover:text-green"
            onClick={props.action}
          >
            {props.icon}
          </div>
        </TooltipTrigger>
        <TooltipContent
          className={classNames(isScaled ? "-mr-9 text-lg" : "")}
          style={{ background: props.danger ? "#ef4444" : "#34d399" }}
        >
          <p className="text-white">{props.text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default function ExpressionContainer() {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);

  const [trainsFilters, setTrainsFilters] = useState<any>([]);
  const [trainsOptions, setTrainsOptions] = useState<any>();
  const [unitsFilters, setUnitsFilters] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>();
  const [expression, setExpression] = useState<any>({
    action: "",
    value: null,
  });

  // table
  const ITEMS_PER_PAGE = 50;
  const [totalPages, setTotalPages] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    setLoading(true);
    const response: any = await getAllExpressions();
    if (response.status === 200) {
      setRawData(response.data);
      //trains
      const trains = response.data.map((item: any) => item.trainName);
      setTrainsOptions(parseFilters(trains));
      //units
      const units = response.data.map((item: any) => item.unitName);
      setUnitsOptions(parseFilters(units));
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    const response: any = await getAllExpressions();
    if (response.status === 200) {
      setRawData(response.data);
    }
  };

  const createNewExpression = async (expression: any) => {
    const response: any = await createExpression(expression);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }

    if (response.status === 400) {
      if (response.data.Validations) {
        return { message: "failure", value: response.data.Validations };
      } else {
        return { message: "failure", value: [response.data.title] };
      }
    }
  };

  const editExpression = async (expression: any) => {
    const response: any = await updateExpression(expression);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }

    if (response.status === 400) {
      if (response.data.Validations) {
        return { message: "failure", value: response.data.Validations };
      } else {
        return { message: "failure", value: response.data.title };
      }
    }
  };

  const deleteExpression = async (id: any) => {
    const response: any = await apiDeleteExpression(id);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (rawData && rawData.length) {
      if (unitsFilters && unitsFilters?.length) {
        const result = filterArray(unitsFilters, rawData, "unitName");
        if (result.length > 0) setData(result);
      } else if (trainsFilters && trainsFilters?.length) {
        const result = filterArray(trainsFilters, rawData, "trainName");
        if (result.length > 0) setData(result);
      } else {
        setData(rawData);
      }
    }
  }, [rawData]);

  useEffect(() => {
    if (unitsFilters && unitsFilters?.length) {
      const result = filterArray(unitsFilters, rawData, "unitName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [unitsFilters]);

  useEffect(() => {
    if (trainsFilters && trainsFilters?.length) {
      const result = filterArray(trainsFilters, rawData, "trainName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [trainsFilters]);

  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }

    if (items) {
      setTotalPages(() => {
        const array = [];
        for (let i = 1; i <= Math.ceil(items.length / ITEMS_PER_PAGE); i++) {
          array.push(i);
        }
        return array;
      });

      return items?.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
  }, [data, debouncedKeyword, currentPage]);

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Expressions, try refreshing the page</p>
        </div>
      )}
      {!loading && !error && data && (
        <div
          className={classNames(
            isScaled ? "text-2xl" : "text-base",
            "pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll h-full"
          )}
        >
          <div className="flex w-full items-center justify-between rounded-md">
            <div className="flex gap-5">
              <div>
                <input
                  onChange={(e: any) => setKeyword(e.target.value)}
                  className="input"
                  type="text"
                  placeholder="Search"
                />
              </div>
              {/* Filters */}
              <Filters
                trainsOptions={trainsOptions}
                trainsFilters={trainsFilters}
                setTrainsFilters={setTrainsFilters}
                unitsOptions={unitsOptions}
                unitsFilters={unitsFilters}
                setUnitsFilters={setUnitsFilters}
              />
            </div>
            <div>
              <button
                className="btn"
                onClick={() => setExpression({ action: "new", value: null })}
              >
                Add
              </button>
            </div>
          </div>
          <div className="gradient p-0.5 rounded-md mt-3">
            <div
              className={classNames(
                isScaled ? "max-h-[820px]" : "max-h-[650px]",
                "p-3 rounded-md bg-black h-full overflow-scroll"
              )}
            >
              <table className="w-full border-collapse">
                <thead>
                  <tr className="text-green">
                    <th className="text-left">Name</th>
                    <th className="text-left">Plant</th>
                    <th className="text-left">Train</th>
                    <th className="text-left">Unit</th>
                    <th className="text-left">Equipment</th>
                    <th>Engineering Unit</th>
                    <th>Seq.No.</th>
                    <th>Min Value</th>
                    <th>Max Value</th>
                    <th>Min Range</th>
                    <th>Max Range</th>
                    <th className="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((expression: any) => (
                      <tr
                        key={expression.id}
                        className="border-b border-b-lightGray hover:bg-dark duration-300"
                      >
                        <td
                          onClick={() =>
                            setExpression({
                              action: "chart",
                              value: expression,
                            })
                          }
                          className="underline cursor-pointer hover:text-green"
                        >
                          {expression.name}
                        </td>
                        <td>{expression.plantName}</td>
                        <td>{expression.trainName}</td>
                        <td>{expression.unitName}</td>
                        <td>{expression.equipmentName}</td>
                        <td className="text-center">{expression.uom}</td>
                        <td className="text-center">{expression.seqOrder}</td>
                        <td className="text-center">{expression.minValue}</td>
                        <td className="text-center">{expression.maxValue}</td>
                        <td className="text-center">{expression.minRange}</td>
                        <td className="text-center">{expression.maxRange}</td>
                        <td className="text-center">
                          <div className="flex w-full gap-5 items-center">
                            {/* Edit */}
                            <RowAction
                              action={() =>
                                setExpression({
                                  action: "edit",
                                  value: expression,
                                })
                              }
                              text="Edit"
                              icon={<LuFileEdit size={isScaled ? 25 : 20} />}
                            />

                            {/* Delete */}
                            <RowAction
                              action={() =>
                                setExpression({
                                  action: "delete",
                                  value: expression,
                                })
                              }
                              text="Delete"
                              icon={<LuTrash2 size={isScaled ? 25 : 20} />}
                              danger
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* pagination */}
          {totalPages && (
            <div className="flex justify-end pt-3">
              <ul className="flex items-center gap-2">
                {currentPage > 1 && (
                  <li
                    className={classNames(
                      currentPage === 1
                        ? "cursor-auto text-lightGray"
                        : "cursor-pointer",
                      "p-2"
                    )}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <span>
                      <FiChevronLeft size={20} />
                    </span>
                  </li>
                )}
                {totalPages &&
                  totalPages.map((page: any) => (
                    <li
                      key={page}
                      className={classNames(
                        page === currentPage
                          ? "bg-green"
                          : "bg-transparent hover:bg-dark",
                        "px-3 py-1 rounded-md cursor-pointer"
                      )}
                      onClick={() => setCurrentPage(page)}
                    >
                      <span>{page}</span>
                    </li>
                  ))}
                {totalPages.length > 1 && (
                  <li
                    className={classNames(
                      currentPage === totalPages.length
                        ? "cursor-auto text-lightGray"
                        : "cursor-pointer",
                      "p-2"
                    )}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <span>
                      <FiChevronRight size={20} />
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}

          <ExpressionChart
            selectedExpression={expression}
            setSelectedExpression={setExpression}
          />
          <NewExpression
            selectedExpression={expression}
            setSelectedExpression={setExpression}
            createNewExpression={createNewExpression}
          />
          <EditExpression
            selectedExpression={expression}
            setSelectedExpression={setExpression}
            editExpression={editExpression}
          />
          <DeleteExpression
            selectedExpression={expression}
            setSelectedExpression={setExpression}
            deleteExpressionById={deleteExpression}
          />
        </div>
      )}
    </>
  );
}
