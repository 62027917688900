import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Input } from "@/components/ui/input";
import TimeZones from "components/timeZones/timezones";
import { useEffect, useState } from "react";
import { FiUser, FiX } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Edit({ setScreen, user, update, error, loading }: any) {
  const [editedImage, setEditedImage] = useState<any>(null);
  const [preview, setPreview] = useState<any>(null);
  const [firstName, setFirstName] = useState<any>(user?.firstName);
  const [lastName, setLastName] = useState<any>(user?.lastName);
  const [skype, setSkype] = useState<any>(user?.skypeId);
  const [phoneNumber, setPhoneNumber] = useState<any>(user?.phoneNumber || "");
  const [timeZone, setTimeZone] = useState<any>(user?.ianaTimeZone);

  useEffect(() => {
    setEditedImage(null);
    setPreview(null);
  }, []);

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("phoneNumber", phoneNumber);
    formData.append("skypeId", skype);
    formData.append("avatar", editedImage.value);
    formData.append("points", `${user?.points}` || "0");
    formData.append("ianaTimeZone", timeZone);

    update(formData);
    setScreen("default");
  };

  const handleFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    const objectUrl = URL.createObjectURL(event.target.files[0]);
    setPreview(objectUrl);
    if (!fileUploaded && !editedImage.fileName) {
      setEditedImage({ fileName: "", errors: "required", value: "" });
    } else if (fileUploaded) {
      if (fileUploaded.size <= 2 * 1024 * 1024) {
        // Max 2MB
        setEditedImage({
          fileName: fileUploaded.name,
          errors: "",
          value: event.target.files[0],
        });
      } else {
        setEditedImage({ fileName: "", errors: "fileSize", value: "" });
      }
    }
  };

  return (
    <div className="h-full w-full flex flex-col">
      <div className="gradient p-0.5 rounded-md m-3">
        <div className="bg-gray rounded-md p-3 flex flex-col gap-3">
          <div className="flex justify-end">
            <div
              onClick={() => setScreen("default")}
              className="cursor-pointer hover:text-green duration-300"
            >
              <FiX size={20} />
            </div>
          </div>
          {error && <div>{error}</div>}
          {loading && <div>Updating Profile...</div>}
          <div className="flex flex-col justify-center items-center relative">
            {(user?.avatar || preview) && (
              <Avatar className="w-40 h-40 rounded-full">
                <AvatarImage
                  src={
                    preview ? preview : `data:image/jpeg;base64,${user?.avatar}`
                  }
                  alt="@shadcn"
                />
                <AvatarFallback></AvatarFallback>
              </Avatar>
            )}
            {!user?.avatar && !preview && (
              <div className="w-40 h-40 grid place-content-center bg-dark rounded-full">
                <FiUser size={80} />
              </div>
            )}
            <div className="pt-5">
              <Input
                id="picture"
                className="input placeholder:text-white cursor-pointer"
                type="file"
                onChange={handleFile}
              />
            </div>
          </div>
          {/* Form */}
          <div>
            <form onSubmit={handleUpdate}>
              {/* first name */}
              <div className="py-2">
                <label
                  className="text-white/70 text-[12px]"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  className="input"
                  name="firstName"
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </div>
              {/* last name */}
              <div className="py-2">
                <label className="text-white/70 text-[12px]" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="input"
                  name="lastName"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                />
              </div>
              {/* email */}
              <div className="py-2">
                <label className="text-white/70 text-[12px]" htmlFor="email">
                  Email
                </label>
                <input
                  disabled
                  className="input"
                  name="email"
                  value={user.email}
                />
              </div>
              {/* password */}
              <div className="py-2">
                <label
                  className="text-white/70 text-[12px] flex justify-between items-center"
                  htmlFor="password"
                >
                  <span>Password</span>
                  <div
                    className="cursor-pointer text-green"
                    onClick={() => setScreen("passwords")}
                  >
                    Change Password
                  </div>
                </label>
                <input
                  disabled
                  className="input"
                  name="password"
                  value="password"
                  type="password"
                />
              </div>
              {/* skype */}
              {/* <div className="py-2">
                <label className="text-white/70 text-[12px]" htmlFor="skype">
                  Skype
                </label>
                <input
                  className="input"
                  name="skype"
                  value={skype}
                  onChange={(e: any) => setSkype(e.target.value)}
                />
              </div> */}
              {/* phone number */}
              <div className="py-2">
                <label className="text-white/70 text-[12px]" htmlFor="skype">
                  Phone Number
                </label>
                <PhoneInput
                  country={"uae"}
                  value={phoneNumber}
                  onChange={(phone) => setPhoneNumber(phone)}
                />
              </div>

              {/* time zone */}
              {/* <div className="py-2">
                <label className="text-white/70 text-[12px]" htmlFor="skype">
                  Time Zone
                </label>
                <TimeZones
                  selectedValue={timeZone}
                  select={(value: any) => setTimeZone(value)}
                />
              </div> */}
              {/* Update */}
              <div className="py-2">
                <button type="submit" className="btn w-full">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
