/* eslint-disable @typescript-eslint/no-unused-expressions */
import api from "utilities/Axios";

function subtractHours(date: any) {
  date.setHours(date.getHours() - 720);
  return date;
}

const dateNow = new Date();
const dateTime = subtractHours(dateNow);

export const getAllExpressions = () => {
  return api.get("/ExpMeta/GetAllExpressions");
};

export const getExpressionById = (id: any) => {
  return api.get(`/ExpMeta/GetExpression?id=${id}`);
};

export const getExpressionData = (ids: any) => {
  return api.post("Dashboard/GetExpData", ids);
};

export const createExpression = (expression: any) => {
  return api.post("/ExpMeta/CreateExpression", expression);
};

export const updateExpression = (expression: any) => {
  return api.post("/ExpMeta/UpdateExpression", expression);
};

export const deleteExpression = (id: any) => {
  return api.get(`/ExpMeta/DeleteExpression?id=${id}`);
};

export const validateExpression = (expression: any) => {
  return api.post("/ExpMeta/ValidateExpression", expression);
};
