import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { updateAlarm } from "api/alarm";
import Loader from "components/shared/Loader";
import { useMemo, useState } from "react";
import { LuFileEdit, LuTimerReset, LuTrash2 } from "react-icons/lu";
import Debounce from "utilities/Debounce";
import DeleteTag from "./DeleteTag";
import EditTag from "./EditTag";
import MapTag from "./MapTag";
import ResetTag from "./ResetTag";
import TagChart from "./TagChart";
import { disableTag } from "api/tags";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";

const RowAction = (props: any) => {
  const { isScaled } = useApp();
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <div
            className="cursor-pointer hover:text-green"
            onClick={props.action}
          >
            {props.icon}
          </div>
        </TooltipTrigger>
        <TooltipContent
          className={classNames(isScaled ? "-mr-9 text-lg" : "")}
          style={{ background: props.danger ? "#ef4444" : "#34d399" }}
        >
          <p className="text-white">{props.text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default function Units({
  selectedEquipment,
  setRefresh,
  data,
  getTagsById,
  editTag,
  resetTag,
  deleteTag,
}: any) {
  const { isScaled } = useApp();
  const [showMapDialog, setShowMapDialog] = useState(false);
  const [tag, setTag] = useState<any>({
    action: "",
    value: null,
  });
  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.tagNameIdentifier.toLowerCase().includes(keyword) ||
          item.tagNameIdentifier.toLowerCase() === keyword ||
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }
    return items;
  }, [data, debouncedKeyword]);

  const alarm = async (id: any, isAlertTag: any) => {
    const response: any = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      setRefresh(true);
    }
  };

  const disable = async (id: any, isDisabled: any) => {
    const response: any = await disableTag(id, isDisabled ? 2 : 1);
    if (response.status === 200) {
      setRefresh(true);
    }
  };

  return (
    <>
      <div
        className={classNames(
          isScaled ? "h-[430px]" : "h-full",
          "gradient p-0.5 rounded-md overflow-hidden"
        )}
      >
        <div className="bg-black pl-3 pt-3 pb-3 rounded-md h-full overflow-hidden overflow-y-scroll">
          {!data && (
            <div className="w-full h-full grid place-content-center">
              <Loader />
            </div>
          )}
          {data && (
            <>
              <div className="flex justify-between">
                <div>
                  <input
                    onChange={(e: any) => setKeyword(e.target.value)}
                    className={classNames(isScaled ? "text-lg" : "", "input")}
                    type="text"
                    placeholder="Search"
                  />
                </div>
                <h1 className="font-bold text-lg mb-2">Tags</h1>
                <div>
                  <button
                    className="btn"
                    onClick={() => setShowMapDialog(true)}
                  >
                    Map Tags
                  </button>
                </div>
              </div>

              <div
                className={classNames(
                  isScaled ? "text-2xl" : "text-base",
                  "bg-black rounded-md border-none mt-5"
                )}
              >
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="text-green">
                      <th className="text-left">PI Name</th>
                      <th className="text-left">Unit</th>
                      <th className="text-left">Equipment</th>
                      <th className="text-left">Tag Name</th>
                      <th>Engineering Unit</th>
                      <th>Min Value</th>
                      <th>Max Value</th>
                      <th>Min Range</th>
                      <th>Max Range</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows &&
                      rows.map((tag: any) => (
                        <tr
                          key={tag.id}
                          className="border-b border-b-lightGray hover:bg-dark duration-300"
                        >
                          <td
                            onClick={() =>
                              setTag({ action: "chart", value: tag })
                            }
                            className="underline cursor-pointer hover:text-green"
                          >
                            {tag.tagNameIdentifier}
                          </td>
                          <td>{tag.unitName}</td>
                          <td>{tag.equipmentName}</td>
                          <td>{tag.name}</td>
                          <td className="text-center">{tag.uom}</td>
                          <td className="text-center">{tag.minValue}</td>
                          <td className="text-center">{tag.maxValue}</td>
                          <td className="text-center">{tag.minRange}</td>
                          <td className="text-center">{tag.maxRange}</td>
                          <td className="text-left">
                            <div className="flex w-full gap-5 items-center justify-center">
                              {/* Edit */}
                              <RowAction
                                action={() =>
                                  setTag({ action: "edit", value: tag })
                                }
                                text="Edit"
                                icon={<LuFileEdit size={isScaled ? 30 : 20} />}
                              />

                              {/* Reset */}
                              <RowAction
                                action={() =>
                                  setTag({ action: "reset", value: tag })
                                }
                                text="Reset"
                                icon={
                                  <LuTimerReset size={isScaled ? 30 : 20} />
                                }
                              />
                              {/* Delete */}
                              <RowAction
                                action={() =>
                                  setTag({ action: "delete", value: tag })
                                }
                                text="Delete"
                                icon={<LuTrash2 size={isScaled ? 30 : 20} />}
                                danger
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
      {showMapDialog && (
        <MapTag
          mappedTags={data}
          setShowMapDialog={setShowMapDialog}
          setRefresh={setRefresh}
          selectedEquipment={selectedEquipment}
          getTagsById={getTagsById}
        />
      )}
      <TagChart
        alarm={alarm}
        disable={disable}
        selectedTag={tag}
        setSelectedTag={setTag}
      />
      <EditTag selectedTag={tag} setSelectedTag={setTag} editTag={editTag} />
      <ResetTag
        selectedTag={tag}
        setSelectedTag={setTag}
        resetTagById={resetTag}
      />
      <DeleteTag
        selectedTag={tag}
        setSelectedTag={setTag}
        deleteTagById={deleteTag}
      />
    </>
  );
}
