import ExpressionArea from "components/charts/expression/ExpressionArea";
import useApp from "hooks/useApp";
import useData from "hooks/useData";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function ExpressionChart({
  selectedExpression,
  setSelectedExpression,
}: any) {
  const { isScaled } = useApp();
  const { getExpressionInfo } = useData();
  const [expression, setExpression] = useState<any>(null);

  return (
    <>
      {selectedExpression.action === "chart" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div className="absolute z-20 inset-0 w-full h-full bg-black/70" />
          <div
            className={classNames(
              isScaled ? "mt-48 max-w-[800px]" : "sm:max-w-[650px]",
              "w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            )}
          >
            <div className="bg-dark p-3 w-full h-full rounded-md">
              <div className="flex flex-col gap-5">
                {/* Header */}
                <div className="flex justify-between items-center">
                  <h1>{selectedExpression.equipmentName}</h1>
                  <span
                    className="cursor-pointer bg-green rounded-md p-1"
                    onClick={() =>
                      setSelectedExpression({ action: "", value: null })
                    }
                  >
                    <FiX size={isScaled ? 25 : 20} />
                  </span>
                </div>
                {/* content */}
                {selectedExpression.value && (
                  <div>
                    <ExpressionArea
                      data={selectedExpression.value}
                      bar
                      unity
                      isExpression
                    />
                  </div>
                )}
                {/* Footer */}
                {expression && (
                  <div className="pt-5">
                    <div className="flex items-center">
                      <p>Tag Name:</p>
                      <div className="flex-1" />
                      <p>{expression.name}</p>
                    </div>

                    <div className="flex items-center">
                      <p>Description:</p>
                      <div className="flex-1" />
                      <p>{expression.desc}</p>
                    </div>

                    <div className="flex items-center">
                      <p>Engineering Unit:</p>
                      <div className="flex-1" />
                      <p>{expression.uom}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
