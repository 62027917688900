import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";
import Widget from "./Widget";

export default function WidgetGrid() {
  const { dashboardWidgets } = useApp();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-rows-2 gap-2 pt-2 pl-3 pb-10 2xl:pb-3 w-full h-full">
      {dashboardWidgets.widget1
        .sort((a: any, b: any) => a.position - b.position)
        .map((widget: any) => (
          <Widget
            key={widget.id}
            widgets={dashboardWidgets.widget1}
            widget={widget}
          />
        ))}
    </div>
  );
}
