/* eslint-disable @typescript-eslint/no-unused-expressions */
import api from "utilities/Axios";

export const updateAlarm = (tagId: number, status: any) => {
  return api.get(`/AlertTag/SetAlertStatus?tagId=${tagId}&status=${status}`);
};

export const getCount = () => {
  return api.get("/AlertTag/GetActiveAlertCount");
};

export const getAlarms = () => {
  return api.get("/AlertTag/GetAlertTagHistories");
};

export const updateAlarmHistory = (alarmId: number, status: number) => {
  return api.get(
    `/AlertTag/SetAlertHistoryStatus?tagHistoryId=${alarmId}&status=${status}`
  );
};

export const deleteAlarm = (alarmId: number) => {
  return api.get(`/AlertTag/DeleteAlertHistory?tagHistoryId=${alarmId}`);
};

export const AcceptAllAlarm = () => {
  return api.get("/AlertTag/AcceptAllAlerts");
};

export const DeleteAllAlarm = () => {
  return api.get("/AlertTag/DeleteAllAlerts");
};
