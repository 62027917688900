import { useEffect, useState } from "react";
import Gauge from "components/charts/SmallGauge";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";

const GaugeBox = ({ data, name }: any) => {
  return (
    <div className="rounded-md bg-dark flex items-center justify-center p-1 pt-3">
      <Gauge data={data} name={name} small />
    </div>
  );
};

export default function GaugePage({ tab }: any) {
  const [loading, setLoading] = useState(true);
  const { k1, k2, k3, k4 } = tab.configurations;

  const [k1Data, setK1Data] = useState<any>(null);
  const [k2Data, setK2Data] = useState<any>(null);
  const [k3Data, setK3Data] = useState<any>(null);
  const [k4Data, setK4Data] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    (async () => {
      // k1
      if (k1?.value?.tagNameIdentifier && k1?.value?.id) {
        const response = await getTagData([k1.value.id]);
        if (response.status === 200) {
          setK1Data(response.data[0]);
        }
      } else if (k1.value.id) {
        const response = await getExpressionData([k1.value.id]);
        if (response.status === 200) {
          setK1Data(response.data[0]);
        }
      }
      // k2
      if (k2?.value?.tagNameIdentifier && k2?.value?.id) {
        const response = await getTagData([k2.value.id]);
        if (response.status === 200) {
          setK2Data(response.data[0]);
        }
      } else if (k2.value.id) {
        const response = await getExpressionData([k2.value.id]);
        if (response.status === 200) {
          setK2Data(response.data[0]);
        }
      }
      // k3
      if (k3?.value?.tagNameIdentifier && k3?.value?.id) {
        const response = await getTagData([k3.value.id]);
        if (response.status === 200) {
          setK3Data(response.data[0]);
        }
      } else if (k3.value.id) {
        const response = await getExpressionData([k3.value.id]);
        if (response.status === 200) {
          setK3Data(response.data[0]);
        }
      }
      // k4
      if (k4?.value?.tagNameIdentifier && k4?.value?.id) {
        const response = await getTagData([k4.value.id]);
        if (response.status === 200) {
          setK4Data(response.data[0]);
        }
      } else if (k4.value.id) {
        const response = await getExpressionData([k4.value.id]);
        if (response.status === 200) {
          setK4Data(response.data[0]);
        }
      }
    })();
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <div className="grid grid-cols-2 grid-rows-2 gap-3 h-full">
          {k1Data && <GaugeBox data={k1Data} name={k1.name} />}
          {k2Data && <GaugeBox data={k2Data} name={k2.name} />}
          {k3Data && <GaugeBox data={k3Data} name={k3.name} />}
          {k4Data && <GaugeBox data={k4Data} name={k4.name} />}
        </div>
      )}
    </>
  );
}
