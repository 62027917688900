import Layout from "components/layout";
import DashboardConfiguration from "components/shared/DashboardConfiguration";
import Alarms from "pages/alarms/Alarms";
import AllAssets from "pages/all-assets/AllAssets";
import Auth from "pages/auth";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import ForgetPassword from "pages/auth/ForgetPassword";
import Dashboard from "pages/Dashboard/dashboard";
import ExpressionContainer from "pages/expressions/ExpressionContainer";
import TagContainer from "pages/tags/TagContainer";
import TicketsContainer from "pages/tickets/TicketsContainer";
import UnityContainer from "pages/unity";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthGuard } from "utilities/AuthGuard";
import "./App.css";
import UsersContainer from "pages/users/UsersContainer";
import UnityLayout from "components/layout/UnityLayout";
import NewDashboard from "pages/NewDashboard";
import HealthContainer from "pages/HealthCheck";

function App() {
  return (
    <Routes>
      {/* <Route path="/auth">
        <Route element={<Auth />}>
          <Route index element={<Navigate to="/auth/login" />} />
          <Route path="login" element={<Login />}></Route>
          <Route path="register" element={<Register />}></Route>
          <Route path="forget-password" element={<ForgetPassword />}></Route>
        </Route>
      </Route> */}
      <Route element={<AuthGuard />}>
        <Route element={<Layout />}>
          <Route path="/new-dashboard" element={<NewDashboard />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/dashboard" element={<NewDashboard />} />
          <Route path="/dashboard-configuration">
            <Route element={<DashboardConfiguration />}>
              <Route
                index
                element={<Navigate to="/dashboard-configuration/tags" />}
              />
              <Route path="tags" element={<TagContainer />} />
              <Route path="expressions" element={<ExpressionContainer />} />
              <Route path="all-assets" element={<AllAssets />} />
            </Route>
          </Route>
          {/* <Route path="/alarms" element={<Alarms />} />
          <Route path="/tickets" element={<TicketsContainer />} /> */}
          <Route path="/users" element={<UsersContainer />} />
          <Route path="/health" element={<HealthContainer />} />
        </Route>
        <Route element={<UnityLayout />}>
          <Route path="/3d-dashboard" element={<UnityContainer />} />
        </Route>
      </Route>
      <Route path="*" element={<p>Not Found</p>} />
    </Routes>
  );
}

export default App;
