import {
  Tooltip as ToolipShadcn,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { getTagsPastData } from "api/dashboard";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import zoomPlugin from "chartjs-plugin-zoom";
import Loader from "components/shared/Loader";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { BiAlarm, BiExpand } from "react-icons/bi";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { MdOutlineDoDisturbOn } from "react-icons/md";
import { PiFileCsv, PiFilePdf, PiFilePng } from "react-icons/pi";
import { RxIdCard } from "react-icons/rx";
import { TbZoomOutArea } from "react-icons/tb";
import classNames from "utilities/ClassNames";
import Bar from "../Bar";
import { getTagData } from "api/tags";
import BigGraph from "./BigGraph";
import useApp from "hooks/useApp";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  zoomPlugin
);

export default function Area({
  data,
  bar,
  setTicketDialog,
  isTag,
  alarm,
  disable,
}: any) {
  const { isScaled } = useApp();
  const chartRef = useRef<any>(null);
  const [bigGraph, setBigGraph] = useState(false);
  const [isDaily, setIsDaily] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tagWithData, setTagWithData] = useState<any>(null);
  const [valueSet, setValueSet] = useState<number[]>();
  const [dataList, setDataList] = useState<any>();
  const [minValue, setMinValue] = useState<any>();
  const [maxValue, setMaxValue] = useState<any>();
  const [isAlertTagStatus, setIsAlertTagStatus] = useState<any>(null);
  const [isDisabledStatus, setIsDisabledStatus] = useState<any>(null);
  const [predictionList, setPredictionList] = useState<any>(null);

  function randomIntFromInterval() {
    const min = bigGraph ? 3 : 300,
      max = 20;
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const randomsDays = randomIntFromInterval();

  useEffect(() => {
    setIsAlertTagStatus(data.isAlertTag);
    setIsDisabledStatus(data.isDisabled);
  }, [data]);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response: any = await getTagsPastData(
        data.id,
        bigGraph ? 365 : 7,
        isDaily ? "D" : "H"
      );
      if (response?.data && response?.status === 200) {
        const dataList = response?.data
          .filter((tag: any) => !tag.isFuture)
          .map((tag: any) => ({
            x: new Date(tag.createdOn).getTime(),
            y: tag.tagValue,
          }));
        const valuesSet = response?.data
          .filter((tag: any) => !tag.isFuture)
          .map((tag: any) => tag.tagValue);
        setDataList(dataList);
        setValueSet(valuesSet);
        const futureData = response?.data
          .filter((tag: any) => tag.isFuture)
          .map((tag: any) => ({
            x: new Date(tag.createdOn).getTime(),
            y: tag.tagValue,
          }));
        setPredictionList(futureData);
      }
      const dataResponse: any = await getTagData([data.id]);
      if (dataResponse.status === 200) {
        setTagWithData(dataResponse.data[0]);
      }
      setLoading(false);
    })();
  }, [isDaily]);

  useEffect(() => {
    const minValue = valueSet?.reduce(
      (a: any, b: any) => Math.min(a, b),
      Infinity
    );
    const maxValue = valueSet?.reduce(
      (a: any, b: any) => Math.max(a, b),
      -Infinity
    );
    setMinValue(minValue);
    setMaxValue(maxValue);
  }, [valueSet]);

  useEffect(() => {
    if (
      dataList &&
      dataList.length > 0 &&
      predictionList &&
      predictionList.length > 0
    ) {
      setPredictionList([dataList[dataList.length - 1], ...predictionList]);
    }
  }, [dataList]);

  const options: any = {
    animation: {
      duration: 0,
    },
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: isScaled ? 18 : 15,
          },
        },
      },
      y: {
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: isScaled ? 18 : 15,
          },
        },
        min: Math.abs(minValue) > 10 ? minValue - 5 : minValue,
        max: Math.abs(maxValue) > 10 ? maxValue + 5 : maxValue,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        axis: "x",
        intersect: false,
        filter: function (tooltipItem: any) {
          return tooltipItem.datasetIndex === 0;
        },
      },
      legend: {
        display: false,
      },
      interaction: {
        intersect: true,
      },
      zoom: {
        animation: {
          duration: 1000,
          easing: "easeOutCubic",
        },
        enabled: true,
        mode: "xy",
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgba(62, 252, 173, 0.2)",
            borderColor: "rgb(19, 146, 93)",
            borderWidth: 1,
          },
          wheel: {
            enabled: true,
            modifierKey: "ctrl",
          },
        },
        limits: {
          x: {
            min: data.minRange,
          },
        },
      },
    },
  };

  let minSetData = null;
  let maxSetData = null;
  let fullArray = null;
  if (predictionList && predictionList.length > 0) {
    fullArray = dataList.concat(predictionList);
  } else {
    fullArray = dataList;
  }

  if (minValue < data.minRange) {
    if (fullArray) {
      minSetData = fullArray?.map((set: any) => ({
        x: set.x,
        y: data.minRange,
      }));
    } else {
      minSetData = dataList?.map((set: any) => ({
        x: set.x,
        y: data.minRange,
      }));
    }
  }

  if (maxValue > data.maxRange) {
    if (fullArray) {
      maxSetData = fullArray?.map((set: any) => ({
        x: set.x,
        y: data.maxRange,
      }));
    } else {
      maxSetData = dataList?.map((set: any) => ({
        x: set.x,
        y: data.maxRange,
      }));
    }
  }

  let minSet = null;
  let maxSet = null;

  if (minSetData) {
    minSet = {
      fill: "start",
      label: "minSet",
      data: minSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  if (maxSetData) {
    maxSet = {
      fill: "end",
      label: "maxSet",
      data: maxSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  const defaultDataSet = {
    fill: true,
    label: "Tag Value",
    data: dataList,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
    lineTension: 0.3,
    pointRadius: 0,
    pointHoverRadius: 0,
    order: 1,
  };

  const futureDataSet = {
    fill: true,
    label: "Prediction Value",
    data: predictionList,
    borderColor: "rgb(219, 39, 119)",
    backgroundColor: "rgba(219, 39, 119, 0.5)",
    lineTension: 0.3,
    pointRadius: 0,
    pointHoverRadius: 0,
    order: 1,
  };

  const datasetsArray = [];
  datasetsArray.push(defaultDataSet);
  datasetsArray.push(futureDataSet);

  if (minSet) {
    datasetsArray.push(minSet);
  }

  if (maxSet) {
    datasetsArray.push(maxSet);
  }

  const chartData = {
    datasets: datasetsArray,
  };

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const zoomIn = () => {
    chartRef.current.zoom(1.1);
  };

  const zoomOut = () => {
    chartRef.current.zoom(0.9);
  };

  const downloadPng = () => {
    const link = document.createElement("a");
    link.download = data.name;
    link.href = chartRef.current.toBase64Image();
    link.click();
  };

  const downloadPdf = () => {
    const image = chartRef?.current?.canvas.toDataURL("image/jpg", 1.0);
    const pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    pdf.addImage(image, "JPEG", 15, 15, 260, 150);
    pdf.save(data.name);
  };

  const downloadCsv = () => {
    const csvData = [
      ["Date", "Expression Value"],
      ...dataList.map((item: any) => [
        dayjs(item.x).format("YYYY-MM-DDTHH:mm:ss"),
        item.y,
      ]),
    ]
      .map((e: any) => e.join(","))
      .join("\n");

    const link = document.createElement("a");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    link.href = URL.createObjectURL(blob);
    link.download = data.name;
    link.click();
  };

  const bgColor = {
    id: `areaChart_${data.id}`,
    beforeDraw: (chart: any, option: any) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.fillStyle = "#111111";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  if (loading)
    return (
      <div className="w-full h-full grid place-content-center min-h-[200px]">
        <Loader />
      </div>
    );

  return (
    <>
      {!bigGraph && data && (
        <div className="flex flex-col gap-3 w-full mx-auto">
          <div
            className={classNames(
              isScaled ? "min-h-[560px]" : "min-h-[470px]",
              "flex gap-3 w-full border-2 border-green rounded-md p-3 mt-3"
            )}
          >
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between">
                <p className="text-lg font-bold">{data.tagNameIdentifier}</p>
                {/* tag controls */}
                <div className="flex items-center justify-between">
                  {isTag && (
                    <div className="flex gap-2">
                      {/* 3D Unity */}
                      {true && (
                        <TooltipProvider delayDuration={0}>
                          <ToolipShadcn>
                            <TooltipTrigger>
                              <div
                                className={classNames(
                                  isScaled
                                    ? "leading-[1px]"
                                    : "leading-[1.15rem]",
                                  "grid place-content-center duration-300 bg-green p-1.5 rounded-md cursor-pointer"
                                )}
                              >
                                <span
                                  className={classNames(
                                    isScaled
                                      ? "text-xl leading-[1.55rem]"
                                      : "leading-[1.15rem]"
                                  )}
                                >
                                  3D
                                </span>
                              </div>
                            </TooltipTrigger>
                            <TooltipContent style={{ background: "#34d399" }}>
                              <p className="text-stone-100">Open Unity</p>
                            </TooltipContent>
                          </ToolipShadcn>
                        </TooltipProvider>
                      )}
                      {/* Alarm */}
                      {alarm && (
                        <TooltipProvider delayDuration={0}>
                          <ToolipShadcn>
                            <TooltipTrigger>
                              <div
                                onClick={() => {
                                  alarm(data.id, isAlertTagStatus);
                                  setIsAlertTagStatus(!isAlertTagStatus);
                                }}
                                className={classNames(
                                  isAlertTagStatus ? "text-red" : "text-white",
                                  "grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                                )}
                              >
                                <BiAlarm size={isScaled ? 25 : 18} />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent style={{ background: "#34d399" }}>
                              <p className="text-stone-100">
                                {isAlertTagStatus
                                  ? "Remove Alarm"
                                  : "Add Alarm"}
                              </p>
                            </TooltipContent>
                          </ToolipShadcn>
                        </TooltipProvider>
                      )}
                      {/* Disable */}
                      <TooltipProvider delayDuration={0}>
                        <ToolipShadcn>
                          <TooltipTrigger>
                            <div
                              onClick={() => {
                                disable(data.id, isDisabledStatus);
                                setIsDisabledStatus(!isDisabledStatus);
                              }}
                              className={classNames(
                                isDisabledStatus ? "text-slate" : "text-white",
                                "grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                              )}
                            >
                              <MdOutlineDoDisturbOn size={isScaled ? 25 : 18} />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent style={{ background: "#34d399" }}>
                            <p className="text-stone-100">Disable Tag</p>
                          </TooltipContent>
                        </ToolipShadcn>
                      </TooltipProvider>
                      {/* Ticket */}
                      {!data.isNormal && !data.isTicketCreated && (
                        <TooltipProvider delayDuration={0}>
                          <ToolipShadcn>
                            <TooltipTrigger>
                              <div
                                onClick={() => setTicketDialog(true)}
                                className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                              >
                                <RxIdCard size={isScaled ? 25 : 18} />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent style={{ background: "#34d399" }}>
                              <p className="text-stone-100">Create Ticket</p>
                            </TooltipContent>
                          </ToolipShadcn>
                        </TooltipProvider>
                      )}
                      {/* Expand */}
                      <TooltipProvider delayDuration={0}>
                        <ToolipShadcn>
                          <TooltipTrigger>
                            <div
                              onClick={() => setBigGraph(true)}
                              className="grid place-content-center duration-300 bg-green p-1.5 leading-3 rounded-md cursor-pointer"
                            >
                              <BiExpand size={isScaled ? 25 : 18} />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent style={{ background: "#34d399" }}>
                            <p className="text-stone-100">Expand</p>
                          </TooltipContent>
                        </ToolipShadcn>
                      </TooltipProvider>
                      {/* <ShadcnTooltip text="Alarm">
            <div className="grid place-content-center duration-300 hover:text-green bg-black p-1.5 leading-3 rounded-md cursor-pointer">
              <BiAlarm size={18} />
            </div>
          </ShadcnTooltip>
          <ShadcnTooltip text="Alarm">
            <div className="grid place-content-center duration-300 hover:text-green bg-black p-1.5 leading-3 rounded-md cursor-pointer">
              <MdOutlineDoDisturbOn size={18} />
            </div>
          </ShadcnTooltip>
          <ShadcnTooltip text="Alarm">
            <div className="grid place-content-center duration-300 hover:text-green bg-black p-1.5 leading-3 rounded-md cursor-pointer">
              <LiaIdCard size={18} />
            </div>
          </ShadcnTooltip> */}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex gap-3 mt-5 w-full">
                <div className="flex flex-col w-full">
                  <div>
                    <Line
                      ref={chartRef}
                      plugins={[bgColor]}
                      options={options}
                      data={chartData}
                    />
                  </div>
                  {bar && tagWithData && (
                    <div className="w-[70%] mx-auto mt-5">
                      <Bar
                        data={tagWithData}
                        meta={data}
                        barChartName={data.name}
                        mainValue={tagWithData.value}
                      />
                    </div>
                  )}
                </div>
                <div className=" flex flex-col gap-3 items-center mt-3">
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={zoomIn}
                  >
                    <FiZoomIn size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={zoomOut}
                  >
                    <FiZoomOut size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={resetZoom}
                  >
                    <TbZoomOutArea size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300 mt-3"
                    onClick={downloadCsv}
                  >
                    <PiFileCsv size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={downloadPdf}
                  >
                    <PiFilePdf size={20} />
                  </div>
                  <div
                    className="cursor-pointer hover:text-green duration-300"
                    onClick={downloadPng}
                  >
                    <PiFilePng size={20} />
                  </div>
                  <div
                    className={classNames(
                      isDaily ? "font-bold text-green" : "text-white",
                      "cursor-pointer duration-300 mt-3 leading-3"
                    )}
                    onClick={() => setIsDaily(true)}
                  >
                    D
                  </div>
                  <div
                    className={classNames(
                      !isDaily ? "font-bold text-green" : "text-white",
                      "cursor-pointer duration-300 leading-3"
                    )}
                    onClick={() => setIsDaily(false)}
                  >
                    H
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {bigGraph && data && <BigGraph data={data} setBigGraph={setBigGraph} />}
    </>
  );
}
