import Loader from "components/shared/Loader";
import { usePins } from "hooks/usePins";
import Pin from "./Pin";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function PinnedWidgets() {
  const { isScaled } = useApp();
  const { dashboardPins, loading } = usePins();

  return (
    <div className="w-full 2xl:w-80 flex flex-row 2xl:flex-col ">
      {loading && (
        <div className="w-full h-full p-3 grid place-content-center">
          <Loader />
        </div>
      )}
      {dashboardPins && (
        <div
          className={classNames(
            isScaled ? "flex-col" : "2xl:flex-col",
            "w-full flex gap-3 flex-row overflow-hidden h-full p-3 pb-0 pt-2 2xl:pr-0 2xl:mb-3 overflow-x-scroll 2xl:overflow-y-scroll 2xl:overflow-x-hidden"
          )}
        >
          {dashboardPins.map((pin: any) => (
            <Pin key={pin.meta.id} data={pin} />
          ))}
        </div>
      )}
    </div>
  );
}
