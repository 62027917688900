import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  createTrain,
  updateTrain as apiUpdateTrain,
  deleteTrain as apiDeleteTrain,
} from "api/trains";
import DataTable from "components/DataTables";
import Loader from "components/shared/Loader";
import useApp from "hooks/useApp";
import { useState } from "react";
import { FiEdit, FiTrash2, FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function Trains({
  setShowUnits,
  setSelectedTrain,
  data,
  getTrains,
}: any) {
  const { isScaled } = useApp();
  const [screen, setScreen] = useState("");
  const [updateTrain, setUpdateTrain] = useState<any>(null);
  const [deleteTrain, setDeleteTrain] = useState<any>(null);

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "name",
      header: () => <div className="font-bold text-green">Train Name</div>,
      cell: ({ row }) => {
        return (
          <div
            onClick={() => {
              setShowUnits(true);
              setSelectedTrain(row.original);
            }}
            className="cursor-pointer hover:text-green"
          >
            {row.original.name}
          </div>
        );
      },
    },
    {
      accessorKey: "desc",
      header: () => <div className="font-bold text-green">Description</div>,
    },
    {
      accessorKey: "seqOrder",
      header: () => <div className="font-bold text-green">Seq.No.</div>,
    },
    {
      header: () => (
        <div className="text-center font-bold text-green">Actions</div>
      ),
      id: "actions",
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-center gap-5">
            <div
              onClick={() => {
                setUpdateTrain(row.original);
                setScreen("edit");
              }}
              className="cursor-pointer hover:text-green"
            >
              <FiEdit size={isScaled ? 25 : 20} />
            </div>
            <div
              onClick={() => {
                setDeleteTrain(row.original.id);
                setScreen("delete");
              }}
              className="cursor-pointer hover:text-green"
            >
              <FiTrash2 size={isScaled ? 25 : 20} />
            </div>
          </div>
        );
      },
    },
  ];
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      columnFilters,
    },
  });

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [seqOrder, setSeqOrder] = useState<any>(0);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const response = await createTrain({
      name,
      desc,
      seqOrder: parseInt(seqOrder),
      plantId: 1,
      trainId: null,
      unitId: null,
    });
    if (response.status === 200) {
      getTrains();
      setScreen("");
    }
  };

  const handleUpdate = async (event: any) => {
    event.preventDefault();
    const response = await apiUpdateTrain({
      name: updateTrain.name,
      desc: updateTrain.desc,
      seqOrder: parseInt(updateTrain.seqOrder),
      plantId: 1,
      trainId: null,
      unitId: null,
      id: updateTrain.id,
    });
    if (response.status === 200) {
      getTrains();
      setScreen("");
    }
  };

  const handleDelete = async (event: any) => {
    event.preventDefault();
    const response = await apiDeleteTrain(deleteTrain);
    if (response.status === 200) {
      getTrains();
      setScreen("");
    }
  };

  return (
    <div
      className={classNames(
        isScaled ? "h-[500px]" : "h-full",
        "gradient p-0.5 rounded-md overflow-hidden"
      )}
    >
      <div className="bg-black pl-3 pt-3 pb-3 rounded-md h-full overflow-hidden overflow-y-scroll">
        {!data && (
          <div className="w-full h-full grid place-content-center">
            <Loader />
          </div>
        )}
        {data && (
          <>
            <h1 className="font-bold text-lg mb-2">Trains</h1>
            <DataTable
              table={table}
              showToolbar
              searchColumn="name"
              searchPlaceholder="Search"
              columns={columns}
              data={data}
              noBorder
              isScaled={isScaled}
              rightMenuAction={
                <button className="btn" onClick={() => setScreen("add")}>
                  Add
                </button>
              }
            />
          </>
        )}
        {data && screen === "add" && (
          <div className="fixed inset-0 w-full h-full z-10">
            <div className="fixed inset-0 bg-black/80 w-full h-full z-20" />
            <div className="w-[700px] z-30 absolute bg-dark p-3 rounded-md transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              {/* header */}
              <div className="flex w-full justify-between">
                <h1 className="text-xl font-bold">New Train</h1>
                <div onClick={() => setScreen("")} className="cursor-pointer">
                  <FiX size={20} />
                </div>
              </div>
              {/* content */}
              <div className="mt-3">
                <form onSubmit={handleSubmit}>
                  {/* name */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Name</label>
                    <input
                      value={name}
                      onChange={(event: any) => setName(event.target.value)}
                      required
                      type="text"
                      className="input"
                      placeholder="Name"
                    />
                  </div>
                  {/* description */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Description</label>
                    <input
                      value={desc}
                      onChange={(event: any) => setDesc(event.target.value)}
                      required
                      type="text"
                      className="input"
                      placeholder="Description"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Sequence Order</label>
                    <input
                      value={seqOrder}
                      onChange={(event: any) =>
                        setSeqOrder(parseInt(event.target.value))
                      }
                      required
                      type="number"
                      className="input"
                      placeholder="Sequence Order"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex justify-end">
                    <button type="submit" className="btn">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {data && screen === "edit" && updateTrain && (
          <div className="fixed inset-0 w-full h-full z-10">
            <div className="fixed inset-0 bg-black/80 w-full h-full z-20" />
            <div className="w-[700px] z-30 absolute bg-dark p-3 rounded-md transform left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
              {/* header */}
              <div className="flex w-full justify-between">
                <h1 className="text-xl font-bold">New Train</h1>
                <div onClick={() => setScreen("")} className="cursor-pointer">
                  <FiX size={20} />
                </div>
              </div>
              {/* content */}
              <div className="mt-3">
                <form onSubmit={handleUpdate}>
                  {/* name */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Name</label>
                    <input
                      value={updateTrain.name}
                      onChange={(event: any) =>
                        setUpdateTrain({
                          ...updateTrain,
                          name: event.target.value,
                        })
                      }
                      required
                      type="text"
                      className="input"
                      placeholder="Name"
                    />
                  </div>
                  {/* description */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Description</label>
                    <input
                      value={updateTrain.desc}
                      onChange={(event: any) =>
                        setUpdateTrain({
                          ...updateTrain,
                          desc: event.target.value,
                        })
                      }
                      required
                      type="text"
                      className="input"
                      placeholder="Description"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex flex-col gap-1">
                    <label>Sequence Order</label>
                    <input
                      value={updateTrain.seqOrder}
                      onChange={(event: any) =>
                        setUpdateTrain({
                          ...updateTrain,
                          seqOrder: event.target.value,
                        })
                      }
                      required
                      type="number"
                      className="input"
                      placeholder="Sequence Order"
                    />
                  </div>
                  {/* Sequence Order */}
                  <div className="py-2 flex justify-end">
                    <button type="submit" className="btn">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {data && screen === "delete" && deleteTrain && (
          <div className="fixed inset-0 w-full h-full bg-black/90 grid place-content-center">
            <div className="flex justify-center items-center flex-col gap-5 p-3 pb-5 text-center bg-dark rounded-md">
              <p>Are you sure you want to delete this train</p>
              <div className="flex gap-5">
                <button className="btn-sec" onClick={() => setScreen("")}>
                  cancel
                </button>
                <button className="btn-danger" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
