import { getCount } from "api/alarm";
import BrainImg from "components/shared/BrainImg";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BsTags } from "react-icons/bs";
import { FiSettings, FiUsers } from "react-icons/fi";
import { RxDashboard, RxGlobe, RxIdCard, RxStopwatch } from "react-icons/rx";
import { TbBrandUnity } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function SideMenu({ closeMenu, showSideMenu }: any) {
  const { updateDashboardWidgets, dashboardWidgets, isScaled } = useApp();
  const { pathname } = useLocation();
  const [alarmCount, setAlarmCount] = useState();
  const slide = {
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
      },
      display: "flex",
    },
    exit: {
      opacity: 0,
      x: "-100%",
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const appear = {
    enter: {
      opacity: 1,
      transition: {
        duration: 0.3,
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.1,
        delay: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };
  const getAlarmCount = async () => {
    const response: any = await getCount();
    if (response.status === 200) {
      setAlarmCount(response.data);
    }
  };
  useEffect(() => {
    getAlarmCount();
  }, []);
  return (
    <motion.div
      initial="exit"
      animate={showSideMenu ? "enter" : "exit"}
      variants={appear}
      className="fixed z-40 inset-0 w-full bg-black/90"
      onClick={closeMenu}
    >
      <motion.div
        initial="exit"
        animate={showSideMenu ? "enter" : "exit"}
        variants={slide}
        className={classNames(
          isScaled ? "w-[350px]" : "w-[260px]",
          "absolute h-full left-0 top-0 z-20 bg-gray"
        )}
      >
        <div className="h-full w-full mt-20 pb-20 flex flex-col justify-between">
          <ul className="flex flex-col">
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <RxDashboard size={isScaled ? 25 : 20} />
              <span className={isScaled ? "text-xl" : "text-base"}>
                Dashboard
              </span>
            </NavLink>
            {pathname === "/dashboard" && (
              <div
                onClick={() => {
                  closeMenu();
                  updateDashboardWidgets({
                    ...dashboardWidgets,
                    selectedType: "init",
                  });
                }}
                className="hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent cursor-pointer"
              >
                <FiSettings size={isScaled ? 25 : 20} />
                <span className={isScaled ? "text-xl" : "text-base"}>
                  Dashboard Configuration
                </span>
              </div>
            )}
            <NavLink
              to="/3d-dashboard"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <TbBrandUnity size={isScaled ? 25 : 20} />
              <span className={isScaled ? "text-xl" : "text-base"}>
                3D Plant
              </span>
            </NavLink>
            <NavLink
              to="/dashboard-configuration"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <BsTags size={isScaled ? 25 : 20} />
              <span className={isScaled ? "text-xl" : "text-base"}>Tags</span>
            </NavLink>
            {/* <NavLink
              to="/alarms"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <RxStopwatch size={isScaled ? 25 : 20} />
              <span className={isScaled ? "text-xl" : "text-base"}>Alarms</span>
            </NavLink>
            <NavLink
              to="/tickets"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <RxIdCard size={isScaled ? 25 : 20} />
              <span className={isScaled ? "text-xl" : "text-base"}>
                Tickets
              </span>
            </NavLink> */}
            <NavLink
              to="/users"
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <FiUsers size={isScaled ? 25 : 20} />
              <span className={isScaled ? "text-xl" : "text-base"}>Users</span>
            </NavLink>
            <NavLink
              to={`${process.env.REACT_APP_COMMON_APP}`}
              className={({ isActive }) =>
                isActive
                  ? "flex items-center gap-5 p-3 w-full bg-gradient-to-r from-green to-blue font-bold"
                  : "hover:bg-black duration-300 flex items-center gap-5 w-full p-3 border-b border-b-slate last:border-b-0 bg-transparent"
              }
            >
              <RxGlobe size={20} />
              <span className={isScaled ? "text-xl" : "text-base"}>
                Back to Saudi Map
              </span>
            </NavLink>
          </ul>
          <div className="grid place-content-center">
            <BrainImg />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}
