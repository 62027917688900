import Bar from "components/charts/SmallBar";
import SmallArea from "components/charts/smallArea";
import { MdQueryStats } from "react-icons/md";
import classNames from "utilities/ClassNames";
import GaugeBox from "./GaugeBox";

import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { useEffect, useState } from "react";

export default function OneGauge({ tab }: any) {
  const [loading, setLoading] = useState(true);
  const data = tab?.configurations;
  const [allData, setAllData] = useState<any>(null);

  useEffect(() => {
    (async () => {
      if (data.k1.value.typeSelected !== "expressions") {
        const response = await getTagData([data.k1.value.item.id]);
        if (response.status === 200) {
          setAllData(response.data[0]);
        }
      } else {
        const response = await getExpressionData([data.k1.value.item.id]);
        if (response.status === 200) {
          setAllData(response.data[0]);
        }
      }
    })();
  }, [tab]);

  const [chartType, setChartType] = useState("gauge");

  function changeChart() {
    if (chartType === "gauge") {
      setChartType("area");
    } else {
      setChartType("gauge");
    }
  }

  return (
    <>
      {allData && (
        <div className="flex flex-col w-full h-full justify-center items-center">
          {/* Gauge */}
          <div className="flex relative w-full h-[90%] justify-center items-center bg-dark p-1 rounded-md mb-2">
            <div
              onClick={changeChart}
              className="cursor-pointer z-20 text-white p-1 rounded-md bg-green duration-300 absolute left-0 top-0"
            >
              <MdQueryStats size={20} />
            </div>
            {chartType === "gauge" && (
              <>
                <GaugeBox data={allData} name={allData?.name} largest />
                {!allData && (
                  <div className="w-full h-full grid place-content-center">
                    <h1>N/A</h1>
                  </div>
                )}
              </>
            )}
            {/* Area */}
            {allData && chartType === "area" && (
              <SmallArea
                item={allData}
                type={allData.tagNameIdentifier ? "tag" : "expression"}
              />
            )}
          </div>
          {allData && chartType === "area" && (
            <div className="w-[80%] mx-auto">
              <Bar
                barChartName={allData?.name}
                rawData={allData}
                type={allData.tagNameIdentifier ? "tag" : "expression"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
