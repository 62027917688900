import axios from "axios";

import { User } from "oidc-client-ts";
function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_APP}:jsx`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

const api = axios.create({
  baseURL: process.env.REACT_APP_ENERGY_API,
  validateStatus: (status: any) => {
    return status >= 200 && status < 500;
  },
});

export const API_Login = axios.create({
  baseURL: process.env.REACT_APP_COMMON_API,
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
});

api.defaults.withCredentials = true;
API_Login.defaults.withCredentials = true;

api.interceptors.request.use(
  async (config: any) => {
    //debugger;// eslint-disable-line no-use-before-define
    const user = getUser();
    const token = user?.access_token;
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: any) => {
    if (response.status === 401) {
      localStorage.removeItem("token");
      return window.dispatchEvent(new Event("storage"));
    }
    return response;
  },
  async function (error) {
    console.log(error);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.dispatchEvent(new Event("storage"));
    }
  }
);

export const baseUrl = process.env.REACT_APP_ENERGY_API;

export default api;
