import { getExpressionPastData, getTagsPastData } from "api/dashboard";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import zoomPlugin from "chartjs-plugin-zoom";
import Loader from "components/shared/Loader";
import dayjs from "dayjs";
import useApp from "hooks/useApp";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { PiFileCsv, PiFilePdf, PiFilePng } from "react-icons/pi";
import { TbZoomOutArea } from "react-icons/tb";
import classNames from "utilities/ClassNames";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  zoomPlugin
);

export default function Area({ item, title, type }: any) {
  const { isScaled } = useApp();
  const chartRef = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const [valueSet, setValueSet] = useState<number[]>();
  const [dataList, setDataList] = useState<any>();
  const [minValue, setMinValue] = useState<any>();
  const [maxValue, setMaxValue] = useState<any>();
  const [isDaily, setIsDaily] = useState(false);
  const [predictionList, setPredictionList] = useState<any>(null);
  const avgType = "D";

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (type === "tag") {
        const response: any = await getTagsPastData(
          item.id,
          7,
          isDaily ? "D" : "H"
        );
        if (response?.data && response?.status === 200) {
          const dataList = response?.data
            .filter((tag: any) => !tag.isFuture)
            .map((tag: any) => ({
              x: new Date(tag.createdOn).getTime(),
              y: tag.tagValue,
            }));
          const valuesSet = response?.data
            .filter((tag: any) => !tag.isFuture)
            .map((tag: any) => tag.tagValue);
          setDataList(dataList);
          setValueSet(valuesSet);
          const futureData = response?.data
            .filter((tag: any) => tag.isFuture)
            .map((tag: any) => ({
              x: new Date(tag.createdOn).getTime(),
              y: tag.tagValue,
            }));
          setPredictionList(futureData);
        }
      } else {
        const response: any = await getExpressionPastData(
          item.id,
          7,
          isDaily ? "D" : "H"
        );
        if (response?.data && response?.status === 200) {
          const dataList = response?.data.map((expression: any) => ({
            x: new Date(expression.createdOn).getTime(),
            y: expression.expressionValue,
          }));
          const valuesSet = response?.data.map(
            (expression: any) => expression.expressionValue
          );
          setDataList(dataList);
          setValueSet(valuesSet);
        }
      }
      setLoading(false);
    })();
  }, [isDaily]);

  useEffect(() => {
    const minValue = valueSet?.reduce(
      (a: any, b: any) => Math.min(a, b),
      Infinity
    );
    const maxValue = valueSet?.reduce(
      (a: any, b: any) => Math.max(a, b),
      -Infinity
    );
    setMinValue(minValue);
    setMaxValue(maxValue);
  }, [valueSet]);

  useEffect(() => {
    if (
      dataList &&
      dataList.length > 0 &&
      predictionList &&
      predictionList.length > 0
    ) {
      setPredictionList([dataList[dataList.length - 1], ...predictionList]);
    }
  }, [dataList]);

  const options: any = {
    animation: {
      duration: 0,
    },
    responsive: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: isScaled ? 18 : 13,
          },
        },
      },
      y: {
        grid: {
          color: "rgb(255, 255, 255, 0.3)",
        },
        ticks: {
          color: "#f1f5f9",
          font: {
            size: isScaled ? 18 : 15,
          },
        },
        min: minValue - 5,
        max: maxValue + 5,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
    plugins: {
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "nearest",
        axis: "x",
        intersect: false,
        filter: function (tooltipItem: any) {
          return tooltipItem.datasetIndex === 0;
        },
      },
      legend: {
        display: false,
      },
      interaction: {
        intersect: true,
      },
      zoom: {
        animation: {
          duration: 1000,
          easing: "easeOutCubic",
        },
        enabled: true,
        mode: "xy",
        zoom: {
          drag: {
            enabled: true,
            backgroundColor: "rgba(62, 252, 173, 0.2)",
            borderColor: "rgb(19, 146, 93)",
            borderWidth: 1,
          },
          wheel: {
            enabled: true,
            modifierKey: "ctrl",
          },
        },
        limits: {
          x: {
            min: item.minRange,
          },
        },
      },
    },
  };

  let minSetData = null;
  let maxSetData = null;
  let fullArray = null;
  if (predictionList && predictionList.length > 0) {
    fullArray = dataList.concat(predictionList);
  } else {
    fullArray = dataList;
  }

  if (minValue < item.minRange) {
    if (fullArray) {
      minSetData = fullArray?.map((set: any) => ({
        x: set.x,
        y: item.minRange,
      }));
    } else {
      minSetData = dataList?.map((set: any) => ({
        x: set.x,
        y: item.minRange,
      }));
    }
  }

  if (maxValue > item.maxRange) {
    if (fullArray) {
      maxSetData = fullArray?.map((set: any) => ({
        x: set.x,
        y: item.maxRange,
      }));
    } else {
      maxSetData = dataList?.map((set: any) => ({
        x: set.x,
        y: item.maxRange,
      }));
    }
  }

  let minSet = null;
  let maxSet = null;

  if (minSetData) {
    minSet = {
      fill: "start",
      label: "minSet",
      data: minSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  if (maxSetData) {
    maxSet = {
      fill: "end",
      label: "maxSet",
      data: maxSetData,
      borderColor: "rgb(253, 79, 105)",
      backgroundColor: "rgb(253, 79, 105,0.5)",
      lineTension: 0.3,
      pointRadius: 0,
      pointHoverRadius: 0,
      order: 2,
    };
  }

  const defaultDataSet = {
    fill: true,
    label: "Tag Value",
    data: dataList,
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)",
    lineTension: 0.3,
    pointRadius: 0,
    pointHoverRadius: 0,
    order: 1,
  };

  const futureDataSet = {
    fill: true,
    label: "Prediction Value",
    data: predictionList,
    borderColor: "rgb(219, 39, 119)",
    backgroundColor: "rgba(219, 39, 119, 0.5)",
    lineTension: 0.3,
    pointRadius: 0,
    pointHoverRadius: 0,
    order: 1,
  };

  const datasetsArray = [];
  datasetsArray.push(defaultDataSet);
  datasetsArray.push(futureDataSet);

  if (minSet) {
    datasetsArray.push(minSet);
  }

  if (maxSet) {
    datasetsArray.push(maxSet);
  }

  const data = {
    datasets: datasetsArray,
  };

  const resetZoom = () => {
    chartRef.current.resetZoom();
  };

  const zoomIn = () => {
    chartRef.current.zoom(1.1);
  };

  const zoomOut = () => {
    chartRef.current.zoom(0.9);
  };

  const downloadPng = () => {
    const link = document.createElement("a");
    link.download = item.name;
    link.href = chartRef.current.toBase64Image();
    link.click();
  };

  const downloadPdf = () => {
    const image = chartRef?.current?.canvas.toDataURL("image/jpg", 1.0);
    const pdf = new jsPDF("landscape");
    pdf.setFontSize(20);
    pdf.addImage(image, "JPEG", 15, 15, 260, 150);
    pdf.save(item.name);
  };

  const downloadCsv = () => {
    const csvData = [
      ["Date", `${title} Value`],
      ...dataList.map((item: any) => [
        dayjs(item.x).format("YYYY-MM-DDTHH:mm:ss"),
        item.y,
      ]),
    ]
      .map((e: any) => e.join(","))
      .join("\n");

    const link = document.createElement("a");
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    link.href = URL.createObjectURL(blob);
    link.download = item.name;
    link.click();
  };

  const bgColor = {
    id: `areaChart_${item.id}`,
    beforeDraw: (chart: any, option: any) => {
      const { ctx, width, height } = chart;
      ctx.save();
      ctx.fillStyle = "#111111";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  if (loading)
    return (
      <div className="w-full h-full grid place-content-center min-h-[200px]">
        <Loader />
      </div>
    );

  return (
    <div
      className={classNames(
        isScaled ? "w-[96%]" : "2xl:w-[95%]",
        "flex gap-1 w-full mx-auto justify-center items-center pt-4"
      )}
    >
      <div className="w-full">
        <Line
          ref={chartRef}
          plugins={[bgColor]}
          options={options}
          data={data}
        />
      </div>
      <div className="flex flex-col text-white/50 gap-2 -mt-3">
        <div
          className="cursor-pointer hover:text-green duration-300"
          onClick={downloadCsv}
        >
          <PiFileCsv size={isScaled ? 25 : 20} />
        </div>
        <div
          className="cursor-pointer hover:text-green duration-300"
          onClick={downloadPdf}
        >
          <PiFilePdf size={isScaled ? 25 : 20} />
        </div>
        <div
          className="cursor-pointer hover:text-green duration-300"
          onClick={downloadPng}
        >
          <PiFilePng size={isScaled ? 25 : 20} />
        </div>
        <div
          className="cursor-pointer hover:text-green duration-300"
          onClick={zoomIn}
        >
          <FiZoomIn size={isScaled ? 25 : 20} />
        </div>
        <div
          className="cursor-pointer hover:text-green duration-300"
          onClick={zoomOut}
        >
          <FiZoomOut size={isScaled ? 25 : 20} />
        </div>
        <div
          className="cursor-pointer hover:text-green duration-300"
          onClick={resetZoom}
        >
          <TbZoomOutArea size={isScaled ? 25 : 20} />
        </div>
        <div
          className={classNames(
            isDaily ? "font-bold text-green" : "text-white/50",
            "cursor-pointer duration-300 leading-3 text-center mt-3"
          )}
          onClick={() => setIsDaily(true)}
        >
          <span className={classNames(isScaled ? "text-xl" : "")}>D</span>
        </div>
        <div
          className={classNames(
            !isDaily ? "font-bold text-green" : "text-white/50",
            "cursor-pointer duration-300 leading-3 text-center"
          )}
          onClick={() => setIsDaily(false)}
        >
          <span className={classNames(isScaled ? "text-xl" : "")}>H</span>
        </div>
      </div>
    </div>
  );
}
