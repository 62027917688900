import { useState, useCallback, useEffect } from "react";
import api from "utilities/Axios";

export const postUnityData = (type: any, id: any, data: any, deleteId: any) => {
  if (type === "bubble") {
    if (deleteId) {
      return api.get("/Unity/DeleteBubble" + `?id=${deleteId}`);
    }
    if (!id) {
      return api.post("/Unity/CreateBubble", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    return api.post("/Unity/UpdateBubble", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  } else if (type === "note") {
    if (deleteId) {
      return api.get("/Unity/DeleteNote" + `?id=${deleteId}`);
    }
    if (!id) {
      return api.post("/Unity/CreateNote", data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    return api.post("/Unity/UpdateNote", data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  return Promise.reject();
};

export const getUnityData = (
  type: "bubble" | "note",
  id: number | null = null
) => {
  if (type === "bubble") {
    if (id) {
      return api.get("/Unity/GetBubble" + `?id=${id}`);
    }
    return api.get("/Unity/GetAllBubbles");
  } else if (type === "note") {
    if (id) {
      return api.get("/Unity/GetNote" + `?id=${id}`);
    }
    return api.get("/Unity/GetAllNotes");
  }

  return Promise.reject();
};

const ERROR_MESSAGE: any = {
  invalid_grant: "Invalid Credentials",
  code_401: "We are sorry, you are not authorized.",
  InvalidToken: "InvalidToken",
  email_not_confirmed: "email_not_confirmed",
  admin_approval_pending: "Your account is in pending for Admin approval.",
};

const objectErrorsArray = (errors: any) => {
  const errorsArr: string[] = [];
  errors.forEach((err: string) => {
    errorsArr.push(err);
  });
  return errorsArr;
};

const identityErrorsArray = (errors: any) => {
  const errorsArr: string[] = [];
  errors.forEach((err: any) => {
    if (ERROR_MESSAGE[err.code]) {
      errorsArr.push(ERROR_MESSAGE[err.code]);
    } else {
      errorsArr.push(err.description);
    }
  });

  return errorsArr;
};

export const b64toBlob = (b64Data: any, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any[] = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const _byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(_byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const errorHandling = (error: any) => {
  if (!error) {
    return ["Please try again later."];
  }
  if (error.response?.status === 403) {
    return ["You are not authorized user."];
  }

  if (error?.response && error.response?.data && error.response.data?.ErrorId) {
    let errors: string[] = [];
    errors = [`Error Id: ${error.response.data.ErrorId}`];
    return errors;
  }

  if (error.response && error.response.data && error.response.data.errors) {
    let errors: string[] = [];
    errors = objectErrorsArray(Object.values(error.response.data.errors));
    return errors;
  }
  if (
    error.response &&
    error.response.data &&
    error.response.data.identityErrors
  ) {
    let errors: string[] = [];
    errors = identityErrorsArray(
      Object.values(error.response.data.identityErrors)
    );
    return errors;
  }

  if (
    error.response &&
    error.response.data &&
    error.response.data.Validations
  ) {
    return error.response.data.Validations;
  }

  const resMessage =
    (error.response && error.response.data && error.response.data?.error) ||
    (error.response && error.response.data) ||
    error.response.data?.error ||
    error.errors ||
    error.toString();

  if (ERROR_MESSAGE[resMessage]) {
    return [ERROR_MESSAGE[resMessage]];
  } else if (
    error.response &&
    error.response.data &&
    error.response.data?.error_description
  ) {
    return [error.response.data.error_description];
  } else {
    return [resMessage];
  }
};

export const useGet = <T,>(
  type: "bubble" | "note",
  id: number | null,
  refresh: boolean
) => {
  const [data, setData] = useState<T | null>(null);
  const [dataCollection, setDataCollection] = useState<T[] | null>(null);
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(true);

  const errorHandler = useCallback((error: any) => {
    setData(null);
    setDataCollection([]);
    setMessage(errorHandling(error));
    setError(true);
    setLoader(false);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoader(true); // set loading to true to indicate that the data is being fetched

      getUnityData(type, id).then(
        (response: any) => {
          if (response && response.data) {
            if (Array.isArray(response.data)) {
              const responseData: Array<T> = response.data;
              setDataCollection(responseData);
            } else {
              const responseData: T = response.data;
              setData(responseData);
            }
            setError(false);
            setMessage([]);
          }
          setLoader(false);
        },
        (responseError) => {
          errorHandler(responseError);
        }
      );
    } catch (error: any) {
      errorHandler(error);
    }
  }, [type, id, errorHandler]);

  useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh, fetchData]);

  return { data, dataCollection, loader, message, error };
};

export const useUpdate = <T,>(
  type: "bubble" | "note",
  post: boolean,
  id: number | null,
  editMode: boolean,
  deleteId: number,
  values: any
) => {
  const [data, setData] = useState(false);
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);

  const errorHandler = useCallback((error: any) => {
    setData(false);
    setMessage(errorHandling(error));
    setError(true);
    setLoader(false);
  }, []);

  const postData = useCallback(async () => {
    try {
      setLoader(true); // set loading to true to indicate that the data is being fetched
      let formValues;
      if (type === "note") {
        if (!deleteId) {
          const formData = new FormData();
          if (values.id) formData.append("unityNote.Model.id", values.id);
          formData.append("unityNote.Model.header", values.header);
          if (values.note) formData.append("unityNote.Model.note", values.note);
          formData.append("unityNote.Model.x", values.x);
          formData.append("unityNote.Model.y", values.y);
          formData.append("unityNote.Model.z", values.z);
          if (values?.files) {
            values?.files.forEach((file: any, index: any) => {
              formData.append(
                `unityNote.Model.files[${index}].uniqueId`,
                file.uniqueId
              );
            });
          }
          values?.uploadedFiles.forEach((file: any, index: any) => {
            formData.append(`unityNote.files[${index}].file`, file.file);
            formData.append(
              `unityNote.files[${index}].fileName`,
              file.fileName
            );
          });
          formValues = formData;
        }
      } else {
        formValues = values;
      }
      postUnityData(type, id, formValues, deleteId).then(
        (response: any) => {
          if (!deleteId) {
            if (editMode) setMessage(["Configured successfully."]);
            else {
              setMessage(["Created successfully."]);
            }
          }

          setError(false);
          setLoader(false);
          setData(true);
        },
        (responseError) => {
          errorHandler(responseError);
        }
      );
    } catch (error: any) {
      errorHandler(error);
    }
  }, [type, values, deleteId, editMode, errorHandler]);

  useEffect(() => {
    if (post && values) {
      postData();
    }
  }, [postData, post]);

  useEffect(() => {
    if (post && deleteId) {
      postData();
    }
  }, [postData, post, deleteId]);

  return { data, loader, message, error };
};
