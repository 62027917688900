import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export const SignalRContext = createContext<any | null>(null);

export const SignalRProvider = ({ children }: { children: any }) => {
  const token = localStorage.getItem("token");
  const [connection, setConnection] = useState<null | HubConnection>(null);

  useEffect(() => {
    (async () => {
      if (token) {
        const response: any = await (await fetch("./config.json")).json();
        const connectionSignalR = new HubConnectionBuilder()
          .withUrl(`${response.BASE_URL}/MainHub`, {
            accessTokenFactory: () => token,
          })
          .build();
        connectionSignalR
          .start()
          .then(function () {})
          .catch((error) => {
            console.error(error.message);
          });
        setConnection(connectionSignalR);
      }
    })();
  }, [token]);

  const FileUploadHandler = useCallback(
    (data: any) => {
      if (connection) {
        connection.on("GenericFunc", function (e) {
          data(e);
        });
      }
    },
    [connection]
  );

  const unityDataHandler = useCallback(
    (data: any) => {
      if (connection) {
        connection.on("TagValueChanged", function () {
          data();
        });
      }
    },
    [connection]
  );

  return (
    <SignalRContext.Provider value={{ FileUploadHandler, unityDataHandler }}>
      {children}
    </SignalRContext.Provider>
  );
};

export default function useSignalR() {
  return useContext(SignalRContext);
}
