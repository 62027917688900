/* eslint-disable @typescript-eslint/no-unused-expressions */
import api, { API_Login } from "utilities/Axios";

// export const getUser = () => {
// 	return api.get("/user/get");
// };

export const getUserInfo = () => {
  return API_Login.get("/api/auth/info", { withCredentials: true });
};

export const signOut = () => {
  return API_Login.get("/api/auth/sign-out", { withCredentials: true });
};

export const callbacklogin = () => {
  return API_Login.get("api/auth/external-auth-callback", {
    withCredentials: true,
  });
};

export const getAvatar = () => {
  return api.get("/user/getavatar", { withCredentials: true });
};

export const updateProfile = (data: any) => {
  return api.post("/user/edit", data, { withCredentials: true });
};

export const resetPassword = (data: any) => {
  const details = {
    oldPassword: data.current_password,
    newPassword: data.new_password,
  };
  return api.post("/auth/resetpasswordfromprofile", details);
};
