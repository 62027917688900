import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { Doughnut } from "react-chartjs-2";
import classNames from "utilities/ClassNames";
import {
  calculateData,
  colorCodes,
  drawLargestSplits,
  drawLargestSplitsHidden,
} from "./Helpers";

const theme = {
  fontFamily: "Cera-Pro",
  primary: "#65F4CD",
  text_grey: "white",
  "teal-color": "#00999f",
  secondary: "#4799E9",
  lightBlue: "#3f5173",
  red: "#f95460",
  orange: "#ffb554",
  danger: "#dc3545",
  grey: "#acb2c0",
  bodyBg: "#232323",
  bodyColor: "#434343",
  themeGradient:
    "linear-gradient(180deg, rgba(90, 85, 202, 0.6) 0%, rgba(90, 85, 202, 0.9) 22.58%, #5a55ca 100%)",
};

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const outOfThresholdRange = {
  BG: [colorCodes.white],
  value: [100],
  shadowBG: ["transparent"],
};

const Gauge = ({ data, name, small, large }: any) => {
  const [valueShadowBG, setValueShadowBG] = useState(
    outOfThresholdRange.shadowBG
  );
  const [valueDataSet, setValueDataSet] = useState(outOfThresholdRange.value);
  const [valueBG, setValueBG] = useState(outOfThresholdRange.BG);

  const [operatingDataSet, setOperatingDataSet] = useState([]);
  const [operatingBG, setOperatingBG] = useState([]);

  const [total, setTotal] = useState(0);
  const [disabled, setDisable] = useState(false);
  const [inRange, setInRange] = useState(true);

  const { minValue, maxValue, formattedValue, minRange, maxRange } = data;

  const [gradient, setGradient] = useState(null);

  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (data) {
      const calculatedData = calculateData(data, 100);
      setTotal(calculatedData.totalValue);
      setInRange(calculatedData.inRange);

      setOperatingDataSet(calculatedData.rangeSet.data);
      setOperatingBG(calculatedData.rangeSet.bg);

      if (!calculatedData.defaultDataSet) {
        setValueBG(calculatedData.valueSet.bg);
        setValueShadowBG(calculatedData.valueSet.shadowBg);
        setValueDataSet(calculatedData.valueSet.data);
      }

      setDisable(calculatedData.disable);
    }
  }, []);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart) {
      const ctx = chart?.ctx;
      const gradientSegment = ctx.createLinearGradient(0, 0, 250, 0);
      const endBGData = valueDataSet[0] / total;

      const gradient = { start: theme.primary, end: theme.secondary };

      if (!inRange) {
        gradient.start = colorCodes.orange;
        gradient.end = colorCodes.red;
      }
      gradientSegment.addColorStop(0, gradient.start);
      gradientSegment.addColorStop(endBGData, gradient.end);

      setGradient(gradientSegment);
    }
  }, [valueBG, valueDataSet]);

  let bgGradient = [gradient];
  let borderBg: any = [gradient];
  if (valueBG.length === 2) {
    bgGradient = [gradient, valueBG[1]];
    borderBg = [gradient, "transparent"];
  } else if (valueBG.length === 1 && valueBG[0] === colorCodes.white) {
    bgGradient = valueBG;
    borderBg = ["transparent"];
  }

  // define gauge datasets - starts
  const gaugeData: any = {
    labels: [],
    datasets: [
      {
        data: valueDataSet,
        backgroundColor: bgGradient,
        hoverBackgroundColor: bgGradient,
        borderColor: borderBg,
        weight: 30,
        borderWidth: [5, 0],
        borderRadius: [5, 0],
        datalabels: {
          labels: {
            title: null,
          },
        },
        spacing: 0,
      },
      {
        data: [100],
        backgroundColor: ["transparent"],
        hoverBackgroundColor: ["transparent"],
        borderColor: ["transparent"],
        weight: 15,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
      {
        data: operatingDataSet,
        backgroundColor: operatingBG,
        hoverBackgroundColor: operatingBG,
        borderColor: "transparent",
        weight: 10,
        datalabels: {
          labels: {
            title: null,
          },
        },
      },
    ],
  };
  // define gauge datasets - ends

  const fontSize = 12;

  const gaugeNeedle = useCallback(() => {
    return {
      id: `gaugeChart_${data.id}`,
      beforeDraw(chart: any, args: any, options: any) {
        drawLargestSplitsHidden(chart, args, options, {
          formattedValue,
          minRange,
          maxRange,
          minValue,
          maxValue,
          total,
        });
      },
      afterDraw(chart: any, args: any, options: any) {
        drawLargestSplits(chart, args, options, {
          formattedValue,
          minRange,
          maxRange,
          minValue,
          maxValue,
          total,
        });
      },
    };
  }, [total]);

  return (
    <div className={classNames(disabled ? "opacity-50" : "")}>
      <div className="flex pb-20 2xl:pb-10 justify-center items-center xl:w-[440px] xl:h-[240px] 2xl:w-[450px] 2xl:h-[250px]">
        {total ? (
          operatingDataSet && (
            <div
              className={classNames(
                small
                  ? "2xl:pb-20 pb-10 2xl:-mt-0 -mt-16"
                  : "bg-transparent 2xl:-mt-24 -mt-8",
                "relative w-full pt-20"
              )}
            >
              <Doughnut
                ref={chartRef}
                id={`gaugeChart_${data.id}`}
                key={`gaugeChart_${name}_${data.id}`}
                data={gaugeData}
                plugins={[gaugeNeedle()]}
                options={{
                  plugins: {
                    // gradient,
                    datalabels: {
                      color: function (context) {
                        return "white";
                      },
                      display: true,
                      font: {
                        size: fontSize,
                      },
                      anchor: "end",
                      padding: {
                        // top: 20
                      },
                    },
                    tooltip: {
                      yAlign: "bottom",
                      enabled: true,
                      displayColors: false,
                      callbacks: {
                        label: function () {
                          return `${formattedValue}`;
                        },
                      },
                    },
                  },
                  rotation: -74,
                  circumference: 150,
                  cutout: "60%",
                  responsive: true,
                  animation: {
                    duration: 0,
                  },
                  aspectRatio: 1.5,
                }}
              />
              <div
                className={classNames(
                  small
                    ? "-bottom-4 2xl:bottom-9 2xl:pb-1"
                    : "pb-10 lg:pb-0 -bottom-14 lg:-bottom-5 2xl:-bottom-2",
                  "absolute w-full"
                )}
              >
                <h4
                  className={classNames(
                    small ? "text-lg " : "text-2xl",
                    "text-center font-bold"
                  )}
                >
                  {data.uom === "%"
                    ? `${Math.trunc(parseInt(formattedValue))}%` || "-"
                    : formattedValue || "-"}
                </h4>
                <h4 className="text-center text-green font-bold text-3xl">
                  {name}
                </h4>
              </div>
            </div>
          )
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center gap-5">
            <p>Range not available</p>
            <h4 className="text-center font-weight-bold">
              {formattedValue || "-"}
            </h4>
            <h4 className="text-center color-primary">{name}</h4>
          </div>
        )}
      </div>
    </div>
  );
};

// export default Gauge;
export default React.memo(Gauge);
