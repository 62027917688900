import Bar from "components/charts/SmallBar";
import SmallArea from "components/charts/GaugeBarArea";
import { MdQueryStats } from "react-icons/md";
import classNames from "utilities/ClassNames";
import GaugeBox from "./GaugeBox";

import { getExpressionData } from "api/expression";
import { getTagData } from "api/tags";
import { useEffect, useState } from "react";

export default function GaugeBar({ tab }: any) {
  const [loading, setLoading] = useState(true);
  const { k1, k2, k3, k4 } = tab.configurations;

  const [k1Data, setK1Data] = useState<any>(null);
  const [k2Data, setK2Data] = useState<any>(null);
  const [k3Data, setK3Data] = useState<any>(null);
  const [k4Data, setK4Data] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    (async () => {
      // k1
      if (k1?.value?.item?.tagNameIdentifier && k1?.value?.item?.id) {
        const response = await getTagData([k1.value.item.id]);
        if (response.status === 200) {
          setK1Data(response.data[0]);
        }
      } else if (k1.value.item.id) {
        const response = await getExpressionData([k1.value.item.id]);
        if (response.status === 200) {
          setK1Data(response.data[0]);
        }
      }
      // k2
      if (k2?.value?.item?.tagNameIdentifier && k2?.value?.item?.id) {
        const response = await getTagData([k2.value.item.id]);
        if (response.status === 200) {
          setK2Data(response.data[0]);
        }
      } else if (k2.value.item.id) {
        const response = await getExpressionData([k2.value.item.id]);
        if (response.status === 200) {
          setK2Data(response.data[0]);
        }
      }
      // k3
      if (k3?.value?.item?.tagNameIdentifier && k3?.value?.item?.id) {
        const response = await getTagData([k3.value.item.id]);
        if (response.status === 200) {
          setK3Data(response.data[0]);
        }
      } else if (k3.value.item.id) {
        const response = await getExpressionData([k3.value.item.id]);
        if (response.status === 200) {
          setK3Data(response.data[0]);
        }
      }
      // k4
      if (k4?.value?.item?.tagNameIdentifier && k4?.value?.item?.id) {
        const response = await getTagData([k4.value.item.id]);
        if (response.status === 200) {
          setK4Data(response.data[0]);
        }
      } else if (k4.value.item.id) {
        const response = await getExpressionData([k4.value.item.id]);
        if (response.status === 200) {
          setK4Data(response.data[0]);
        }
      }
    })();
    setLoading(false);
  }, []);

  const [current, setCurrent] = useState(0);
  const [chartType, setChartType] = useState("gauge");

  function changeChart() {
    if (chartType === "gauge") {
      setChartType("area");
    } else {
      setChartType("gauge");
    }
  }

  return (
    <>
      {tab.configurations && (
        <div className="flex flex-col w-full h-full justify-center items-center">
          {/* Gauge */}
          <div
            className={classNames(
              chartType === "area" ? "pt-0" : "pt-3",
              "flex relative w-full h-[60%] justify-center bg-dark p-1 rounded-md mb-2"
            )}
          >
            <div
              onClick={changeChart}
              className="cursor-pointer z-20 text-white p-1 rounded-md bg-green duration-300 absolute left-0 top-0"
            >
              <MdQueryStats size={20} />
            </div>
            {chartType === "gauge" && (
              <>
                {(k1Data || k2Data || k3Data || k3Data) && (
                  <>
                    {current === 0 && k1Data && (
                      <GaugeBox data={k1Data} name={k1Data?.name} />
                    )}
                    {current === 1 && k2Data && (
                      <GaugeBox data={k2Data} name={k2Data?.name} />
                    )}
                    {current === 2 && k3Data && (
                      <GaugeBox data={k3Data} name={k3Data?.name} />
                    )}
                    {current === 3 && k4Data && (
                      <GaugeBox data={k4Data} name={k4Data?.name} />
                    )}
                  </>
                )}
                {!k1Data && !k2Data && !k3Data && !k3Data && !loading && (
                  <div className="w-full h-full grid place-content-center">
                    <h1></h1>
                  </div>
                )}
              </>
            )}
            {/* Area */}
            {chartType === "area" && (
              <>
                {current === 0 && k1Data && (
                  <SmallArea
                    item={k1Data}
                    type={k1Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                )}
                {current === 1 && k2Data && (
                  <SmallArea
                    item={k2Data}
                    type={k2Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                )}
                {current === 2 && k3Data && (
                  <SmallArea
                    item={k3Data}
                    type={k3Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                )}
                {current === 3 && k4Data && (
                  <SmallArea
                    item={k4Data}
                    type={k4Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                )}
              </>
            )}
          </div>

          {(k1Data || k2Data || k3Data || k3Data) && (
            <div className="h-[40%] grid grid-cols-2 grid-rows-2 gap-1 w-full">
              {k1Data && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(0);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={k1Data?.name}
                    rawData={k1Data}
                    type={k1Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
              {k2Data && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(1);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={k2Data?.name}
                    rawData={k2Data}
                    type={k2Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
              {k3Data && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(2);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={k3Data?.name}
                    rawData={k3Data}
                    type={k3Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
              {k4Data && (
                <div
                  className="bg-dark rounded-md py-1 px-2 cursor-pointer"
                  onClick={() => {
                    setCurrent(3);
                    setChartType("gauge");
                  }}
                >
                  <Bar
                    barChartName={k4Data?.name}
                    rawData={k4Data}
                    type={k4Data.tagNameIdentifier ? "tag" : "expression"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}
