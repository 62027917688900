const TextOverflow = (text: string, letters: number) => {
  if (text.toString().length > letters) {
    const slicedText = text.toString().slice(0, letters);
    return (
      <>
        {slicedText}
        <span className="text-xs">...</span>
      </>
    );
  } else {
    return text;
  }
};

export default TextOverflow;
