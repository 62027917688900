import MediumBar from "components/charts/MediumBar";
import useApp from "hooks/useApp";
import { usePins } from "hooks/usePins";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function Pin({ data, unityPin }: any) {
  const { isScaled } = useApp();
  const { unPin } = usePins();

  return (
    <div
      className={classNames(
        isScaled ? "h-[130px] min-w-[350px]" : "h-[120px] min-w-[300px]",
        "rounded-md p-0.5 gradient  2xl:w-full 2xl:min-w-[150px] flex items-center"
      )}
    >
      <div className="w-full rounded-md h-full bg-black px-2 pb-5 flex flex-col items-center">
        <div
          className="ml-auto py-1 flex cursor-pointer"
          onClick={() => unPin(data.data[0].id, null, unityPin ? 2 : 1)}
        >
          <FiX size={20} />
        </div>
        <MediumBar data={data.data[0]} barChartName="Total Production" />
      </div>
    </div>
  );
}
