import { getEquipmentsByUnitId } from "api/equipments";
import {
  deleteTag as apiDeleteTag,
  resetTag as apiResetTag,
  getTagsByEquipmentsId,
  updateTag,
} from "api/tags";
import { getTrains as apiGetTrains } from "api/trains";
import { getUnitsByTrainId } from "api/units";
import { useEffect, useState } from "react";
import Equipments from "./Equipments";
import Trains from "./Trains";
import Units from "./Units";
import Tags from "./tags";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";

export default function AllAssets() {
  const { isScaled } = useApp();
  const [refresh, setRefresh] = useState(false);

  // show status
  const [showUnits, setShowUnits] = useState(false);
  const [showEquipments, setShowEquipments] = useState(false);
  const [showTags, setShowTags] = useState(false);

  //   selected
  const [selectedTrain, setSelectedTrain] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);

  //   data
  const [trains, setTrains] = useState<any>(null);
  const [units, setUnits] = useState<any>(null);
  const [equipments, setEquipments] = useState<any>(null);
  const [tagsByEquipment, setTagsByEquipment] = useState<any>(null);

  const getTrains = async () => {
    const response: any = await apiGetTrains();
    if (response.status === 200) {
      setTrains(response.data);
    }
  };

  const getUnits = async () => {
    setShowEquipments(false);
    setShowTags(false);
    const response: any = await getUnitsByTrainId(selectedTrain.id);
    if (response.status === 200) {
      setUnits(response.data);
    }
  };

  const getEquipments = async () => {
    const response: any = await getEquipmentsByUnitId(selectedUnit.id);
    if (response.status === 200) {
      setEquipments(response.data);
    }
  };

  const getTagsById = async () => {
    const response: any = await getTagsByEquipmentsId(selectedEquipment.id);
    if (response.status === 200) {
      setTagsByEquipment(response.data);
    }
  };

  useEffect(() => {
    getTrains();
  }, []);

  useEffect(() => {
    if (showUnits) getUnits();
  }, [selectedTrain]);

  useEffect(() => {
    if (showEquipments) getEquipments();
  }, [selectedUnit]);

  useEffect(() => {
    if (showTags) getTagsById();
  }, [selectedEquipment]);

  useEffect(() => {
    if (refresh) {
      getTagsById();
    }
  }, [refresh]);

  useEffect(() => {
    if (refresh) {
      const timer = setTimeout(() => {
        setRefresh(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [refresh]);

  const editTag = async (tag: any) => {
    const response: any = await updateTag(tag);
    if (response.status === 200) {
      setRefresh(true);
      return { message: "success" };
    }

    if (response.status === 400) {
      if (response.data.Validations) {
        return { message: "failure", value: response.data.Validations };
      } else {
        return { message: "failure", value: response.data.title };
      }
    }
  };

  const resetTag = async (id: any) => {
    const response: any = await apiResetTag(id);
    if (response.status === 200) {
      setRefresh(true);
      return { message: "success" };
    }
  };

  const deleteTag = async (id: any) => {
    const response: any = await apiDeleteTag(id);
    if (response.status === 200) {
      setRefresh(true);
      return { message: "success" };
    }
  };

  return (
    <div className="p-3 flex flex-col gap-2 h-[85vh]">
      <div className="h-[50%]">
        <div className="w-full h-full grid grid-cols-3 grid-rows-1 gap-2">
          <Trains
            setShowUnits={setShowUnits}
            setSelectedTrain={setSelectedTrain}
            data={trains}
            getTrains={getTrains}
          />
          {showUnits && (
            <Units
              data={units}
              setShowEquipments={setShowEquipments}
              setSelectedUnit={setSelectedUnit}
              getUnits={getUnits}
              selectedTrain={selectedTrain}
              trains={trains}
            />
          )}
          {showEquipments && (
            <Equipments
              data={equipments}
              setSelectedEquipment={setSelectedEquipment}
              setShowTags={setShowTags}
              getEquipments={getEquipments}
              trains={trains}
              units={units}
              selectedTrain={selectedTrain}
              selectedUnit={selectedUnit}
            />
          )}
        </div>
      </div>
      <div className={classNames(isScaled ? "mt-[210px]" : "mt-0", "h-[50%]")}>
        {showTags && (
          <Tags
            data={tagsByEquipment}
            setRefresh={setRefresh}
            selectedEquipment={selectedEquipment}
            getTagsById={getTagsById}
            editTag={editTag}
            resetTag={resetTag}
            deleteTag={deleteTag}
          />
        )}
      </div>
    </div>
  );
}
