import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FiMoreVertical } from "react-icons/fi";
import { LuTimerReset, LuTrash2 } from "react-icons/lu";
import {
  BsFillStarFill,
  BsPersonFillCheck,
  BsRecordFill,
  BsUpload,
} from "react-icons/bs";
import { useEffect, useState } from "react";
import useApp from "hooks/useApp";
import {
  UpdateUserRole,
  getAllUsers,
  getAllUsersForAdmin,
  getRoles,
} from "api/users";

export default function RoleDropdown({
  id,
  currentrole,
  rolesData,
  refreshhandler,
}: any) {
  let curr = currentrole;

  const [data, setData] = useState<any>(null);
  const [pendinglist, setpendinglist] = useState<any[] | null>(null);
  const [rawData, setRawData] = useState<any>(null);
  const { userRole } = useApp();

  const rolelist: any = [];
  if (rolesData) {
    rolesData.map((role: any) => {
      rolelist.push(role);
    });
  }

  // const getData = async () => {
  //   if (isAdmin) {
  //     const response: any = await getAllUsersForAdmin();
  //     if (response.status === 200) {
  //       setRawData(response.data);
  //     }
  //   } else {
  //     const response: any = await getAllUsers();
  //     if (response.status === 200) {
  //       setRawData(response.data);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   (async()=>{
  //     const response:any = await getRoles();
  //     if(response && response.status===200){
  //       const roles=response.data;
  //       console.log(roles);
  //     }
  //   })();
  // }, []);

  // // useEffect(() => {
  // //   if (role?.toLowerCase() === "admin") {
  // //     setEditable(true);
  // //   }
  // // }, [role]);

  // const refreshData = async () => {
  //   if (isAdmin) {
  //     const response: any = await getAllUsersForAdmin();
  //     if (response.status === 200) {
  //       setRawData(response.data);
  //     }
  //   } else {
  //     const response: any = await getAllUsers();
  //     if (response.status === 200) {
  //       setRawData(response.data);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  // useEffect(() => {
  //   if (rawData && rawData.length) {
  //     setData(rawData);
  //   }
  // }, [rawData]);
  const Capitalize = (str: any) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleChange = (role: any) => {
    const text = "Are you sure you want to change the role ?";
    if (window.confirm(text) === true) {
      return UpdateUserRole({ userId: id, roleId: rolelist[role].id }).then(
        (response: any) => {
          curr = Capitalize(rolelist[role].name);
          refreshhandler();
        },
        (error) => {}
      );
    }
  };

  return userRole === "admin" ? (
    <DropdownMenu>
      {
        <DropdownMenuTrigger asChild>
          <div
            style={{
              border: "groove 2px",
              borderRadius: "3px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            {curr ? curr : "Unassigned"}
          </div>
        </DropdownMenuTrigger>
      }
      <DropdownMenuContent align="end" className="bg-dark border-slate">
        <DropdownMenuItem
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => handleChange(0)}
        >
          <div style={{ display: "flex", gap: "5px" }}>
            {Capitalize(rolelist[0]?.name)}
            {Capitalize(rolelist[0]?.name) === curr && (
              <BsPersonFillCheck
                style={{ marginTop: "3px" }}
              ></BsPersonFillCheck>
            )}
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex gap-2 items-center cursor-pointer"
          onClick={() => handleChange(1)}
        >
          <div style={{ display: "flex", gap: "5px" }}>
            {Capitalize(rolelist[1]?.name)}
            {Capitalize(rolelist[1]?.name) === curr && (
              <BsPersonFillCheck
                style={{ marginTop: "3px" }}
              ></BsPersonFillCheck>
            )}
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    curr
  );
}
