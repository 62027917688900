import Loader from "components/shared/Loader";
import React from "react";
import TextOverflow from "utilities/TextOverflow";
import { useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import Chart1 from "../../../NewDashboard/Chart1";
import Chart2 from "../../../NewDashboard/Chart2";
import Chart3 from "../../../NewDashboard/Chart3";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

const Large = ({ tab }: any) => {
  const { isScaled } = useApp();
  const data = tab.configurations;
  const [k1, setK1] = useState<any>(null);
  const [k2, setK2] = useState<any>(null);
  const [k3, setK3] = useState<any>(null);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    (async () => {
      // K1
      if (data.k1.id !== "") {
        if (data.k1.type === "tags") {
          const k1Res = await getTagData([data.k1.id]);
          if (k1Res.status === 200) {
            setK1(k1Res.data[0]);
          }
        } else {
          const k1Res = await getExpressionData([data.k1.id]);
          if (k1Res.status === 200) {
            setK1(k1Res.data[0]);
          }
        }
      }
      // K2
      if (data.k2.id !== "") {
        if (data.k2.type === "tags") {
          const k2Res = await getTagData([data.k2.id]);
          if (k2Res.status === 200) {
            setK2(k2Res.data[0]);
          }
        } else {
          const k2Res = await getExpressionData([data.k2.id]);
          if (k2Res.status === 200) {
            setK2(k2Res.data[0]);
          }
        }
      }
      // K3
      if (data.k3.id !== "") {
        if (data.k3.type === "tags") {
          const k3Res = await getTagData([data.k3.id]);
          if (k3Res.status === 200) {
            setK3(k3Res.data[0]);
          }
        } else {
          const k3Res = await getExpressionData([data.k3.id]);
          if (k3Res.status === 200) {
            setK3(k3Res.data[0]);
          }
        }
      }
    })();
  }, []);

  return (
    <>
      {!data && (
        <div className="w-full h-full grid place-content-center">
          <Loader />
        </div>
      )}
      <div className="w-ful h-full flex gap-3 -mt-3">
        <div className="flex w-[400px] flex-col gap-3">
          {/* KPI 1 */}
          {k1 && (
            <div
              className="rounded-md bg-dark p-3 flex items-center justify-center cursor-pointer h-full"
              onClick={() => setCurrent(0)}
            >
              <div className="flex flex-col gap-1">
                <p
                  className={classNames(
                    isScaled ? "text-2xl" : "text-xl",
                    "capitalize font-bold text-center whitespace-nowrap text-green"
                  )}
                >
                  {k1.name}
                </p>
                <div className="flex gap-1 justify-center">
                  {k1.value ? (
                    <p className="text-white font-semibold text-5xl">
                      {k1?.uom === "%"
                        ? TextOverflow(k1.value.toFixed(2), 7)
                        : TextOverflow(k1.value.toFixed(2), 7)}
                    </p>
                  ) : (
                    <p className="text-white font-semibold text-5xl">
                      {TextOverflow(k1.formattedValue, 7)}
                    </p>
                  )}
                  {k1.value && (
                    <p className="text-white font-semibold text-lg self-end">
                      {k1?.uom}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* KPI 2 */}
          {k2 && (
            <div
              className="rounded-md bg-dark p-3 flex items-center justify-center cursor-pointer h-full"
              onClick={() => setCurrent(1)}
            >
              <div className="flex flex-col gap-1">
                <p
                  className={classNames(
                    isScaled ? "text-2xl" : "text-xl",
                    "capitalize font-bold text-center whitespace-nowrap text-green"
                  )}
                >
                  {k2.name}
                </p>
                <div className="flex gap-1 justify-center">
                  {k2.value ? (
                    <p className="text-white font-semibold text-5xl">
                      {k2?.uom === "%"
                        ? TextOverflow(k2.value.toFixed(2), 7)
                        : TextOverflow(k2.value.toFixed(2), 7)}
                    </p>
                  ) : (
                    <p className="text-white font-semibold text-5xl">
                      {TextOverflow(k2.formattedValue, 7)}
                    </p>
                  )}
                  {k2.value && (
                    <p className="text-white font-semibold text-lg self-end">
                      {k2?.uom}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* KPI 3 */}
          {k3 && (
            <div
              className="rounded-md bg-dark p-3 flex items-center justify-center cursor-pointer h-full"
              onClick={() => setCurrent(2)}
            >
              <div className="flex flex-col gap-1">
                <p
                  className={classNames(
                    isScaled ? "text-2xl" : "text-xl",
                    "capitalize font-bold text-center whitespace-nowrap text-green"
                  )}
                >
                  {k3.name}
                </p>
                <div className="flex gap-1 justify-center">
                  {k3.value ? (
                    <p className="text-white font-semibold text-5xl">
                      {k3?.uom === "%"
                        ? TextOverflow(k3.value.toFixed(2), 7)
                        : TextOverflow(k3.value.toFixed(2), 7)}
                    </p>
                  ) : (
                    <p className="text-white font-semibold text-5xl">
                      {TextOverflow(k3.formattedValue, 7)}
                    </p>
                  )}
                  {k3.value && (
                    <p className="text-white font-semibold text-lg self-end">
                      {k3?.uom}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {current === 0 && k1 && (
          <div className="w-full flex justify-center items-center">
            <Chart1 data={k1} />
          </div>
        )}
        {current === 1 && k2 && (
          <div className="w-full flex justify-center items-center">
            <Chart2 data={k2} />{" "}
          </div>
        )}
        {current === 2 && k3 && (
          <div className="w-full flex justify-center items-center">
            <Chart3 data={k3} />{" "}
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Large);
