import { createContext } from "react";
export interface IBubbleData {
  id?: number;
  x: number;
  y: number;
  z: number;

  unitId: number | null;
  equipmentId: number | null;

  name: string | null;

  color: number | null;
  size: number | null;
}

export interface INote {
  id?: number;
  x: number;
  y: number;
  z: number;
  header?: string;
  note?: string;
  files?: {
    uniqueId: string;
    fileName: string;
  }[];
  uploadedFiles?: {
    fileName: string;
    file: any;
  }[];
}

export interface IBubble {
  unitId: null | number;
  equipmentId: null | number;
  prefabId?: number;
  prefabParentId?: null | number;
  name?: string | null;
  desc?: string | null;
  x: number;
  y: number;
  z: number;
  id?: number;
}
export interface IKpi {
  popup: boolean;
  name: string;
  kpiValue: number;
}
export interface IBubbleDetails {
  name: string;
  nameIdentifier: string;
  id: number | null;
  type: string;
}
export interface IExpressionLookup {
  name: string;
  nameIdentifier: string;
  formattedValue: string;
  uom: string;
  value: number | null;
  minRange: number | null;
  maxRange: number | null;
  minValue: number | null;
  maxValue: number | null;
}
export interface IChartData {
  id: number;
  createdOn?: string | null;
  name: string;
  nameIdentifier: string;
  desc?: string;
  formattedValue: string;
  uom: string | null;
  value: number | null;
  minRange: number | null;
  maxRange: number | null;
  minValue: number | null;
  maxValue: number | null;
  isNormal?: boolean;
  color?: number;
  size?: number;
  children?: IChartData[];
  isTicketCreated?: boolean;
  isAlertTag?: boolean;
  isDisabled: boolean;
  expressionLookup?: IChartData[];
  type: string;
}
export interface IUserPinList {
  tagId: number | null;
  expressionId: number | null;
  tagDetail: IChartData | null;
  expressionDetail: IChartData | null;
}
export interface IPinnedIds {
  tagId: number | null;
  expressionId: number | null;
}

type WebGLContextType = {
  resumeUnity: () => void;
  pauseUnity: () => void;
  chartData: IChartData[] | null;
  bubbleDetails: IBubbleDetails | null;
  handleModalLoader: () => void;
  handleCloseModal: () => void;
  modalOpen: boolean;
  modalLoader: boolean;
  ticketTags: number[];
  ticketTagsHandler: (tagId: number) => void;
  popupBubbleId: number | null;
  configureBubbleHandler: (id: number) => void;
};

const WebGLContext = createContext<any>({
  resumeUnity: () => {},
  pauseUnity: () => {},
  chartData: null,
  bubbleDetails: null,
  handleModalLoader: () => {},
  handleCloseModal: () => {},
  modalOpen: false,
  modalLoader: false,
  ticketTags: [],
  ticketTagsHandler: (state: any) => {},
  popupBubbleId: null,
  configureBubbleHandler: (id: number) => {},
});

export default WebGLContext;
