import { SplashScreen } from "./SplashScreen";
import useSignalR from "hooks/useSignalR";
import useApp from "hooks/useApp";
import WebGLContext, { IChartData, IBubbleDetails } from "./WebGlContext";
import React, { useCallback, useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  getBubblesData,
  getBubblesMeta,
  getNotes,
  updateBubblePosition as apiUpdateBubblePosition,
  getEquipment,
  getUnit,
} from "api/unity";
import { usePins } from "hooks/usePins";
import Bubble from "./Bubble";
import Note from "./Note";
import Pin from "./Pin";
import { useSearchParams } from "react-router-dom";
import { getAllEquipments } from "api/equipments";
import { getAllUnits } from "api/units";
import useData from "hooks/useData";
import Charts from "./Charts";
import { signOut } from "api/user";

const WebGL: React.FC = (props) => {
  const { unityPins } = usePins();
  const [showPins, setShowPins] = useState(true);
  const { setIsLoggedIn, user, isScaled } = useApp();
  const [searchParams] = useSearchParams();
  const [unityLoaded, setUnityLoaded] = useState<any>(null);
  const [currid, setcurrid] = useState<any>(null);
  function handleCacheControl(url: any) {
    if (url.match(/\.data/) || url.match(/\.bundle/)) {
      return "must-revalidate";
    }
    if (url.match(/\.mp4/) || url.match(/\.wav/)) {
      return "immutable";
    }
    return "no-store";
  }

  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
  } = useUnityContext({
    loaderUrl: "Build/Unity/WebGL.loader.js",
    dataUrl: "Build/Unity/WebGL.data",
    frameworkUrl: "Build/Unity/WebGL.framework.js",
    codeUrl: "Build/Unity/WebGL.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: "Build/Unity/StreamingAssets",
  });

  const { unityDataHandler } = useSignalR();
  const {
    getPinnedLists,
    getUnitInfo,
    getEquipmentInfo,
    getTagInfo,
    getTagsInfo,
    getEquipmentExpressionsInfo,
    getUnitExpressionsInfo,
    daySky,
    sceneId,
    profileModal,
  } = useData();

  const [bubbleUpdatedPosition, setBubbleUpdatedPosition] = useState<any>(null);
  const [refreshBubble, setRefreshBubble] = useState(true);
  const [rerenderBubble, setRenderBubble] = useState(false);
  const [rerenderBubbleData, setRenderBubbleData] = useState(false);

  const [refreshNote, setRefreshNote] = useState(true);
  const [chartData, setChartData] = useState<any>(null);
  const [bubbleDetails, setBubbleDetails] = useState<IBubbleDetails | null>(
    null
  );
  const [modalOpen, setModalOpen] = useState(false);

  const [modalLoader, setModalLoader] = useState(true);
  const [playerState, setPlayerState] = useState(false);
  const [ticketTags, updateTicketTags] = useState<number[]>([]);

  const [showSideDialog, setSideDialog] = useState<boolean>(false);
  const [showLogo, setShowLogo] = useState(false);

  const [chartType, setChartType] = useState<any>(null);

  const [selectedUnit, setSelectedUnit] = useState<any>(null);

  const [allBubbles, setAllBubbles] = useState<any>(null);
  const [bubblesMeta, setBubblesMeta] = useState<any>(null);
  const [allNotes, setAllNotes] = useState<any>(null);
  const [equipments, setEquipments] = useState<any>(null);
  const [units, setUnits] = useState<any>(null);

  const getData = async () => {
    const bubblesMetaResponse = await getBubblesMeta();
    if (bubblesMetaResponse.status === 200) {
      setBubblesMeta(bubblesMetaResponse.data);
    }

    const bubblesDataResponse = await getBubblesData();
    if (bubblesDataResponse.status === 200) {
      setAllBubbles(bubblesDataResponse.data);
    }

    const notesResponse = await getNotes();
    if (notesResponse.status === 200) {
      setAllNotes(notesResponse.data);
    }

    const equipmentsResponse = await getAllEquipments();
    if (equipmentsResponse.status === 200) {
      setEquipments(equipmentsResponse.data);
    }

    const unitsResponse = await getAllUnits();
    if (unitsResponse.status === 200) {
      setUnits(unitsResponse.data);
    }
    sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
  };

  useEffect(() => {
    getData();
  }, []);

  const refreshUnityData = async () => {
    const bubblesDataResponse = await getBubblesData();
    if (bubblesDataResponse.status === 200) {
      setAllBubbles(bubblesDataResponse.data);
      sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
    }
    const bubblesMetaResponse = await getBubblesMeta();
    if (bubblesMetaResponse.status === 200) {
      setBubblesMeta(bubblesMetaResponse.data);
      sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    }
  };

  const updateBubbles = async () => {
    const bubblesMetaResponse = await getBubblesMeta();
    if (bubblesMetaResponse.status === 200) {
      setBubblesMeta(bubblesMetaResponse.data);
    }

    const bubblesDataResponse = await getBubblesData();
    if (bubblesDataResponse.status === 200) {
      setAllBubbles(bubblesDataResponse.data);
    }
  };

  useEffect(() => {
    sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
  }, [allBubbles, bubblesMeta]);

  const updateNotes = async () => {
    const notesResponse = await getNotes();
    if (notesResponse.status === 200) {
      setAllNotes(notesResponse.data);
    }
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
  };

  const [bubblePopUp, setBubblePopUp] = useState<any>(null);
  const [bubbleModalOpen, setBubbleModalOpen] = useState(false);
  const [popupBubbleId, setBubbleId] = useState<number | null>(null);

  const [notePopup, setNotePopup] = useState<any>(null);
  const [popupNoteId, setNoteId] = useState<number | null>(null);
  const [noteModalOpen, setNoteModalOpen] = useState(false);

  // const [unityLoaded, setUnityLoaded] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );

  // const {unityLoaded,setUnityLoaded}=useContext(UnityContext);

  useEffect(() => {
    if (isLoaded) {
      setLoadingStatus(
        "We are building your digital twin, it will take from 30 seconds till 4 min depends on graphic card"
      );
    }
  }, [isLoaded]);

  const baseUrl = localStorage.getItem("baseUrl");
  const webglUrl = localStorage.getItem("webglUrl");
  const webglPort = localStorage.getItem("webglPort");

  const unityReadyHandler = useCallback(() => {
    setUnityLoaded(true);
    setUnityLoaded(true);
    setShowLogo(true);
    setTimeout(() => {
      setShowLogo(false);
    }, 5000);
    if (baseUrl) {
      sendMessage("Utilities", "SetBackendUrl", baseUrl);
    }
    if (webglUrl && webglPort) {
      const webglserverurl = { webglUrl: webglUrl, webglPort: webglPort };
      sendMessage(
        "Utilities",
        "SetWebGlServerUrl",
        JSON.stringify(webglserverurl)
      );
    }
  }, [sendMessage]);

  useEffect(() => {
    if (unityLoaded) {
      setTimeout(() => {
        const bubbleId = searchParams.get("bubbleId");
        if (bubbleId) {
          sendMessage("Utilities", "MoveUserToBubble", parseInt(bubbleId));
        }
      }, 5000);
    }
  }, [unityLoaded, sendMessage]);

  useEffect(() => {
    setRefreshBubble(false);
    setRenderBubble(true);
  }, [allBubbles]);

  useEffect(() => {
    setRefreshNote(false);
  }, [allNotes]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    }
  }, [unityLoaded]);

  const loadDataFromContext = useCallback(() => {
    let dataCollection: any = [];
    if (allBubbles) {
      dataCollection = allBubbles.map((bubble: any) => {
        const { unitId, equipmentId } = bubble;

        const dataInfo: any = {
          id: bubble.id,
          x: bubble.x,
          y: bubble.y,
          z: bubble.z,
          unitId: bubble.unitId || 0,
          equipmentId: bubble.equipmentId || 0,
          name: bubble.name ? bubble.name : "",
          // color: -1,
          // size: -1,
        };

        if (unitId && equipmentId) {
          const equipmentInfo = getEquipmentInfo(equipmentId);

          if (equipmentInfo) {
            const { name, color, size } = equipmentInfo;
            if (equipmentInfo?.tagIds.length === 1) {
              const tagInfo = getTagInfo(equipmentInfo.tagIds[0]);
              // dataInfo.name = tagInfo?.name || equipmentInfo.name;
              dataInfo.color = tagInfo?.color || equipmentInfo.color || -1;
              dataInfo.size = tagInfo?.size || equipmentInfo.size || -1;
            } else {
              // dataInfo.name = name;
              dataInfo.color = color || -1;
              dataInfo.size = size || -1;
            }
          }
        } else if (unitId) {
          const unitInfo = getUnitInfo(unitId);
          if (unitInfo) {
            // dataInfo.name = unitInfo.name;
            dataInfo.color = unitInfo.color || -1;
            dataInfo.size = unitInfo.size || -1;
          }
        }

        return dataInfo;
      });
    }

    return dataCollection;
  }, [allBubbles, getEquipmentInfo, getTagInfo, getUnitInfo]);

  const sendBubbleData = useCallback(
    (reload: boolean) => {
      if (unityLoaded) {
        const dataCollection: any = loadDataFromContext();
        const bubbleAndData = {
          rerender: reload,
          bubbles: dataCollection,
        };
        // console.log(bubbleAndData);
        // sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
        // sendMessage("MainBubble", "SetBubbleData", JSON.stringify(bubbleData)
        sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
      }
    },
    [unityLoaded, sendMessage, loadDataFromContext]
  );

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (unityLoaded && rerenderBubble) {
      sendBubbleData(true);
      setRenderBubble(false);
    }
    // eslint-disable-next-line
  }, [rerenderBubble, sendBubbleData, unityLoaded]);

  useEffect(() => {
    setRenderBubbleData(true);
  }, [units]);

  useEffect(() => {
    if (unityLoaded && rerenderBubbleData) {
      sendBubbleData(false);
      setRenderBubbleData(false);
    }
    // eslint-disable-next-line
  }, [rerenderBubbleData, sendBubbleData, unityLoaded]);

  // Notes
  useEffect(() => {
    if (unityLoaded) {
      if (allNotes) {
        const notesCollection = allNotes.map((eachNote: any) => {
          const { id, x, y, z, header, note } = eachNote;
          return { id, x, y, z, header, note };
        });
        sendMessage(
          "MainNote",
          "SetNotesList",
          JSON.stringify(notesCollection)
        );
      } else {
        sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
      }
    }
    // eslint-disable-next-line
  }, [allNotes, unityLoaded]);

  const createBubble: any = useCallback((x: number, y: number, z: number) => {
    const bubbleDetails = {
      x,
      y,
      z,
      unitId: null,
      equipmentId: null,
    };
    setBubblePopUp(bubbleDetails);
    setBubbleModalOpen(true);
    setBubbleId(null);
  }, []);

  const updateBubblePosition: any = useCallback(async (bubbles: any) => {
    if (bubbles) {
      const data = JSON.parse(bubbles);

      // bubbleUpdatedPosition,
      setBubbleUpdatedPosition(data);

      // updateBubblePos(data).then(
      //   (response) => {},
      //   (error) => {
      //     console.log(error);
      //   }
      // );

      const response = await apiUpdateBubblePosition(data);
      if (response.status === 200) {
        const bubblesMetaResponse = await getBubblesMeta();
        if (bubblesMetaResponse.status === 200) {
          setBubblesMeta(bubblesMetaResponse.data);
        }
        // sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
      }
    }
  }, []);

  const configureBubbleHandler: any = useCallback((bubbleId: any) => {
    if (bubbleId) {
      setBubblePopUp(null);
      setBubbleModalOpen(true);
      setBubbleId(bubbleId);
    }
  }, []);

  const createNote: any = useCallback((x: number, y: number, z: number) => {
    const noteDetails = { x, y, z };
    setNotePopup(noteDetails);
    setNoteId(null);
    setNoteModalOpen(true);
  }, []);

  const openNote: any = useCallback((noteId: number) => {
    setNotePopup(null);
    setNoteId(noteId);
    setNoteModalOpen(true);
  }, []);

  const fetchLatestData = useCallback(() => {
    sendMessage("UpdateBubbles", "UpdateFromSignalR");
  }, [sendMessage]);

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("Utilities", "updateSkyBox", daySky);
    }
  }, [unityLoaded, daySky, sendMessage]);

  const tagsAndBubbleConfigHandler: any = useCallback(
    async (bubbleId: any, equipmentId: any, type: any) => {
      if (type === "equipment") {
        const equipment: any = await getEquipment(equipmentId);
        setSelectedUnit(equipment.data.unitId);
        const collections: any = equipment.data.tags;
        if (collections.length > 0) {
          if (equipment) {
            setBubbleDetails({
              name: equipment.data?.name,
              nameIdentifier: equipment.data?.nameIdentifier,
              id: equipment.data?.id,
              type: "tag",
            });
          }
          setChartData(collections);
          setModalOpen(true);
          setModalLoader(false);
          setBubbleId(bubbleId);
        } else if (bubbleId) {
          configureBubbleHandler(bubbleId);
        }
      } else if (type === "unconfigured") {
        setSelectedUnit(null);
        configureBubbleHandler(bubbleId);
      }
    },
    [getTagsInfo, getEquipmentInfo, configureBubbleHandler, equipments]
  );

  const expressionsHandler: any = useCallback(
    async (bubbleId: any, assertId: any, type: any) => {
      if (type === "equipment") {
        const equipment: any = await getEquipment(assertId);
        setSelectedUnit(equipment.data.unitId);
        const collections: any = equipment.data.expressions;
        if (collections.length > 0) {
          if (equipment) {
            setBubbleDetails({
              name: equipment.data.name,
              nameIdentifier: equipment.data.nameIdentifier,
              id: equipment.data.id,
              type: "expression",
            });
          }
          setChartData(collections);
          setModalOpen(true);
          setModalLoader(false);
          setBubbleId(bubbleId);
        }
      } else if (type === "unit") {
        const unit: any = await getUnit(assertId);
        setSelectedUnit(unit.data.unitId);
        const collections: any = unit.data.expressions;
        if (collections.length > 0) {
          if (unit) {
            setBubbleDetails({
              name: unit.data.name,
              nameIdentifier: unit.data.name,
              id: unit.data.id,
              type: "expression",
            });
          }
          setChartData(collections);
          setModalOpen(true);
          setModalLoader(false);
          setBubbleId(bubbleId);
        } else {
          configureBubbleHandler(bubbleId);
          setSelectedUnit(null);
        }
      }
    },
    [
      getEquipmentExpressionsInfo,
      getUnitExpressionsInfo,
      getUnitInfo,
      getEquipmentInfo,
      configureBubbleHandler,
      units,
      equipments,
    ]
  );

  const logOut = useCallback(() => {
    (async () => {
      const response: any = await signOut();
      if (response && response.status === 200) {
        setIsLoggedIn("loggedOut");
      } else {
        setIsLoggedIn("loggedIn");
      }
    })();

    // localStorage.removeItem("token");
  }, []);

  const handlePlayerState: any = useCallback((state: boolean) => {
    setPlayerState(state);
  }, []);

  /* When WebGL component unmounts (in cleanup function) - enable the browser keyboard inputs */
  useEffect(() => {
    return () => {
      if (unityLoaded) {
        pauseUnity();
      }
    };
  }, [unityLoaded]);

  useEffect(() => {
    switch (sceneId) {
      case 1:
        sendMessage("Scene Two", "Loadscene");
        break;
      case 2:
        sendMessage("Scene Three", "Loadscene");
        break;
      default:
        break;
    }
  }, [sendMessage, sceneId]);

  const handleShowPins: any = () => {
    setShowPins(true);
  };

  const handleHidePins: any = () => {
    setShowPins(false);
  };

  useEffect(
    function () {
      // addEventListener("UnityIsReady", unityReadyHandler);

      // addEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
      // addEventListener("TriggerOnRightClick", expressionsHandler);
      // addEventListener("PlayerState", handlePlayerState);
      // addEventListener("makeLogOut", logOut);

      // addEventListener("NewBubbleAdded", createBubble);
      // addEventListener("UpdateBubblePosition", updateBubblePosition);
      // addEventListener("NewNoteAdded", createNote);
      // addEventListener("openNote", openNote);

      addEventListener("UnityIsReady", unityReadyHandler);

      addEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
      addEventListener("TriggerOnRightClick", expressionsHandler);
      addEventListener("PlayerState", handlePlayerState);
      addEventListener("makeLogOut", logOut);

      addEventListener("NewBubbleAdded", createBubble);
      addEventListener("UpdateBubblePosition", updateBubblePosition);
      addEventListener("NewNoteAdded", createNote);
      addEventListener("openNote", openNote);
      // pins React_OnConfiguratorEnter  and React_OnConfiguratorExit
      addEventListener("React_OnConfiguratorEnter", handleHidePins);
      addEventListener("React_OnConfiguratorExit", handleShowPins);
      return () => {
        removeEventListener("UnityIsReady", unityReadyHandler);

        removeEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
        removeEventListener("TriggerOnRightClick", expressionsHandler);
        removeEventListener("PlayerState", handlePlayerState);
        removeEventListener("makeLogOut", logOut);

        removeEventListener("NewBubbleAdded", createBubble);
        removeEventListener("NewNoteAdded", createNote);
        removeEventListener("React_OnConfiguratorEnter", handleHidePins);
        removeEventListener("React_OnConfiguratorExit", handleShowPins);
      };
      // eslint-disable-next-line
    },
    [tagsAndBubbleConfigHandler, expressionsHandler]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        unityDataHandler(fetchLatestData);
      }
    },
    [unityLoaded, fetchLatestData]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        const userData = {
          name: user.name,
          email: user.email,
          skype: user.skype,
          id: user.id,
          token: "",
          roles: user.roles.includes("admin") ? "admin" : "user",
        };
        sendMessage("UserDetails", "setUserDetails", JSON.stringify(userData));
      }
    },
    [unityLoaded]
  );

  /* resume unity when closing the modal/popup */
  const resumeUnity = useCallback(() => {
    if (unityLoaded) {
      sendMessage("Utilities", "ResumeGame");
    }
  }, [unityLoaded, sendMessage]);

  /* PauseUnity - will enable keyboard inputs */
  const pauseUnity = useCallback(() => {
    sendMessage("Utilities", "PauseGameFromReact");
  }, [sendMessage]);

  useEffect(() => {
    if (noteModalOpen) {
      sendMessage("Utilities", "PauseGameFromReact");
    } else {
      sendMessage("Utilities", "ResumeGame");
    }
  }, [noteModalOpen]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setBubbleId(null);
    resumeUnity();
  }, [resumeUnity]);

  const handleModalLoader = useCallback(() => {
    pauseUnity();
    setModalLoader(false);
  }, [pauseUnity]);

  useEffect(() => {
    if (unityLoaded) {
      if (currid !== null)
        sendMessage("Utilities", "MoveUserToLocation", parseInt(currid));
    }
  }, [currid, unityLoaded]);

  const ticketTagsHandler = useCallback((tagId: number) => {
    updateTicketTags((state) => {
      const newList = [...state];
      newList.push(tagId);
      return newList;
    });
  }, []);

  useEffect(() => {
    if (unityLoaded) {
      if (profileModal) {
        pauseUnity();
      } else {
        resumeUnity();
      }
    }
  }, [unityLoaded, profileModal, pauseUnity, resumeUnity]);

  const bubbleCloseHandler = useCallback(() => {
    setBubbleModalOpen(false);
    // setBubbleId(null);
    setBubblePopUp(null);
    resumeUnity();
    setRefreshBubble(true);
  }, [resumeUnity]);

  const noteCloseHandler = useCallback(() => {
    setNoteModalOpen(false);
    resumeUnity();
    setRefreshNote(true);
  }, [resumeUnity]);

  return (
    <WebGLContext.Provider
      value={{
        resumeUnity,
        pauseUnity,
        chartData,
        bubbleDetails,
        handleModalLoader,
        handleCloseModal,
        modalOpen,
        modalLoader,
        ticketTags,
        ticketTagsHandler,
        popupBubbleId,
        configureBubbleHandler,
        chartType,
        setChartType,
        refreshUnityData,
      }}
    >
      {modalOpen && chartData && <Charts setModalOpen={setModalOpen} />}

      {bubbleModalOpen && (
        <Bubble
          bubbleId={popupBubbleId}
          modalOpen={bubbleModalOpen}
          bubbleDetails={bubblePopUp}
          closeHandler={bubbleCloseHandler}
          updateBubbles={updateBubbles}
          units={units}
          equipments={equipments}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          selectedUnit={selectedUnit}
        />
      )}

      {noteModalOpen && (
        <Note
          noteId={popupNoteId}
          modalOpen={noteModalOpen}
          noteDetails={notePopup}
          closeHandler={noteCloseHandler}
          updateNotes={updateNotes}
          getData={getData}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
        />
      )}

      {showPins && unityPins && unityPins.length > 0 && (
        <div className="absolute right-2 bottom-3 rounded-md p-3 flex flex-col gap-3">
          {unityPins.slice(0, 6).map((pin: any) => (
            <Pin key={pin.id} data={pin} unityPin />
          ))}
        </div>
      )}

      <div id="unity-container">
        <SplashScreen
          open={!unityLoaded || showLogo}
          logo={true}
          status={loadingStatus}
          isScaled={isScaled}
        />
        <Unity
          unityProvider={unityProvider}
          className="unityApp"
          style={{
            width: isScaled ? "157vw" : "100vw",
            height: isScaled ? "155vh" : "99.2vh",
            visibility: unityLoaded || showLogo ? "visible" : "hidden",
          }}
        />
      </div>
    </WebGLContext.Provider>
  );
};

export default WebGL;
