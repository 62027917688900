import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useApp from "hooks/useApp";
import {
  FiEdit,
  FiMoreVertical,
  FiPlusSquare,
  FiSettings,
  FiTrash,
  FiTrash2,
} from "react-icons/fi";
import classNames from "utilities/ClassNames";

export default function Menu(props: any) {
  const { isScaled } = useApp();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="hover:text-blue duration-300 cursor-pointer">
          <FiMoreVertical size={isScaled ? 25 : 20} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={classNames(
          isScaled ? "w-52" : "w-44",
          "bg-dark border-slate"
        )}
      >
        <DropdownMenuGroup>
          {props.tabsLength < 4 && props.tabsLength !== 0 && (
            <DropdownMenuItem
              onClick={props.newTab}
              className="cursor-pointer group p-0"
            >
              <div className="w-full h-full px-1.5 py-1.5 flex items-center gap-2 rounded-md group-hover:bg-black">
                <FiPlusSquare
                  className={classNames(isScaled ? "h-6 w-6" : "h-4 w-4")}
                />
                <span className={classNames(isScaled ? "text-xl" : "")}>
                  New Tab
                </span>
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={props.editTab}
            className="cursor-pointer group p-0"
          >
            <div className="w-full h-full px-1.5 py-1.5 flex items-center gap-2 rounded-md group-hover:bg-black">
              <FiEdit
                className={classNames(isScaled ? "h-6 w-6" : "h-4 w-4")}
              />
              <span className={classNames(isScaled ? "text-xl" : "")}>
                Edit Tab
              </span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={props.configureTab}
            className="cursor-pointer group p-0"
          >
            <div className="w-full h-full px-1.5 py-1.5 flex items-center gap-2 rounded-md group-hover:bg-black">
              <FiSettings
                className={classNames(isScaled ? "h-6 w-6" : "h-4 w-4")}
              />
              <span className={classNames(isScaled ? "text-xl" : "")}>
                Configure Tab
              </span>
            </div>
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={props.deleteTab}
            className="cursor-pointer group p-0"
          >
            <div className="w-full h-full px-1.5 py-1.5 flex items-center gap-2 rounded-md group-hover:text-red group-hover:bg-black">
              <FiTrash
                className={classNames(isScaled ? "h-6 w-6" : "h-4 w-4")}
              />
              <span className={classNames(isScaled ? "text-xl" : "")}>
                Delete Tab
              </span>
            </div>
          </DropdownMenuItem>
          {props.deleteWidget && (
            <DropdownMenuItem
              onClick={props.deleteWidget}
              className="cursor-pointer group p-0"
            >
              <div className="w-full h-full px-1.5 py-1.5 flex items-center gap-2 rounded-md group-hover:text-red group-hover:bg-black">
                <FiTrash
                  className={classNames(isScaled ? "h-6 w-6" : "h-4 w-4")}
                />
                <span className={classNames(isScaled ? "text-xl" : "")}>
                  Delete Widget
                </span>
              </div>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
