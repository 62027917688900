import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { mapEquipmentTag } from "api/tags";
import { useState, useMemo } from "react";
import Debounce from "utilities/Debounce";

export default function MapTable({
  mappedTags,
  data,
  selectedEquipment,
  getTags,
  setLoading,
  setError,
  getTagsById,
  setShowMapDialog,
}: any) {
  const [selected, setSelected] = useState(
    mappedTags.map((tag: any) => ({
      equipmentId: selectedEquipment.id,
      tagId: tag.id,
      isMapped: true,
    }))
  );
  const isSelected = (id: any) =>
    selected.filter((item: any) => item.tagId === id && item.isMapped).length >
    0;
  const handleSelect = (tag: any) => {
    if (isSelected(tag.id)) {
      setSelected([
        ...selected.filter((item: any) => item.tagId !== tag.id),
        { equipmentId: selectedEquipment.id, tagId: tag.id, isMapped: false },
      ]);
    } else {
      setSelected([
        ...selected,
        { equipmentId: selectedEquipment.id, tagId: tag.id, isMapped: true },
      ]);
    }
  };
  const columns: ColumnDef<any>[] = [
    {
      id: "select",
      header: () => <div className="font-bold">PI Name</div>,
      cell: ({ row }) => (
        <div
          onClick={() => handleSelect(row.original)}
          className="flex gap-2 items-center cursor-pointer"
        >
          <Checkbox
            checked={isSelected(row.original.id)}
            aria-label="Select row"
          />
          <span>{row.original.tagNameIdentifier}</span>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "name",
      header: () => <div className="font-bold">Tag Name</div>,
    },
    {
      accessorKey: "desc",
      header: () => <div className="font-bold">Description</div>,
    },
    {
      accessorKey: "uom",
      header: () => <div className="font-bold">Engineering Unit</div>,
    },
    {
      accessorKey: "minValue",
      header: () => <div className="text-center font-bold">Min Value</div>,
      cell: ({ row }) => {
        return (
          <div className="text-center">{row.getValue("minValue") ?? "-"}</div>
        );
      },
    },
    {
      accessorKey: "maxValue",
      header: () => <div className="text-center font-bold">Max Value</div>,
      cell: ({ row }) => {
        return (
          <div className="text-center">{row.getValue("maxValue") ?? "-"}</div>
        );
      },
    },
    {
      accessorKey: "minRange",
      header: () => <div className="text-center font-bold">Min Range</div>,
      cell: ({ row }) => {
        return (
          <div className="text-center">{row.getValue("minRange") ?? "-"}</div>
        );
      },
    },
    {
      accessorKey: "maxRange",
      header: () => <div className="text-center font-bold">Max Range</div>,
      cell: ({ row }) => {
        return (
          <div className="text-center">{row.getValue("maxRange") ?? "-"}</div>
        );
      },
    },
  ];
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
    },
  });

  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      const searchByPIname = data?.filter(
        (item: any) =>
          item.tagNameIdentifier.toLowerCase().includes(keyword) ||
          item.tagNameIdentifier.toLowerCase() === keyword
      );
      const searchByTagsName = data?.filter(
        (item: any) =>
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
      items = [...searchByPIname, ...searchByTagsName];
    }

    return items;
  }, [data, debouncedKeyword]);

  const handleMapTags = async () => {
    setLoading(true);
    const response: any = await mapEquipmentTag(selected);
    if (response.status === 200) {
      getTagsById();
      setLoading(false);
      setShowMapDialog(false);
    } else {
      setError("Error mapping tags, please try again");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="relative">
        <div className="flex justify-between">
          <div>
            <input
              onChange={(e: any) => setKeyword(e.target.value)}
              className="input"
              type="text"
              placeholder="Search"
            />
          </div>
          <div>
            <button className="btn" onClick={handleMapTags}>
              Confirm
            </button>
          </div>
        </div>
        <div className="rounded-md border-none mt-5 h-[300px] overflow-hidden overflow-y-scroll">
          <table className="w-full border-collapse">
            <thead>
              <tr className="text-green">
                <th className="text-left">PI Name</th>
                <th className="text-left">Unit</th>
                <th className="text-left">Equipment</th>
                <th className="text-left">Tag Name</th>
                <th>Engineering Unit</th>
                <th>Min Value</th>
                <th>Max Value</th>
                <th>Min Range</th>
                <th>Max Range</th>
              </tr>
            </thead>
            <tbody>
              {rows &&
                rows.map((tag: any) => (
                  <tr
                    key={tag.id}
                    className="border-b border-b-lightGray hover:bg-dark duration-300"
                  >
                    <td>
                      <div
                        className="flex items-center gap-2 underline hover:text-green cursor-pointer"
                        onClick={() => handleSelect(tag)}
                      >
                        <Checkbox
                          checked={isSelected(tag.id)}
                          aria-label="Select row"
                        />
                        <span>{tag.tagNameIdentifier}</span>
                      </div>
                    </td>
                    <td>{tag.unitName}</td>
                    <td>{tag.equipmentName}</td>
                    <td>{tag.name}</td>
                    <td className="text-center">{tag.uom}</td>
                    <td className="text-center">{tag.minValue}</td>
                    <td className="text-center">{tag.maxValue}</td>
                    <td className="text-center">{tag.minRange}</td>
                    <td className="text-center">{tag.maxRange}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
