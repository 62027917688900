import MediumBar from "components/charts/MediumBar";
import { usePins } from "hooks/usePins";
import { FiX } from "react-icons/fi";

export default function Pin({ data, unityPin }: any) {
  const { unPin } = usePins();

  return (
    <div className="rounded-md p-0.5 gradient h-[120px] min-w-[300px] 2xl:w-full 2xl:min-w-[150px] flex items-center">
      <div className="w-full rounded-md h-full bg-black px-2 pb-5 flex flex-col items-center">
        <div
          className="ml-auto py-1 flex cursor-pointer"
          onClick={() =>
            unPin(
              data.data[0].tagNameIdentifier ? data.data[0].id : null,
              !data.data[0].tagNameIdentifier ? data.data[0].id : null,
              unityPin ? 2 : 1
            )
          }
        >
          <FiX size={20} />
        </div>
        <MediumBar data={data.data[0]} barChartName="Total Production" />
      </div>
    </div>
  );
}
