import Select from "components/widgets/Select";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";
export default function KpiConfiguration({
  kpi,
  setKpi,
  handleConfiguration,
  setScreen,
  configError,
}: any) {
  const { isScaled } = useApp();
  return (
    <form className="flex flex-col gap-3 w-11/12 mx-auto">
      {/* K1 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            type="text"
            required
            value={kpi.k1.name}
            onChange={(e: any) =>
              setKpi((prev: any) => ({
                ...prev,
                k1: {
                  ...kpi.k1,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={kpi.k1.id}
            selectedValue={kpi.k1.value}
            type={kpi.k1.type}
            clear={() =>
              setKpi((prev: any) => ({
                ...prev,
                k1: {
                  id: "",
                  name: "",
                  value: "",
                  type: "",
                },
              }))
            }
            select={({ item, typeSelected }: any) => {
              setKpi((prev: any) => ({
                ...prev,

                k1: {
                  ...kpi.k1,
                  id: item.id,
                  value: item,
                  type: typeSelected,
                },
              }));
            }}
          />
        </div>
      </div>
      {/* K2 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            type="text"
            required
            value={kpi.k2.name}
            onChange={(e: any) =>
              setKpi((prev: any) => ({
                ...prev,
                k2: {
                  ...kpi.k2,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={kpi.k2.id}
            selectedValue={kpi.k2.value}
            type={kpi.k2.type}
            clear={() =>
              setKpi((prev: any) => ({
                ...prev,
                k2: {
                  id: "",
                  name: "",
                  value: "",
                  type: "",
                },
              }))
            }
            select={({ item, typeSelected }: any) => {
              setKpi((prev: any) => ({
                ...prev,

                k2: {
                  ...kpi.k2,
                  id: item.id,
                  value: item,
                  type: typeSelected,
                },
              }));
            }}
          />
        </div>
      </div>
      {/* K3 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            type="text"
            required
            value={kpi.k3.name}
            onChange={(e: any) =>
              setKpi((prev: any) => ({
                ...prev,
                k3: {
                  ...kpi.k3,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={kpi.k3.id}
            selectedValue={kpi.k3.value}
            type={kpi.k3.type}
            clear={() =>
              setKpi((prev: any) => ({
                ...prev,
                k3: {
                  id: "",
                  name: "",
                  value: "",
                  type: "",
                },
              }))
            }
            select={({ item, typeSelected }: any) => {
              setKpi((prev: any) => ({
                ...prev,

                k3: {
                  ...kpi.k3,
                  id: item.id,
                  value: item,
                  type: typeSelected,
                },
              }));
            }}
          />
        </div>
      </div>
      {/* K4 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            type="text"
            required
            value={kpi.k4.name}
            onChange={(e: any) =>
              setKpi((prev: any) => ({
                ...prev,
                k4: {
                  ...kpi.k4,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={kpi.k4.id}
            selectedValue={kpi.k4.value}
            type={kpi.k4.type}
            clear={() =>
              setKpi((prev: any) => ({
                ...prev,
                k4: {
                  id: "",
                  name: "",
                  value: "",
                  type: "",
                },
              }))
            }
            select={({ item, typeSelected }: any) => {
              setKpi((prev: any) => ({
                ...prev,
                k4: {
                  ...kpi.k4,
                  id: item.id,
                  value: item,
                  type: typeSelected,
                },
              }));
            }}
          />
        </div>
      </div>

      <div className="flex gap-1 justify-end mt-3">
        <div className="text-red">{configError}</div>
        <div className="flex items-center gap-3">
          <div
            onClick={() => {
              setScreen("");
            }}
            className="btn-sec"
          >
            Cancel
          </div>
          <div onClick={handleConfiguration} className="btn">
            Commit
          </div>
        </div>
      </div>
    </form>
  );
}
