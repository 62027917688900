import useApp from "hooks/useApp";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import classNames from "utilities/ClassNames";

export default function DashboardConfiguration() {
  const { isScaled } = useApp();
  const navigate = useNavigate();
  const location = useLocation();
  const routes = location.pathname.split("/");
  return (
    <div>
      <div
        className={classNames(
          isScaled ? "text-2xl" : "",
          "pl-3 pt-5 pb-5 flex gap-5"
        )}
      >
        <div
          className={classNames(
            routes.includes("tags") ? "gradient" : "bg-black",
            "pb-1"
          )}
        >
          <div
            onClick={() => navigate("/dashboard-configuration/tags")}
            className="cursor-pointer font-bold w-full h-full bg-black px-2 pb-1"
          >
            TAGS
          </div>
        </div>

        <div
          className={classNames(
            routes.includes("expressions") ? "gradient" : "bg-black",
            "pb-1"
          )}
        >
          <div
            onClick={() => navigate("/dashboard-configuration/expressions")}
            className="cursor-pointer font-bold w-full h-full bg-black px-2 pb-1"
          >
            EXPRESSIONS
          </div>
        </div>

        <div
          className={classNames(
            routes.includes("all-assets") ? "gradient" : "bg-black",
            "pb-1"
          )}
        >
          <div
            onClick={() => navigate("/dashboard-configuration/all-assets")}
            className="cursor-pointer font-bold w-full h-full bg-black px-2 pb-1"
          >
            ASSETS (TRAIN/UNIT/EQUIPMENT)
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
