import { TabsProvider } from "hooks/dynamicWidgets/useTabs";
import { WidgetsProvider } from "hooks/dynamicWidgets/useWidgets";
import { WidgetsDataProvider } from "hooks/dynamicWidgets/useWidgetsData";

import WidgetGrid from "./dynamicWidgets";

export default function DynamicWidgets() {
  return (
    <WidgetsProvider>
      <WidgetsDataProvider>
        <TabsProvider>
          <WidgetGrid />
        </TabsProvider>
      </WidgetsDataProvider>
    </WidgetsProvider>
  );
}
