import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { flexRender } from "@tanstack/react-table";
import classNames from "utilities/ClassNames";
import Pagination from "./Pagination";
import Toolbar from "./Toolbar";
import useApp from "hooks/useApp";

export default function index({
  showToolbar,
  showPagination,
  searchColumn,
  searchPlaceholder,
  columns,
  filters,
  rightMenuAction,
  table,
  noBorder,
  isScaled,
}: any) {
  return (
    <>
      {showToolbar && (
        <Toolbar
          table={table}
          searchColumn={searchColumn}
          searchPlaceholder={searchPlaceholder}
          filters={filters}
          rightMenuAction={rightMenuAction}
          isScaled={isScaled}
        />
      )}
      <div
        className={classNames(noBorder ? "" : "gradient rounded-md p-0.5 mt-2")}
      >
        <div className="bg-black rounded-md border-none">
          <Table className="bg-black rounded-md text-[17px]">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup: any) => (
                <TableRow
                  key={headerGroup.id}
                  className={classNames(
                    isScaled ? "text-2xl" : "text-base",
                    "border-slate font-bold"
                  )}
                >
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row: any) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={classNames(
                      isScaled ? "text-2xl" : "text-base",
                      "border-slate"
                    )}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id} className="font-[16px]">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      {showPagination && <Pagination table={table} />}
    </>
  );
}
