import { Label } from "@/components/ui/label";
import { getExpressionById, validateExpression } from "api/expression";
import Loader from "components/shared/Loader";
import { useCallback, useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import ExpressionCalculation from "./ExpressionCalculation";

import { uploadTag } from "api/tags";
import useApp from "hooks/useApp";
import useSignalR from "hooks/useSignalR";
import { v4 as uuidv4 } from "uuid";
import classNames from "utilities/ClassNames";

const Status: any = {
  1: "Start",
  2: "Progress",
  3: "Completed",
  4: "Canceled",
  5: "Failed",
};

const defaultSignalRValue = {
  currentMessage: "",
  currentPercentage: 0,
  currentCount: 0,
  totalCount: 0,
  currentStage: 0,
  totalStage: 0,
  type: "",
  status: "",
};

export default function newExpression({
  selectedExpression,
  setSelectedExpression,
  editExpression,
}: any) {
  const { isScaled } = useApp();
  const [expData, setExpData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [validationLoading, setValidationLoading] = useState(false);
  const [errors, setErrors] = useState<any>([]);
  const [message, setMessage] = useState<any>("");
  const [expressionMessage, setExpressionMessage] = useState<any>("");
  const [validationResult, setValidationResult] = useState(null);
  const [validationErrors, setValidationErrors] = useState<any>(null);

  const [name, setName] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [assetType, setAssetType] = useState<any>(0);
  const [type, setType] = useState<any>(null);
  const [id, setId] = useState<any>(0);
  const [seqOrder, setSeqOrder] = useState<any>(0);
  const [minRange, setMinRange] = useState<any>(0);
  const [maxRange, setMaxRange] = useState<any>(0);
  const [expression, setExpression] = useState<any>("");
  const [expression1, setExpression1] = useState<any>("");
  const [expression2, setExpression2] = useState<any>("");
  const [expression3, setExpression3] = useState<any>("");
  const [equipmentId, setEquipmentId] = useState<any>(null);
  const [unitId, setUnitId] = useState<any>(null);
  const [trainId, setTrainId] = useState<any>(null);
  const [plantId, setPlantId] = useState<any>(null);
  const [uom, setUom] = useState<any>("");
  const [minValue, setMinValue] = useState<any>(0);
  const [maxValue, setMaxValue] = useState<any>(0);
  const [equipmentName, setEquipmentName] = useState<any>(null);
  const [unitName, setUnitName] = useState<any>(null);
  const [trainName, setTrainName] = useState<any>(null);
  const [plantName, setPlantName] = useState<any>(null);

  const { isLoggedIn } = useApp();
  const { FileUploadHandler } = useSignalR();
  const [fileLoading, setFileLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState<any>([]);
  const [abortController, setAbortController] = useState<any>(null);
  const [fileDetails, setFileDetails] = useState({
    fileName: "",
    errors: "",
    value: "",
  });
  const [error, setError] = useState(false);
  const [uuid, setUuid] = useState<string>();
  const [progressPopup, setProgressPopup] = useState(false);
  const [signalRDetails, setSignalRDetails] = useState(defaultSignalRValue);

  useEffect(() => {
    if (equipmentId === "") {
      setEquipmentId(null);
    }
    // setUnitId(null);
    // setTrainId(null);
    // setPlantId(null);
  }, [equipmentId]);

  useEffect(() => {
    if (unitId === "") {
      setUnitId(null);
    }
  }, [unitId]);

  useEffect(() => {
    if (unitId === "") {
      setTrainId(null);
    }
  }, [trainId]);

  useEffect(() => {
    if (selectedExpression.value) {
      setLoading(true);
      (async () => {
        const response = await getExpressionById(selectedExpression?.value?.id);
        if (response.status === 200) {
          setExpData(response.data);
        }
      })();
      setLoading(false);
    }
  }, [selectedExpression]);

  useEffect(() => {
    if (selectedExpression.action === "edit" && expData) {
      setName(expData.name);
      setDesc(expData.desc);
      setAssetType(
        expData.plantId
          ? "1"
          : expData.trainId
          ? "2"
          : expData.unitId
          ? "3"
          : "4"
      );
      setType(expData.type);
      setSeqOrder(expData.seqOrder);
      setId(expData.id);
      setMinRange(expData.minRange);
      setMaxRange(expData.maxRange);
      setExpression(expData.expression);
      setExpression1(expData.expression1);
      setExpression2(expData.expression2);
      setExpression3(expData.expression3);
      setEquipmentId(expData.equipmentId);
      setUnitId(expData.unitId);
      setTrainId(expData.trainId);
      setPlantId(expData.plantId);
      setMinValue(expData.minValue);
      setMaxValue(expData.maxValue);
      setEquipmentName(expData.equipmentName);
      setUnitName(expData.unitName);
      setTrainName(expData.trainName);
      setPlantName(expData.plantName);
      setUom(expData.uom);
    }
  }, [expData]);

  const handleValidation = async () => {
    setValidationErrors(null);
    setValidationLoading(true);
    setErrors([]);
    const response: any = await validateExpression({
      name,
      desc,
      assetType,
      type,
      id,
      seqOrder,
      minRange,
      maxRange,
      expression,
      expression1,
      expression2,
      expression3,
      equipmentId,
      unitId,
      trainId,
      plantId,
      uom,
      minValue,
      maxValue,
      equipmentName,
      unitName,
      trainName,
      plantName,
    });
    if (response.status === 200) {
      setMessage("Expression has been validated successfully.");
      setValidationResult(response.data);
      return setValidationLoading(false);
    }
    if (response.status === 400) {
      if (response.data.Validations) {
        setValidationErrors(response.data.Validations);
      } else {
        setValidationErrors([response.data.title]);
      }
      setValidationLoading(false);
    }
  };

  const handleSubmit = async (event: any) => {
    setErrors(null);
    event.preventDefault();
    if (
      expression === "" &&
      expression1 === "" &&
      expression2 === "" &&
      expression3 === ""
    ) {
      setExpressionMessage("Expression details is required");
    }
    setLoading(true);
    const result = await editExpression({
      name,
      desc,
      assetType: parseInt(assetType),
      type,
      id,
      seqOrder: parseInt(seqOrder),
      minRange,
      maxRange,
      expression,
      expression1,
      expression2,
      expression3,
      equipmentId,
      unitId,
      trainId,
      plantId,
      uom,
      minValue,
      maxValue,
      equipmentName,
      unitName,
      trainName,
      plantName,
    });
    if (result.message === "success") {
      setLoading(false);
      return setSelectedExpression({ action: "", value: null });
    } else {
      setLoading(false);
      setErrors(result.value);
    }
    setLoading(false);
  };

  const handleFile = (event: any) => {
    const fileUploaded = event.target.files[0];
    if (!fileUploaded && !fileDetails.fileName) {
      setFileDetails({ fileName: "", errors: "required", value: "" });
    } else if (fileUploaded) {
      if (!["text/csv", ".csv"].includes(fileUploaded.type)) {
        setFileDetails({ fileName: "", errors: "fileType", value: "" });
        setMessage(["Please upload only csv file."]);
        setError(true);
      } else {
        setFileDetails({
          fileName: fileUploaded.name,
          errors: "",
          value: event.target.files[0],
        });
        setMessage([]);
        setError(false);
      }
    }
  };

  const handleFileSubmit = async (event: any) => {
    event.preventDefault();
    setFileLoading(true);
    const formData = new FormData();
    const _id = uuidv4();
    formData.append("File", fileDetails.value);
    formData.append("Id", _id);
    const controller = new AbortController();
    setAbortController(controller);
    setProgressPopup(true);
    setUuid(_id);
    const response: any = await uploadTag(formData, controller.signal);
    if (response.status === 200) {
      setFileLoading(false);
      setMessage(["Uploaded Successfully."]);
      setAbortController(null);
      return setProgressPopup(false);
    }
    if (response.status === 400) {
      if (response.data.Validations) {
        setFileErrors(response.data.Validations);
      } else {
        setFileErrors([response.data.title]);
      }
    }
    setFileLoading(false);
  };

  const updateSignalRDetails = useCallback(
    (data: any) => {
      if (data.id === uuid) {
        setSignalRDetails(data);
      }
    },
    [uuid]
  );

  useEffect(() => {
    if (isLoggedIn === "loggedIn") {
      FileUploadHandler(updateSignalRDetails);
    }
  }, [isLoggedIn, updateSignalRDetails, FileUploadHandler]);

  const closeHandler = useCallback(() => {
    const text =
      "Closing the window will abort the file upload. Are you sure you want to close ?";
    if (window.confirm(text) === true) {
      setProgressPopup(false);
      abortController?.abort();
      setFileLoading(false);
      setUuid("");
      setSignalRDetails(defaultSignalRValue);
    }
  }, [abortController]);

  const downloadFile = async () => {
    // const response = await downloadTag(selectedExpression.value.id);
    // if (response.status === 200) {
    //   console.log(response.data);
    //   const result = response.data;
    //   const url = window.URL.createObjectURL(new Blob([result]));
    //   const link = document.createElement("a");
    //   link.href = url;
    //   const fileName = `${selectedExpression.value.tagNameIdentifier}.csv`; // whatever your file name .
    //   link.setAttribute("download", fileName);
    //   document.body.appendChild(link);
    //   link.click();
    //   link.remove();
    // }
    // download expression
    alert("download expression file");
  };

  const clearFile = async () => {
    // await clearTag(selectedExpression.value.id);
    alert("clear expression file");
  };

  return (
    <>
      {selectedExpression.action === "edit" && (
        <div className="fixed inset-0 z-10 w-full h-screen bg-black/50">
          {!loading && (
            <div
              className={classNames(
                isScaled ? "mt-48" : "mt-7",
                "h-[92%] w-[1000px] gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              )}
            >
              <div className="relative flex flex-col w-full h-full overflow-hidden overflow-y-scroll gap-5 bg-dark rounded-md pl-3 pt-3 pb-3">
                {/* Header */}
                <div className="flex justify-between items-center">
                  <h1 className="text-xl font-bold">Edit Expression</h1>
                  <span
                    className="cursor-pointer bg-green p-1 rounded-md"
                    onClick={() =>
                      setSelectedExpression({ action: "", value: null })
                    }
                  >
                    <FiX size={20} />
                  </span>
                </div>
                <div className="h-[900px] overflow-hidden overflow-y-scroll p-3">
                  {errors?.length > 0 && (
                    <div>
                      {errors.map((error: string) => (
                        <p key={error} className="text-red">
                          {error}
                        </p>
                      ))}
                    </div>
                  )}
                  <form className="grid gap-4 py-4">
                    {/* Name */}
                    <div className="flex flex-col gap-2">
                      <Label htmlFor="name">Name</Label>
                      <input
                        id="name"
                        type="text"
                        placeholder="PI Name"
                        className="col-span-3 input"
                        required
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                      />
                    </div>
                    {/* Tag Description */}
                    <div className="flex flex-col gap-2">
                      <Label htmlFor="description">Description</Label>
                      <input
                        id="desc"
                        type="text"
                        placeholder="Description"
                        className="col-span-3 input"
                        required
                        value={desc}
                        onChange={(event: any) => setDesc(event.target.value)}
                      />
                    </div>
                    {/* Calculation Type */}
                    <ExpressionCalculation
                      loading={loading}
                      message={message}
                      errors={errors}
                      data={expData}
                      editMode={true}
                      handleValidation={handleValidation}
                      setExpression={setExpression}
                      expression={expression}
                      expression1={expression1}
                      setExpression1={setExpression1}
                      expression2={expression2}
                      setExpression2={setExpression2}
                      expression3={expression3}
                      setExpression3={setExpression3}
                      equipmentId={equipmentId}
                      setEquipmentId={setEquipmentId}
                      unitId={unitId}
                      setUnitId={setUnitId}
                      trainId={trainId}
                      setTrainId={setTrainId}
                      assetType={assetType}
                      setAssetType={setAssetType}
                      type={type}
                      setType={setType}
                      setPlantId={setPlantId}
                      expressionMessage={expressionMessage}
                      validationResult={validationResult}
                      validationLoading={validationLoading}
                      setValidationLoading={setValidationLoading}
                      setValidationResult={setValidationResult}
                      setMessage={setMessage}
                      validationErrors={validationErrors}
                    />
                    {/* Engineering Unit and Sequence Order */}
                    <div className="flex gap-3 items-center">
                      <div className="flex flex-col gap-2 w-full">
                        <Label htmlFor="uom">Engineering Unit</Label>
                        <input
                          id="uom"
                          type="text"
                          placeholder="Engineering Unit"
                          className="col-span-3 input"
                          required
                          value={uom}
                          onChange={(event: any) => setUom(event.target.value)}
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <Label htmlFor="seqOrder">Sequence Order</Label>
                        <input
                          id="seqOrder"
                          type="number"
                          placeholder="Sequence Order"
                          className="col-span-3 input"
                          required
                          value={seqOrder}
                          onChange={(event: any) =>
                            setSeqOrder(event.target.value)
                          }
                        />
                      </div>
                    </div>
                    {/* Min Max Values */}
                    <div className="flex gap-3 items-center">
                      <div className="flex flex-col gap-2 w-full">
                        <Label htmlFor="minValue">Min Value</Label>
                        <input
                          id="minValue"
                          type="number"
                          placeholder="Min Value"
                          className="col-span-3 input"
                          required
                          value={minValue}
                          onChange={(event: any) =>
                            setMinValue(parseFloat(event.target.value))
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <Label htmlFor="maxValue">Max Value</Label>
                        <input
                          id="maxValue"
                          type="number"
                          placeholder="Max Value"
                          className="col-span-3 input"
                          required
                          value={maxValue}
                          onChange={(event: any) =>
                            setMaxValue(parseFloat(event.target.value))
                          }
                        />
                      </div>
                    </div>
                    {/* Min Max Range */}
                    <div className="flex gap-3 items-center">
                      <div className="flex flex-col gap-2 w-full">
                        <Label htmlFor="minRange">Min Range</Label>
                        <input
                          id="minRange"
                          type="number"
                          placeholder="Min Range"
                          className="col-span-3 input"
                          required
                          value={minRange}
                          onChange={(event: any) =>
                            setMinRange(parseFloat(event.target.value))
                          }
                        />
                      </div>
                      <div className="flex flex-col gap-2 w-full">
                        <Label htmlFor="maxRange">Max Range</Label>
                        <input
                          id="maxRange"
                          type="number"
                          placeholder="Max Range"
                          className="col-span-3 input"
                          required
                          value={maxRange}
                          onChange={(event: any) =>
                            setMaxRange(parseFloat(event.target.value))
                          }
                        />
                      </div>
                    </div>
                  </form>
                  {/* file upload */}
                  <>
                    {!fileLoading && (
                      <div className="flex flex-col gap-5 ">
                        {fileErrors?.length > 0 && (
                          <div>
                            {fileErrors.map((error: string) => (
                              <p key={error} className="text-rose-400">
                                {error}
                              </p>
                            ))}
                          </div>
                        )}
                        <form
                          onSubmit={handleFileSubmit}
                          className="grid gap-4 py-4"
                        >
                          {/* PI Name */}
                          <div className="gradient p-0.5 rounded-md relative">
                            <div className="absolute py-1 px-2 bg-dark -top-4 left-2">
                              File Upload
                            </div>
                            <div className="flex flex-col gap-2 bg-dark p-3 pt-5 rounded-md">
                              <input
                                name="tag_csv"
                                onChange={handleFileSubmit}
                                accept={"text/csv,.csv"}
                                type="file"
                                placeholder="PI Name"
                                className="col-span-3 input"
                                required
                              />
                              <div className="mt-2">
                                <span
                                  className="btn"
                                  onClick={handleFileSubmit}
                                >
                                  Upload
                                </span>
                              </div>
                              <div className="mt-3 file-upload">
                                <div className="my-1">
                                  <label>Supports: </label>
                                  <div className="inline-block ml-1">
                                    <span className="text-green">.csv</span>{" "}
                                    files only
                                  </div>
                                </div>
                                <div className="my-1">
                                  <label className="my-1">TagName:</label>
                                  <span className="ml-1">PITagName</span>
                                  <label className="ml-2">
                                    ExpressionName:
                                  </label>
                                  <span className="ml-2">
                                    ExprName
                                    <span className="text-green">@</span>
                                    EquipName or ExprName
                                    <span className="text-green">@</span>
                                    UnitName or ExprName
                                    <span className="text-green">@</span>
                                    TrainName or ExprName
                                    <span className="text-green">@</span>
                                    PlantName
                                  </span>
                                </div>
                                <div></div>
                                <div>
                                  <label>Sample File Format: </label>
                                  Date (mm/dd/yyyy hh:mm) , TagName1 or
                                  ExpressionName1 , TagName2 or ExpressionName2
                                  ,....
                                </div>
                              </div>
                              <h1 className="mt-3 font-bold">Sample File</h1>
                              <pre className="bg-white text-dark p-3 rounded-md text-sm">
                                Date, 141-FI-320.PV, 500-FI-611. PV,
                                500-FI-611A. PV <br />
                                1/1/2023 0:00,
                                157.9499355,834.0295041,788.3192724
                                <br />
                                1/3/2023
                                0:00,158.1374796,773.6364545,733.5062088
                                <br />
                                1/4/2023
                                0:00,157.9176099,850.7646894,801.0766662
                              </pre>
                            </div>
                          </div>

                          <div className="mt-3 flex justify-between">
                            <div className="flex items-center gap-3">
                              <div className="btn" onClick={downloadFile}>
                                Download Data
                              </div>
                              <div className="btn" onClick={clearFile}>
                                Clean Data
                              </div>
                            </div>
                            <div className="btn" onClick={handleSubmit}>
                              Update
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {progressPopup && (
                      <>
                        <div className="absolute z-40 inset-0 w-full h-full bg-black/70" />
                        <div className="w-full h-auto bg-black p-3 rounded-md z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[600px]">
                          <div className="flex justify-between items-center">
                            <h1>File Uploading...</h1>
                            <span
                              className="cursor-pointer"
                              onClick={() => closeHandler()}
                            >
                              <FiX size={20} />
                            </span>
                          </div>
                          <p>
                            {Status[signalRDetails.status]} -
                            {signalRDetails.currentPercentage} %
                          </p>
                          <div className="py-[15px] px-[30px]">
                            <div className="Progressbar">
                              <div
                                className="Progressbar__value"
                                style={{
                                  width: `${signalRDetails.currentPercentage}%`,
                                }}
                              ></div>
                              <progress
                                value={signalRDetails.currentPercentage}
                                max="100"
                              >
                                100%
                              </progress>
                            </div>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <p>
                              {" "}
                              <span>{signalRDetails.currentMessage} </span>{" "}
                              <span>{signalRDetails.currentCount} </span> /{" "}
                              <span id="TotalCount">
                                {signalRDetails.totalCount}
                              </span>{" "}
                            </p>
                            <label id="CurrentStage">
                              {signalRDetails.currentStage}
                            </label>
                            /
                            <label id="TotalStage">
                              {signalRDetails.totalStage}
                            </label>
                          </div>
                          <div className="flex justify-end">
                            <button
                              onClick={() => abortController?.abort()}
                              className="btn-sec"
                              type="button"
                            >
                              Cancel Upload
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </div>
            </div>
          )}
          {loading && (
            <div className="w-full h-full grid place-content-center relative z-30">
              <Loader />
            </div>
          )}
        </div>
      )}
    </>
  );
}
