import Loader from "components/shared/Loader";
import React from "react";
import TextOverflow from "utilities/TextOverflow";
import { useState, useEffect } from "react";
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

const KpiTab = ({ data, name }: any) => {
  const { isScaled } = useApp();
  return (
    <>
      {data && (
        <div className="rounded-md bg-dark p-3 flex items-center justify-center">
          <div className="flex flex-col gap-1">
            <p
              className={classNames(
                isScaled ? "text-4xl" : "text-xl",
                "capitalize font-bold text-center whitespace-nowrap text-green"
              )}
            >
              {name}
            </p>
            <div className="flex gap-1 justify-center">
              {data.value ? (
                <p
                  className={classNames(
                    isScaled ? "text-6xl" : "text-5xl",
                    "text-white font-semibold"
                  )}
                >
                  {data?.uom === "%"
                    ? TextOverflow(data?.value.toFixed(2), 7)
                    : TextOverflow(data?.value.toFixed(2), 7)}
                </p>
              ) : (
                <p className="text-white font-semibold text-5xl">
                  {TextOverflow(data?.formattedValue, 7)}
                </p>
              )}
              {data.value && (
                <p className="text-white font-semibold text-lg self-end">
                  {data?.uom}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Kpi = ({ tab }: any) => {
  const data = tab.configurations;
  const [k1, setK1] = useState<any>(null);
  const [k2, setK2] = useState<any>(null);
  const [k3, setK3] = useState<any>(null);
  const [k4, setK4] = useState<any>(null);

  useEffect(() => {
    (async () => {
      // K1
      if (data.k1.id !== "") {
        if (data.k1.type === "tags") {
          const k1Res = await getTagData([data.k1.id]);
          if (k1Res.status === 200) {
            setK1(k1Res.data[0]);
          }
        } else {
          const k1Res = await getExpressionData([data.k1.id]);
          if (k1Res.status === 200) {
            setK1(k1Res.data[0]);
          }
        }
      }
      // K2
      if (data.k2.id !== "") {
        if (data.k2.type === "tags") {
          const k2Res = await getTagData([data.k2.id]);
          if (k2Res.status === 200) {
            setK2(k2Res.data[0]);
          }
        } else {
          const k2Res = await getExpressionData([data.k2.id]);
          if (k2Res.status === 200) {
            setK2(k2Res.data[0]);
          }
        }
      }
      // K3
      if (data.k3.id !== "") {
        if (data.k3.type === "tags") {
          const k3Res = await getTagData([data.k3.id]);
          if (k3Res.status === 200) {
            setK3(k3Res.data[0]);
          }
        } else {
          const k3Res = await getExpressionData([data.k3.id]);
          if (k3Res.status === 200) {
            setK3(k3Res.data[0]);
          }
        }
      }
      // K4
      if (data.k3.id !== "") {
        if (data.k4.type === "tags") {
          const k4Res = await getTagData([data.k4.id]);
          if (k4Res.status === 200) {
            setK4(k4Res.data[0]);
          }
        } else {
          const k4Res = await getExpressionData([data.k4.id]);
          if (k4Res.status === 200) {
            setK4(k4Res.data[0]);
          }
        }
      }
    })();
  }, []);

  return (
    <>
      {!data && (
        <div className="w-full h-full grid place-content-center">
          <Loader />
        </div>
      )}
      <div className="grid grid-cols-2 grid-rows-2 gap-3 h-full">
        {k1 && <KpiTab data={k1} name={data.k1.name} />}
        {k2 && <KpiTab data={k2} name={data.k2.name} />}
        {k3 && <KpiTab data={k3} name={data.k3.name} />}
        {k4 && <KpiTab data={k4} name={data.k4.name} />}
      </div>
    </>
  );
};

export default React.memo(Kpi);
