/* eslint-disable @typescript-eslint/no-unused-expressions */
import api from "utilities/Axios";

export const getWidgets = async () => {
  return api.get("/Dashboard/GetDashboardWidget");
};

export const updateWidgets = async (data: any) => {
  return api.post("/Dashboard/UpdateDashboardWidget", data);
};

export const getAllData = async (plantId = 1) => {
  return api.get(
    `/Dashboard/GetPlantTrainUnitsEquipmentsTagsByPlant?plantId=${plantId}`
  );
};

export const getExpressionPastData = async (
  id: string,
  days: number,
  avgType: string
) => {
  let url = `/Dashboard/GetPastExpressionDataWithAvg?expressionId=${id}`;
  days ? (url += `&pastNumberOfDays=${days}`) : null;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return api.get(url);
};

export const getTagsPastData = async (
  id: string,
  days: number,
  avgType: string
) => {
  let url = `/Dashboard/GetTagPastDataWithAvg?tagId=${id}`;
  days ? (url += `&pastNumberOfDays=${days}`) : null;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return api.get(url);
};

export const getPinnedWidgets = async () => {
  return api.get("/Dashboard/getPinnedWidgets");
};

export const pinWidget = async (data: any) => {
  return api.post(`/Dashboard/PinWidget`, data);
};

export const getExpressionsValues = async (data: any) => {
  return api.post(`/Dashboard/GetExpressionValue`, data);
};
