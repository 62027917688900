import TagArea from "components/charts/tag/TagArea";
import useData from "hooks/useData";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { createTicket } from "api/tickets";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";

export default function TagChart({
  selectedTag,
  setSelectedTag,
  alarm,
  disable,
}: any) {
  const { isScaled } = useApp();
  const { getTagInfo } = useData();
  const [tag, setTag] = useState<any>(null);
  const [ticketDialog, setTicketDialog] = useState(false);

  const [newTicket, setNewTicket] = useState<any>({
    comments: "",
    description: "",
    priority: null,
    severity: null,
    tagId: null,
    title: "",
  });

  useEffect(() => {
    if (selectedTag.action === "chart") {
      const result = getTagInfo(selectedTag.value.id);
      setTag(result);
    }
  }, [selectedTag]);

  useEffect(() => {
    if (tag) {
      setNewTicket({ ...newTicket, tagId: tag.id });
    }
  }, [tag]);

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setNewTicket({ ...newTicket, [e.target.id]: e.target.value });
  };

  const handleNewTicket = async (event: any) => {
    event.preventDefault();
    const response: any = await createTicket(newTicket);
    if (response.status === 200) {
      setTicketDialog(false);
    }
    setNewTicket({
      comments: "",
      description: "",
      priority: null,
      severity: null,
      tagId: null,
      title: "",
    });
  };

  return (
    <>
      {selectedTag.action === "chart" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div
            className={classNames(
              isScaled ? "h-[2000px]" : "h-full",
              "absolute z-20 inset-0 w-full bg-black/70"
            )}
          />

          <div
            className={classNames(
              isScaled ? "mt-48 sm:max-w-[800px]" : "sm:max-w-[650px]",
              "w-full h-auto gradient p-0.5 rounded-md z-30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            )}
          >
            <div className="bg-dark p-3 w-full h-full rounded-md">
              {ticketDialog && (
                <div className="flex flex-col py-3">
                  <h1>Add trouble ticket</h1>
                  <form className="mt-3" onSubmit={handleNewTicket}>
                    <div className="py-1">
                      <label htmlFor="title">Issue Title</label>
                      <input
                        className="input"
                        onChange={handleChange}
                        type="text"
                        id="title"
                        value={newTicket.title}
                      />
                    </div>

                    <div className="py-1">
                      <label htmlFor="description">Issue description</label>
                      <textarea
                        className="input"
                        onChange={handleChange}
                        id="description"
                        value={newTicket.description}
                      />
                    </div>

                    <div className="py-1">
                      <label htmlFor="comments">Resolution</label>
                      <textarea
                        className="input"
                        onChange={handleChange}
                        id="comments"
                        value={newTicket.comments}
                      />
                    </div>

                    <div className="py-2 flex justify-between gap-5">
                      <div className="flex flex-col gap-1 w-full">
                        <label>Severity</label>
                        <Select
                          value={newTicket.severity}
                          onValueChange={(value: any) =>
                            setNewTicket({ ...newTicket, severity: value })
                          }
                        >
                          <SelectTrigger className="w-full border-slate">
                            <SelectValue placeholder="severity" />
                          </SelectTrigger>
                          <SelectContent className="bg-dark border-slate">
                            <SelectItem value="1">1</SelectItem>
                            <SelectItem value="2">2</SelectItem>
                            <SelectItem value="3">3</SelectItem>
                            <SelectItem value="4">4</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-col gap-1 w-full">
                        <label>Priority</label>
                        <Select
                          value={newTicket.priority}
                          onValueChange={(value: any) =>
                            setNewTicket({ ...newTicket, priority: value })
                          }
                        >
                          <SelectTrigger className="w-full border-slate">
                            <SelectValue placeholder="Priority" />
                          </SelectTrigger>
                          <SelectContent className="bg-dark border-slate">
                            <SelectItem value="3">Height</SelectItem>
                            <SelectItem value="2">Medium</SelectItem>
                            <SelectItem value="1">Low</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                    {/* Ticket Dialog footer */}
                    <div className="flex justify-end gap-5 mt-2">
                      <button
                        className="cursor-pointer btn-sec"
                        onClick={() => setTicketDialog(false)}
                      >
                        Cancel
                      </button>
                      <button className="cursor-pointer btn" type="submit">
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {!ticketDialog && (
                <div className="flex flex-col">
                  {/* Header */}
                  <div className="flex justify-end">
                    <div
                      className="cursor-pointer bg-green rounded-md p-1"
                      onClick={() =>
                        setSelectedTag({ action: "", value: null })
                      }
                    >
                      <FiX size={20} />
                    </div>
                  </div>
                  {/* content */}
                  {selectedTag && (
                    <TagArea
                      data={selectedTag.value}
                      bar
                      setTicketDialog={setTicketDialog}
                      isTag
                      alarm={alarm}
                      disable={disable}
                    />
                  )}
                  {/* Footer */}
                  {tag && (
                    <div className="pt-5">
                      <div className="flex items-center">
                        <p>Tag Name:</p>
                        <div className="flex-1" />
                        <p>{tag.name}</p>
                      </div>

                      <div className="flex items-center">
                        <p>Description:</p>
                        <div className="flex-1" />
                        <p>{tag.desc}</p>
                      </div>

                      <div className="flex items-center">
                        <p>Engineering Unit:</p>
                        <div className="flex-1" />
                        <p>{tag.uom}</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
