import { updateAlarm } from "api/alarm";
import {
  deleteTag as apiDeleteTag,
  resetTag as apiResetTag,
  createTag,
  disableTag,
  getAllTagsWithParents,
  updateTag,
} from "api/tags";
import Loader from "components/shared/Loader";
import { useEffect, useMemo, useState } from "react";
import { BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LuFileEdit, LuTimerReset, LuTrash2 } from "react-icons/lu";
import Debounce from "utilities/Debounce";
import { filterArray } from "utilities/FilterArray";
import { parseFilters } from "utilities/ParseFilters";
import DeleteTag from "./DeleteTag";
import EditTag from "./EditTag";
import NewTag from "./NewTag";
import ResetTag from "./ResetTag";
import TagChart from "./TagChart";
import Filters from "./table/Filters";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { usePins } from "hooks/usePins";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";

const RowAction = (props: any) => {
  const { isScaled } = useApp();
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <div
            className="cursor-pointer hover:text-green"
            onClick={props.action}
          >
            {props.icon}
          </div>
        </TooltipTrigger>
        <TooltipContent
          className={classNames(isScaled ? "-mr-9 text-lg" : "")}
          style={{ background: props.danger ? "#ef4444" : "#34d399" }}
        >
          <p className="text-white">{props.text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default function TagContainer() {
  const { isScaled } = useApp();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);

  const [equipmentsFilters, setEquipmentsFilters] = useState<any>([]);
  const [equipmentsOptions, setEquipmentsOptions] = useState<any>();
  const [unitsFilters, setUnitsFilters] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>();
  const [tag, setTag] = useState<any>({
    action: "",
    value: null,
  });

  const { dashboardPins, pinTag, unPin, pinLoading } = usePins();

  // table
  const ITEMS_PER_PAGE = 50;
  const [totalPages, setTotalPages] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    setLoading(true);
    const response: any = await getAllTagsWithParents();
    if (response.status === 200) {
      setRawData(response.data);
      //equipments
      const equipments = response.data.map((item: any) => item.equipmentName);
      setEquipmentsOptions(parseFilters(equipments));
      //equipments
      const units = response.data.map((item: any) => item.unitName);
      setUnitsOptions(parseFilters(units));
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    const response: any = await getAllTagsWithParents();
    if (response.status === 200) {
      setRawData(response.data);
    }
  };

  useEffect(() => {
    refreshData();
  }, [dashboardPins]);

  const createNewTag = async (tag: any) => {
    const response: any = await createTag(tag);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }

    if (response.status === 400) {
      if (response.data.Validations) {
        return { message: "failure", value: response.data.Validations };
      } else {
        return { message: "failure", value: response.data.title };
      }
    }
  };

  const editTag = async (tag: any) => {
    const response: any = await updateTag(tag);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }

    if (response.status === 400) {
      if (response.data.Validations) {
        return { message: "failure", value: response.data.Validations };
      } else {
        return { message: "failure", value: response.data.title };
      }
    }
  };

  const resetTag = async (id: any) => {
    const response: any = await apiResetTag(id);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }
  };

  const deleteTag = async (id: any) => {
    const response: any = await apiDeleteTag(id);
    if (response.status === 200) {
      refreshData();
      return { message: "success" };
    }
  };

  const alarm = async (id: any, isAlertTag: any) => {
    const response: any = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      refreshData();
    }
  };

  const disable = async (id: any, isDisabled: any) => {
    const response: any = await disableTag(id, isDisabled ? false : true);
    if (response.status === 200) {
      refreshData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (rawData && rawData.length) {
      if (unitsFilters && unitsFilters?.length) {
        const result = filterArray(unitsFilters, rawData, "unitName");
        if (result.length > 0) setData(result);
      } else if (equipmentsFilters && equipmentsFilters?.length) {
        const result = filterArray(equipmentsFilters, rawData, "equipmentName");
        if (result.length > 0) setData(result);
      } else {
        setData(rawData);
      }
    }
  }, [rawData]);

  useEffect(() => {
    if (unitsFilters && unitsFilters?.length) {
      const result = filterArray(unitsFilters, rawData, "unitName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [unitsFilters]);

  useEffect(() => {
    if (equipmentsFilters && equipmentsFilters?.length) {
      const result = filterArray(equipmentsFilters, rawData, "equipmentName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [equipmentsFilters]);

  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.tagNameIdentifier.toLowerCase().includes(keyword) ||
          item.tagNameIdentifier.toLowerCase() === keyword ||
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }

    if (items) {
      setTotalPages(() => {
        const array = [];
        for (let i = 1; i <= Math.ceil(items.length / ITEMS_PER_PAGE); i++) {
          array.push(i);
        }
        return array;
      });

      return items?.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
  }, [data, debouncedKeyword, currentPage]);

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Tags, try refreshing the page</p>
        </div>
      )}
      {!loading && !error && data && (
        <div
          className={classNames(
            isScaled ? "text-2xl" : "text-base",
            "pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll h-full"
          )}
        >
          <div className="flex w-full items-center justify-between rounded-md">
            <div className="flex gap-5">
              <div>
                <input
                  onChange={(e: any) => setKeyword(e.target.value)}
                  className="input"
                  type="text"
                  placeholder="Search"
                />
              </div>
              {/* Filters */}
              <Filters
                equipmentsOptions={equipmentsOptions}
                equipmentsFilters={equipmentsFilters}
                setEquipmentsFilters={setEquipmentsFilters}
                unitsOptions={unitsOptions}
                unitsFilters={unitsFilters}
                setUnitsFilters={setUnitsFilters}
              />
            </div>
            <div>
              <button
                className="btn"
                onClick={() => setTag({ action: "new", value: null })}
              >
                Add
              </button>
            </div>
          </div>
          <div className="gradient p-0.5 rounded-md mt-3">
            <div
              className={classNames(
                isScaled ? "max-h-[820px]" : "max-h-[650px]",
                "p-3 rounded-md bg-black h-full overflow-scroll"
              )}
            >
              <table className="w-full border-collapse">
                <thead>
                  <tr className="text-green">
                    <th className="text-left">PI Name</th>
                    <th className="text-left">Unit</th>
                    <th className="text-left">Equipment</th>
                    <th className="text-left">Tag Name</th>
                    <th>Engineering Unit</th>
                    <th>Min Value</th>
                    <th>Max Value</th>
                    <th>Min Range</th>
                    <th>Max Range</th>
                    <th>Pinned</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {rows &&
                    rows.map((tag: any) => (
                      <tr
                        key={tag.id}
                        className="border-b border-b-lightGray hover:bg-dark duration-300"
                      >
                        <td
                          onClick={() =>
                            setTag({ action: "chart", value: tag })
                          }
                          className="underline cursor-pointer hover:text-green"
                        >
                          {tag.tagNameIdentifier}
                        </td>
                        <td>{tag.unitName}</td>
                        <td>{tag.equipmentName}</td>
                        <td>{tag.name}</td>
                        <td className="text-center">{tag.uom}</td>
                        <td className="text-center">{tag.minValue}</td>
                        <td className="text-center">{tag.maxValue}</td>
                        <td className="text-center">{tag.minRange}</td>
                        <td className="text-center">{tag.maxRange}</td>
                        <td>
                          <div className="flex justify-center cursor-pointer">
                            {tag.isPinnedReact ? (
                              <BsPinAngleFill
                                size={isScaled ? 20 : 17}
                                className="text-green"
                                onClick={() => unPin(tag.id, null, 1)}
                              />
                            ) : (
                              <BsPinAngle
                                size={isScaled ? 20 : 17}
                                onClick={() => pinTag(tag.id, true)}
                              />
                            )}
                          </div>
                        </td>
                        <td className="text-left">
                          <div className="flex w-full gap-5 items-center justify-center">
                            {/* Edit */}
                            <RowAction
                              action={() =>
                                setTag({ action: "edit", value: tag })
                              }
                              text="Edit"
                              icon={<LuFileEdit size={isScaled ? 25 : 20} />}
                            />

                            {/* Reset */}
                            <RowAction
                              action={() =>
                                setTag({ action: "reset", value: tag })
                              }
                              text="Reset"
                              icon={<LuTimerReset size={isScaled ? 25 : 20} />}
                            />
                            {/* Delete */}
                            <RowAction
                              action={() =>
                                setTag({ action: "delete", value: tag })
                              }
                              text="Delete"
                              icon={<LuTrash2 size={isScaled ? 25 : 20} />}
                              danger
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* pagination */}
          {totalPages && (
            <div className="flex justify-end pt-3">
              <ul className="flex items-center gap-2">
                {currentPage > 1 && (
                  <li
                    className={classNames(
                      currentPage === 1
                        ? "cursor-auto text-lightGray"
                        : "cursor-pointer",
                      "p-2"
                    )}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <span>
                      <FiChevronLeft size={20} />
                    </span>
                  </li>
                )}
                {totalPages &&
                  totalPages.map((page: any) => (
                    <li
                      key={page}
                      className={classNames(
                        page === currentPage
                          ? "bg-green"
                          : "bg-transparent hover:bg-dark",
                        "px-3 py-1 rounded-md cursor-pointer"
                      )}
                      onClick={() => setCurrentPage(page)}
                    >
                      <span>{page}</span>
                    </li>
                  ))}
                {totalPages.length > 1 && (
                  <li
                    className={classNames(
                      currentPage === totalPages.length
                        ? "cursor-auto text-lightGray"
                        : "cursor-pointer",
                      "p-2"
                    )}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <span>
                      <FiChevronRight size={20} />
                    </span>
                  </li>
                )}
              </ul>
            </div>
          )}

          <TagChart
            alarm={alarm}
            disable={disable}
            selectedTag={tag}
            setSelectedTag={setTag}
          />
          <NewTag
            selectedTag={tag}
            setSelectedTag={setTag}
            createNewTag={createNewTag}
          />
          <EditTag
            selectedTag={tag}
            setSelectedTag={setTag}
            editTag={editTag}
          />
          <ResetTag
            selectedTag={tag}
            setSelectedTag={setTag}
            resetTagById={resetTag}
          />
          <DeleteTag
            selectedTag={tag}
            setSelectedTag={setTag}
            deleteTagById={deleteTag}
          />
        </div>
      )}
    </>
  );
}
