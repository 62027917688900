import Select from "components/widgets/Select";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";
export default function GaugeConfiguration({
  gauge,
  setGauge,
  handleConfiguration,
  setScreen,
  configError,
}: any) {
  const { isScaled } = useApp();
  return (
    <form className="flex flex-col gap-3 w-11/12 mx-auto">
      {/* K1 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gauge?.k1.name}
            onChange={(e: any) =>
              setGauge((prev: any) => ({
                ...prev,
                k1: {
                  ...gauge?.k1,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gauge.k1.id}
            selectedValue={gauge.k1.value}
            clear={() =>
              setGauge((prev: any) => ({
                ...prev,
                k1: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGauge((prev: any) => ({
                ...prev,
                k1: {
                  ...gauge.k1,
                  id: item.id,
                  value: item.item,
                },
              }));
            }}
          />
        </div>
      </div>

      {/* K2 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gauge?.k2.name}
            onChange={(e: any) =>
              setGauge((prev: any) => ({
                ...prev,
                k2: {
                  ...gauge?.k2,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gauge.k2.id}
            selectedValue={gauge.k2.value}
            clear={() =>
              setGauge((prev: any) => ({
                ...prev,
                k2: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGauge((prev: any) => ({
                ...prev,
                k2: {
                  ...gauge.k2,
                  id: item.id,
                  value: item.item,
                },
              }));
            }}
          />
        </div>
      </div>

      {/* K3 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gauge?.k3.name}
            onChange={(e: any) =>
              setGauge((prev: any) => ({
                ...prev,
                k3: {
                  ...gauge?.k3,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gauge.k3.id}
            selectedValue={gauge.k3.value}
            clear={() =>
              setGauge((prev: any) => ({
                ...prev,
                k3: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGauge((prev: any) => ({
                ...prev,
                k3: {
                  ...gauge.k3,
                  id: item.id,
                  value: item.item,
                },
              }));
            }}
          />
        </div>
      </div>

      {/* K4 */}
      <div className="flex flex-1 gap-3 w-full items-center">
        <div className="w-1/2">
          <input
            placeholder="Name"
            className={classNames(isScaled ? "text-lg h-11" : "", "input")}
            name="name"
            id="name"
            type="text"
            required
            value={gauge?.k4.name}
            onChange={(e: any) =>
              setGauge((prev: any) => ({
                ...prev,
                k4: {
                  ...gauge?.k4,
                  name: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className="w-1/2">
          <Select
            id={gauge.k4.id}
            selectedValue={gauge.k4.value}
            clear={() =>
              setGauge((prev: any) => ({
                ...prev,
                k4: {
                  id: "",
                  name: "",
                  value: "",
                },
              }))
            }
            select={(item: any) => {
              setGauge((prev: any) => ({
                ...prev,
                k4: {
                  ...gauge.k4,
                  id: item.id,
                  value: item.item,
                },
              }));
            }}
          />
        </div>
      </div>

      <div className="flex gap-1 justify-end mt-3">
        <div className="text-red">{configError}</div>
        <div className="flex items-center gap-3">
          <div
            onClick={() => {
              setScreen("");
            }}
            className="btn-sec"
          >
            Cancel
          </div>
          <div onClick={handleConfiguration} className="btn">
            Commit
          </div>
        </div>
      </div>
    </form>
  );
}
