/* eslint-disable @typescript-eslint/no-unused-expressions */
import { tr } from "date-fns/locale";
import api, { API_Login } from "utilities/Axios";

export const getAllUsersForAdmin = () => {
  return API_Login.get("/api/user/UsersForAdmin", { withCredentials: true });
};

export const UpdateUserRole = (data: any) => {
  return API_Login.post("/api/user/assignrole", data, {
    withCredentials: true,
  });
};

export const getRoles = () => {
  return API_Login.get("/api/user/UserRoles", { withCredentials: true });
};

export const getAllUsers = () => {
  return API_Login.get("/api/user/Users", { withCredentials: true });
};

export const deleteUser = (data: any) => {
  return api.get("/user/deleteuser" + `?id=${data}`);
};
