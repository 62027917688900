import DynamicWidgets from "pages/Dashboard/DynamicWidgets";
import DynamicWidgets1 from "pages/Dashboard/DynamicWidgets1";
import DynamicWidgets2 from "pages/Dashboard/DynamicWidgets2";
import PinnedWidgets from "pages/Dashboard/pinnedWidgets/PinnedWidgets";
import useApp from "hooks/useApp";
import { FiCheck, FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import classNames from "utilities/ClassNames";
import Loader from "components/shared/Loader";

export default function NewDashboard() {
  const {
    updateDashboardWidgets,
    dashboardWidgets,
    isScaled,
    dashboardWidgetsLoading,
  } = useApp();
  const [title1, setTitle1] = useState<any>("Layout");
  const [title2, setTitle2] = useState<any>("Layout");
  const [title3, setTitle3] = useState<any>("Layout");

  const [editTitle, setEditTitle] = useState({
    title1: false,
    title2: false,
    title3: false,
  });

  const [titleInput, setTitleInput] = useState({
    title1: "",
    title2: "",
    title3: "",
  });

  // title 1
  useEffect(() => {
    if (
      !dashboardWidgets.title1 ||
      dashboardWidgets.title1 === undefined ||
      dashboardWidgets.title1 === ""
    ) {
      setTitle1("Layout");
    } else {
      setTitle1(dashboardWidgets.title1);
    }
  }, [dashboardWidgets]);

  // title 2
  useEffect(() => {
    if (
      !dashboardWidgets.title2 ||
      dashboardWidgets.title2 === undefined ||
      dashboardWidgets.title2 === ""
    ) {
      setTitle2("Layout");
    } else {
      setTitle2(dashboardWidgets.title2);
    }
  }, [dashboardWidgets]);

  // title 3
  useEffect(() => {
    if (
      !dashboardWidgets.title3 ||
      dashboardWidgets.title3 === undefined ||
      dashboardWidgets.title3 === ""
    ) {
      setTitle3("Layout");
    } else {
      setTitle3(dashboardWidgets.title3);
    }
  }, [dashboardWidgets]);

  if (dashboardWidgetsLoading) {
    return (
      <div className="w-full h-[80vh] grid place-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        isScaled ? "flex-row-reverse" : "flex-col 2xl:flex-row-reverse",
        "pb-3 w-full h-full 2xl:h-[95vh] overflow-hidden overflow-y-scroll 2xl:overflow-y-hidden flex relative"
      )}
    >
      {dashboardWidgets.selectedType !== "init" && (
        <div>
          <PinnedWidgets />
        </div>
      )}
      {dashboardWidgets.selectedType === "init" && (
        <div className="flex flex-col gap-7 w-full justify-center items-center pt-5 2xl:pt-40">
          <h1 className="font-bold text-3xl">Select Dashboard type</h1>
          <div className="w-full h-full flex gap-3 justify-center flex-wrap">
            {/* type 1 */}
            <div className="flex flex-col gap-3">
              {editTitle.title1 && (
                <div className="flex gap-2 items-center">
                  <input
                    className="input"
                    value={titleInput.title1}
                    onChange={(e: any) =>
                      setTitleInput({ ...titleInput, title1: e.target.value })
                    }
                  />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setTitle1(titleInput.title1);
                      setEditTitle({ ...editTitle, title1: false });
                      updateDashboardWidgets({
                        ...dashboardWidgets,
                        title1: titleInput.title1,
                      });
                    }}
                  >
                    <FiCheck size={20} />
                  </div>
                </div>
              )}
              {!editTitle.title1 && (
                <div className="flex gap-2 items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => setEditTitle({ ...editTitle, title1: true })}
                  >
                    <BiEditAlt size={20} />
                  </div>
                  <h1 className="font-bold text-2xl text-center">{title1}</h1>
                </div>
              )}
              <div className="bg-black rounded-md">
                <div
                  onClick={() =>
                    updateDashboardWidgets({
                      ...dashboardWidgets,
                      selectedType: 1,
                    })
                  }
                  className="min-w-[500px] grid grid-cols-3 grid-rows-2 p-3 gap-3 bg-dark rounded-md cursor-pointer hover:border-green border-2 border-transparent duration-300"
                >
                  <div className="gradient p-0.5 rounded-md col-span-2">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* type 2 */}
            <div className="flex flex-col gap-3">
              {editTitle.title2 && (
                <div className="flex gap-2 items-center">
                  <input
                    className="input"
                    value={titleInput.title2}
                    onChange={(e: any) =>
                      setTitleInput({ ...titleInput, title2: e.target.value })
                    }
                  />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setTitle2(titleInput.title2);
                      setEditTitle({ ...editTitle, title2: false });
                      updateDashboardWidgets({
                        ...dashboardWidgets,
                        title2: titleInput.title2,
                      });
                    }}
                  >
                    <FiCheck size={20} />
                  </div>
                </div>
              )}
              {!editTitle.title2 && (
                <div className="flex gap-2 items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => setEditTitle({ ...editTitle, title2: true })}
                  >
                    <BiEditAlt size={20} />
                  </div>
                  <h1 className="font-bold text-2xl text-center">{title2}</h1>
                </div>
              )}
              <div className="bg-black rounded-md">
                <div
                  onClick={() =>
                    updateDashboardWidgets({
                      ...dashboardWidgets,
                      selectedType: 2,
                    })
                  }
                  className="min-w-[500px] grid grid-cols-3 grid-rows-2 p-3 gap-3 bg-dark rounded-md  cursor-pointer hover:border-green border-2 border-transparent duration-300"
                >
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                  <div className="gradient p-0.5 rounded-md col-span-2">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>
                </div>
              </div>
            </div>
            {/* type 3 */}
            <div className="flex flex-col gap-3">
              {editTitle.title3 && (
                <div className="flex gap-2 items-center">
                  <input
                    className="input"
                    value={titleInput.title3}
                    onChange={(e: any) =>
                      setTitleInput({ ...titleInput, title3: e.target.value })
                    }
                  />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setTitle3(titleInput.title3);
                      setEditTitle({ ...editTitle, title3: false });
                      updateDashboardWidgets({
                        ...dashboardWidgets,
                        title3: titleInput.title3,
                      });
                    }}
                  >
                    <FiCheck size={20} />
                  </div>
                </div>
              )}
              {!editTitle.title3 && (
                <div className="flex gap-2 items-center">
                  <div
                    className="cursor-pointer"
                    onClick={() => setEditTitle({ ...editTitle, title3: true })}
                  >
                    <BiEditAlt size={20} />
                  </div>
                  <h1 className="font-bold text-2xl text-center">{title3}</h1>
                </div>
              )}
              <div className="bg-black rounded-md">
                <div
                  onClick={() =>
                    updateDashboardWidgets({
                      ...dashboardWidgets,
                      selectedType: 3,
                    })
                  }
                  className="min-w-[500px] grid grid-cols-3 grid-rows-2 p-3 gap-3 bg-dark rounded-md cursor-pointer hover:border-green border-2 border-transparent duration-300"
                >
                  <div className="gradient p-0.5 rounded-md">
                    <div className="bg-dark w-full h-32 rounded-md"></div>
                  </div>

                  <div className="bg-back p-0.5 rounded-md">
                    <div className="bg-black w-full h-32 rounded-md grid place-content-center">
                      <FiPlus />
                    </div>
                  </div>

                  <div className="bg-back p-0.5 rounded-md">
                    <div className="bg-black w-full h-32 rounded-md grid place-content-center">
                      <FiPlus />
                    </div>
                  </div>

                  <div className="bg-back p-0.5 rounded-md">
                    <div className="bg-black w-full h-32 rounded-md grid place-content-center">
                      <FiPlus />
                    </div>
                  </div>

                  <div className="bg-back p-0.5 rounded-md">
                    <div className="bg-black w-full h-32 rounded-md grid place-content-center">
                      <FiPlus />
                    </div>
                  </div>

                  <div className="bg-back p-0.5 rounded-md">
                    <div className="bg-black w-full h-32 rounded-md grid place-content-center">
                      <FiPlus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {dashboardWidgets.selectedType === 1 && <DynamicWidgets1 />}
      {dashboardWidgets.selectedType === 2 && <DynamicWidgets2 />}
      {dashboardWidgets.selectedType === 3 && <DynamicWidgets />}
    </div>
  );
}
