/* eslint-disable no-mixed-spaces-and-tabs */
import { useState, useEffect } from "react";
import AreaConfiguration from "./configurations/AreaConfiguration";
import GaugeBarConfiguration from "./configurations/GaugeBarConfiguration";
import GaugeConfiguration from "./configurations/GaugeConfiguration";
import KpiConfiguration from "./configurations/KpiConfiguration";
import OneGaugeConfiguration from "./configurations/OneGaugeConfiguration";
import LargeConfiguration from "./configurations/LargeConfiguration";

export default function Configuration({
  widget,
  screen,
  setScreen,
  tab,
  tabs,
  currentTab,
  updateWidgets,
}: any) {
  const [configError, setConfigError] = useState("");
  const [large, setLarge] = useState(
    tab?.type === "large" && tab?.configurations
      ? tab?.configurations
      : {
          k1: { id: "", name: "", value: "", type: "" },
          k2: { id: "", name: "", value: "", type: "" },
          k3: { id: "", name: "", value: "", type: "" },
        }
  );
  const [kpi, setKpi] = useState(
    tab?.type === "kpi" && tab?.configurations
      ? tab?.configurations
      : {
          k1: { id: "", name: "", value: "", type: "" },
          k2: { id: "", name: "", value: "", type: "" },
          k3: { id: "", name: "", value: "", type: "" },
          k4: { id: "", name: "", value: "", type: "" },
        }
  );

  const [area, setArea] = useState(
    tab?.type === "area" && tab?.configurations
      ? tab?.configurations
      : { id: "", name: "", value: "", type: "" }
  );

  const [gauge, setGauge] = useState(
    tab?.type === "gauge" && tab?.configurations
      ? tab?.configurations
      : {
          k1: { id: "", name: "", value: "" },
          k2: { id: "", name: "", value: "" },
          k3: { id: "", name: "", value: "" },
          k4: { id: "", name: "", value: "" },
        }
  );

  const [gaugeBar, setGaugeBar] = useState(
    tab?.type === "gaugeBar" && tab?.configurations
      ? tab?.configurations
      : {
          k1: { id: "", name: "", value: "" },
          k2: { id: "", name: "", value: "" },
          k3: { id: "", name: "", value: "" },
          k4: { id: "", name: "", value: "" },
        }
  );

  const [oneGaugeBar, setOneGaugeBar] = useState(
    tab?.type === "oneGaugeBar" && tab?.configurations
      ? tab?.configurations
      : {
          k1: { id: "", name: "", value: "" },
        }
  );

  const handleConfiguration = (event: any) => {
    event.preventDefault();
    if (tab.type === "large") {
      if (
        large.k1.id === "" &&
        large.k1.name === "" &&
        large.k1.value === "" &&
        large.k2.id === "" &&
        large.k2.name === "" &&
        large.k2.value === "" &&
        large.k3.id === "" &&
        large.k3.name === "" &&
        large.k3.value === ""
      ) {
        const newTab = { ...tab, configurations: null, configured: false };
        const newTabs = [...tabs];
        newTabs[currentTab] = newTab;
        const newWidget = { ...widget, tabs: newTabs };
        updateWidgets(widget.id, newWidget);
        setScreen("");
        return;
      }
      const newTab = { ...tab, configurations: large, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
      return;
    } else if (tab.type === "kpi") {
      if (
        kpi.k1.id === "" &&
        kpi.k1.name === "" &&
        kpi.k1.value === "" &&
        kpi.k2.id === "" &&
        kpi.k2.name === "" &&
        kpi.k2.value === "" &&
        kpi.k3.id === "" &&
        kpi.k3.name === "" &&
        kpi.k3.value === "" &&
        kpi.k4.id === "" &&
        kpi.k4.name === "" &&
        kpi.k4.value === ""
      ) {
        const newTab = { ...tab, configurations: null, configured: false };
        const newTabs = [...tabs];
        newTabs[currentTab] = newTab;
        const newWidget = { ...widget, tabs: newTabs };
        updateWidgets(widget.id, newWidget);
        setScreen("");
        return;
      }
      const newTab = { ...tab, configurations: kpi, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
      return;
    } else if (tab.type === "area") {
      if (area.id === "" || area.name === "" || area.value === "") {
        const newTab = { ...tab, configurations: null, configured: false };
        const newTabs = [...tabs];
        newTabs[currentTab] = newTab;
        const newWidget = { ...widget, tabs: newTabs };
        updateWidgets(widget.id, newWidget);
        setScreen("");
        return;
      }
      const newTab = { ...tab, configurations: area, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
      return;
    } else if (tab.type === "gauge") {
      if (
        gauge.k1.id === "" &&
        gauge.k1.name === "" &&
        gauge.k1.value === "" &&
        gauge.k2.id === "" &&
        gauge.k2.name === "" &&
        gauge.k2.value === "" &&
        gauge.k3.id === "" &&
        gauge.k3.name === "" &&
        gauge.k3.value === "" &&
        gauge.k4.id === "" &&
        gauge.k4.name === "" &&
        gauge.k4.value === ""
      ) {
        const newTab = { ...tab, configurations: null, configured: false };
        const newTabs = [...tabs];
        newTabs[currentTab] = newTab;
        const newWidget = { ...widget, tabs: newTabs };
        updateWidgets(widget.id, newWidget);
        setScreen("");
      }
      const newTab = { ...tab, configurations: gauge, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
      return;
    } else if (tab.type === "gaugeBar") {
      if (
        gaugeBar.k1.id === "" &&
        gaugeBar.k1.name === "" &&
        gaugeBar.k1.value === "" &&
        gaugeBar.k2.id === "" &&
        gaugeBar.k2.name === "" &&
        gaugeBar.k2.value === "" &&
        gaugeBar.k3.id === "" &&
        gaugeBar.k3.name === "" &&
        gaugeBar.k3.value === "" &&
        gaugeBar.k4.id === "" &&
        gaugeBar.k4.name === "" &&
        gaugeBar.k4.value === ""
      ) {
        const newTab = { ...tab, configurations: null, configured: false };
        const newTabs = [];
        newTabs[currentTab] = newTab;
        const newWidget = { ...widget, tabs: newTabs };
        updateWidgets(widget.id, newWidget);
        setScreen("");
        return;
      }
      const newTab = { ...tab, configurations: gaugeBar, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
      return;
    } else if (tab.type === "oneGaugeBar") {
      if (
        oneGaugeBar.k1.id === "" &&
        oneGaugeBar.k1.name === "" &&
        oneGaugeBar.k1.value === ""
      ) {
        const newTab = { ...tab, configurations: null, configured: false };
        const newTabs = [];
        newTabs[currentTab] = newTab;
        const newWidget = { ...widget, tabs: newTabs };
        updateWidgets(widget.id, newWidget);
        setScreen("");
        return;
      }
      const newTab = { ...tab, configurations: oneGaugeBar, configured: true };
      const newTabs = [...tabs];
      newTabs[currentTab] = newTab;
      const newWidget = { ...widget, tabs: newTabs };
      updateWidgets(widget.id, newWidget);
      setScreen("");
      return;
    }
  };

  return (
    <>
      {screen === "configuration" && (
        <div className="w-full h-full flex justify-center items-center">
          {tab.type === "large" && (
            <LargeConfiguration
              large={large}
              setLarge={setLarge}
              handleConfiguration={handleConfiguration}
              setScreen={setScreen}
              configError={configError}
            />
          )}
          {tab.type === "kpi" && (
            <KpiConfiguration
              kpi={kpi}
              setKpi={setKpi}
              handleConfiguration={handleConfiguration}
              setScreen={setScreen}
              configError={configError}
            />
          )}
          {/* Area Configuration */}
          {tab.type === "area" && (
            <AreaConfiguration
              area={area}
              setArea={setArea}
              handleConfiguration={handleConfiguration}
              setScreen={setScreen}
              configError={configError}
            />
          )}
          {tab.type === "gauge" && (
            <GaugeConfiguration
              gauge={gauge}
              setGauge={setGauge}
              handleConfiguration={handleConfiguration}
              setScreen={setScreen}
              configError={configError}
            />
          )}
          {tab.type === "gaugeBar" && (
            <GaugeBarConfiguration
              gaugeBar={gaugeBar}
              setGaugeBar={setGaugeBar}
              handleConfiguration={handleConfiguration}
              setScreen={setScreen}
              configError={configError}
            />
          )}
          {tab.type === "oneGaugeBar" && (
            <OneGaugeConfiguration
              oneGaugeBar={oneGaugeBar}
              setOneGaugeBar={setOneGaugeBar}
              handleConfiguration={handleConfiguration}
              setScreen={setScreen}
              configError={configError}
            />
          )}
        </div>
      )}
    </>
  );
}
