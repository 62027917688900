import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { DataProvider } from "hooks/useData";
import { ContextProvider } from "utilities/ContextProvider";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


const oidcConfig = {
  client_id: "jsx",
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_ENERGY_APP}`,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AppProvider>
        <DataProvider>
          <ContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ContextProvider>
        </DataProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);
