import { resetPassword } from "api/user";
import { useEffect, useState } from "react";
import { FiArrowLeft } from "react-icons/fi";

export default function Passwords({ setScreen }: any) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setError("New password and confirm password didn't matched");
    } else {
      setLoading(true);
      const response = await resetPassword({
        current_password: currentPassword,
        new_password: newPassword,
      });
      if (response.status === 200) {
        setScreen("edit");
      } else {
        setError("Error resting password please try again");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setError(null);
  }, [currentPassword, newPassword, confirmPassword]);

  return (
    <div className="h-full w-full flex flex-col">
      <div className="gradient p-0.5 rounded-md m-3">
        <div className="bg-gray rounded-md p-3 flex flex-col gap-3">
          <div className="flex justify-start">
            <div
              onClick={() => setScreen("edit")}
              className="cursor-pointer hover:text-green duration-300"
            >
              <FiArrowLeft size={20} />
            </div>
          </div>
          {loading && <div>Resting password</div>}
          {error && <div>Error resting password</div>}
          {/* content */}
          <form onSubmit={handleSubmit}>
            {/* current password */}
            <div className="py-2">
              <label
                className="text-white/70 text-[12px]"
                htmlFor="currentPassword"
              >
                Current Password
              </label>
              <input
                type="password"
                className="input"
                name="currentPassword"
                value={currentPassword}
                onChange={(e: any) => setCurrentPassword(e.target.value)}
              />
            </div>
            {/* new password */}
            <div className="py-2">
              <label
                className="text-white/70 text-[12px]"
                htmlFor="newPassword"
              >
                New Password
              </label>
              <input
                type="password"
                className="input"
                name="newPassword"
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
              />
            </div>
            {/* confirm password */}
            <div className="py-2">
              <label
                className="text-white/70 text-[12px]"
                htmlFor="confirmPassword"
              >
                Confirm New Password
              </label>
              <input
                type="password"
                className="input"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
            </div>
            {/* Update */}
            <div className="py-2">
              <button type="submit" className="btn w-full">
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
